import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import * as sweetAlert from "../../../utils/sweetAlert";
import usersService from "../../../services/users.service";
import validator from "validator";
import { useDispatch } from "react-redux";

// redux
import UsersThunkAPI from "store/features/users/middleware";
export default function ValidateOtp(props) {
  const { setShowModal, email } = props;
  const Ref = useRef(null);
  const router = useRouter();
  const [userDetails, setUserDetails] = useState({ validateOtp: "" });
  const [validateError, setValidateError] = useState({ validateOtp: null });
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState("00:00");

  const dispatch = useDispatch();
  const onHandleUserInfo = (event, label) => {
    const { value } = event.target;
    let tempValidateError = { ...validateError };

    value = value.replace(/\D/g, "");

    if (!value) tempValidateError.validateOtp = "Please Enter OTP";
    else if (value && value.length !== 6)
      tempValidateError.validateOtp = "Invalid OTP";
    else if (!validator.isNumeric(value))
      tempValidateError.validateOtp = "Invalid OTP";
    else tempValidateError.validateOtp = "";
    setValidateError({
      ...tempValidateError,
    });
    setUserDetails({ ...userDetails, validateOtp: value.trim() });
  };

  let isOTP = Boolean(userDetails?.validateOtp);
  let isError = Boolean(validateError.validateOtp);
  //Validate or Verify Email by OTP -
  const OnHandlevalidateEmail = async (e) => {
    e.preventDefault();
    if (isDisabled) {
      sweetAlert.errorAlert("OTP expired");
      return;
    }

    try {
      if (isOTP && !isError) {
        let payload = {
          mail: email,
          otp_code: userDetails.validateOtp,
          // if modale not open from update profile (email)
          profileEmailUpdate: props?.isUpdate ? true : false,
          purpose : props?.isUpdate?"UPDATE-EMAIL":"SIGNUP"

        };

        if (props?.isUpdate) {
          payload.passwordToUpdateEmail = props?.passwordToUpdateEmail;
        }

        dispatch(
          UsersThunkAPI.validateEmail({
            payload: payload,
            callback: (res) => {
              if (res.status) {
                sweetAlert.successAlert(res.message);
                setUserDetails();
                if (!props?.isUpdate) router.push("/");
                setShowModal(false);
              }
            },
          })
        );
      }
    } catch (error) {
      const { name, message } = error;
    }
  };
  //End Validate Email

  //Resend OTP Code to mail-
  const resendOTP = async () => {
    setUserDetails({
      ...userDetails,
      validateOtp: "",
    });
    try {
      let payload = {
        mail: email,
      };
      const response = await usersService.resendOTP(payload);
      if (response.status) {
        onClickReset();
        // sweetAlert.successAlert(response.message);
      } else {
        sweetAlert.errorAlert(response.message);
      }
    } catch (error) {
      const { name, message } = error;
    }
  };
  const resendOtpCode = async () => {
    try {
      if (isDisabled) {
        resendOTP();
      }
    } catch (err) {}
  };
  //Set timer for OTP-
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    if (timer == "00:") {
      setIsDisabled(false);
    }
    if (total <= 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      // setIsDisabled(false);
    }
  };

  const clearTimer = (e) => {
    setTimer("00:59");
    if (Ref.current) {
      clearInterval(Ref.current);
      setIsDisabled(true);
    }
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 59);
    setIsDisabled(true);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    onClickReset();
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <div>
      <div className='otpSectionMain'>
        <div className='otpSectionHead'>Enter the code sent to your email</div>
        <div className='otpSectionSemiHead'>
          The code has been sent to email
        </div>
        <div className='otpSectionMobile fontIBMPlexBold mb24'>{email}</div>
        <form onSubmit={(e) => OnHandlevalidateEmail(e)}>
          <div className='form-group otpInout'>
            <label>Enter OTP</label>
            <input
              type='text'
              className='form-control'
              placeholder='Enter OTP'
              value={userDetails?.validateOtp || ""}
              onChange={(e) => onHandleUserInfo(e, "validateOtp")}
              maxLength={6}
            />
            <div>
              <h6 style={{ color: "#d34747" }}>{validateError.validateOtp}</h6>
            </div>
          </div>

          <button
            type='submit'
            rel='step-1'
            className={
              ((!userDetails?.validateOtp || isError) &&
                "createAccountBtn2 cursor-no-drop mb-4") ||
              " createAccountBtn mb-4"
            }
            disabled={!userDetails?.validateOtp || isError}
            onClick={OnHandlevalidateEmail}
          >
            Verify OTP
          </button>
        </form>

        <button
          className='forgerPassBtn recentOTP textDisable'
          type='button'
          disabled={false}
        >
          {isDisabled ? (
            <span onClick={resendOtpCode}>
              <em className='fal fa-sync'></em> Resend Code
            </span>
          ) : (
            <span style={{ color: "gray" }}>
              <em className='fal fa-sync'></em> Resend Code
            </span>
          )}

          {timer !== "00:00" && (
            <span className='timer-sec'>
              <span id='timer'> {timer}</span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}
