import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import LaunchPadThunkAPI from "./middleware";

const initialState = {
  launchPad: null,
  singleLaunchpadDetails: null,
  status: STATUSES.IDLE,
  singleUserStatus: STATUSES.IDLE,
  communityVoteAnalysys: null,
  likeLoader: false,
  disLikeLoader: false,
};

const launchPadSlice = createSlice({
  name: "launchPads",
  initialState,
  reducers: {
    removeFavouriteLaunchpad: (state, action) => {
      let temp = state.launchPad.map((item) => {
        if (item?._id == action?.payload) item.isfavourite = !item.isfavourite;
        return item;
      });
      state.launchPad = temp;
    },
    removeFavouriteRecentViewLaunchpad: (state, action) => {
      let temp = state.launchPad.map((item) => {
        if (item?._id == action?.payload) item.isfavourite = !item.isfavourite;
        return item;
      });
      state.launchPad = temp;
    },
    addRemoveToFavouriteLaunchpadDetail: (state) => {
      if (state.singleLaunchpadDetails)
        state.singleLaunchpadDetails.isfavourite =
          !state?.singleLaunchpadDetails?.isfavourite;
    },
  },
  extraReducers: {
    /**
     * @desc get user launchpad :
     */
    [LaunchPadThunkAPI.favouriteLaunchPadAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.favouriteLaunchPadAsync.fulfilled]: (state, action) => {
      state.launchPad = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.favouriteLaunchPadAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
    /**
     * @desc add or remove user launchpad :
     */
    [LaunchPadThunkAPI.addFavouriteLaunchpadAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.addFavouriteLaunchpadAsync.fulfilled]: (
      state,
      action
    ) => {
      
      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.addFavouriteLaunchpadAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },
    /**
     * @desc get single user launchpad details :
     */
    [LaunchPadThunkAPI.getSingleLaunchpadDetailsAsync.pending]: (
      state,
      action
    ) => {
      state.singleUserStatus = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.getSingleLaunchpadDetailsAsync.fulfilled]: (
      state,
      action
    ) => {
      state.singleLaunchpadDetails = action?.payload;
      state.singleUserStatus = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.getSingleLaunchpadDetailsAsync.rejected]: (
      state,
      action
    ) => {
      state.singleUserStatus = STATUSES.ERROR;
    },

    //Create launchpad
    [LaunchPadThunkAPI.createLaunchpadAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.createLaunchpadAsync.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.createLaunchpadAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //Update launchpad
    [LaunchPadThunkAPI.updateLaunchpadAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.updateLaunchpadAsync.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.updateLaunchpadAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //launchpadCommunityVoteAnalysis
    [LaunchPadThunkAPI.launchpadCommunityVoteAnalysisAsync.pending]: (
      state,
      action
    ) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.launchpadCommunityVoteAnalysisAsync.fulfilled]: (
      state,
      action
    ) => {
      state.communityVoteAnalysys = action.payload;

      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.launchpadCommunityVoteAnalysisAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },

    //launchpadCommunityAddRemoveLike
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveLikeAsync.pending]: (
      state,
      action
    ) => {
      state.likeLoader = true;
    },
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveLikeAsync.fulfilled]: (
      state,
      action
    ) => {
      state.communityVoteAnalysys = action.payload;

      state.likeLoader = false;
    },
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveLikeAsync.rejected]: (
      state,
      action
    ) => {
      state.likeLoader = false;
    },

    //launchpadCommunityAddRemoveDislike
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveDislikeAsync.pending]: (
      state,
      action
    ) => {
      state.disLikeLoader = true;
    },
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveDislikeAsync.fulfilled]: (
      state,
      action
    ) => {
      state.communityVoteAnalysys = action.payload;

      state.disLikeLoader = false;
    },
    [LaunchPadThunkAPI.launchpadCommunityAddRemoveDislikeAsync.rejected]: (
      state,
      action
    ) => {
      state.disLikeLoader = false;
    },

    //Create launchpad
    [LaunchPadThunkAPI.addRecentViewLaunchpadByUserAsync.pending]: (
      state,
      action
    ) => {
      state.status = STATUSES.LOADING;
    },
    [LaunchPadThunkAPI.addRecentViewLaunchpadByUserAsync.fulfilled]: (
      state,
      action
    ) => {
      state.status = STATUSES.IDLE;
    },
    [LaunchPadThunkAPI.addRecentViewLaunchpadByUserAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },
  },
});
export const {
  addRemoveLaunchpad,
  removeFavouriteLaunchpad,
  addRemoveToFavouriteLaunchpadDetail,
} = launchPadSlice.actions;
export default launchPadSlice.reducer;
