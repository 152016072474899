import { useEffect, useState } from "react";
import Image from "next/future/image";
import { useWeb3React } from "@web3-react/core";

import {
  WalletConnectConnector,
  UserRejectedRequestError,
} from "@web3-react/walletconnect-connector";

import Button from "react-bootstrap/Button";
import ethCoinImg from "static/images/WalletConnectlogo.svg";
import bnbCoinImg from "static/images/bnb.svg";
import maticCoinImg from "static/images/matic.svg";

import Spinner from "componants/common/Spinner";

const wc = new WalletConnectConnector({
  infuraId: "0168c4f5d973491daae8d12b05e2f051",
  supportedChainIds: [1, 97, 250, 5, 137, 56],
  qrcode: true,
  pollingInterval: 12000,
});

export default function ConnectWalletConnect(props) {
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const { library, chainId, ...web3React } = useWeb3React();

  function handleError(error) {
    if (error instanceof UserRejectedRequestError) {
      setIsLocalLoading(false);
      wc.handleDisconnect();
    }
  }

  function initConnection() {
    setIsLocalLoading(true);
    web3React.activate(wc, handleError);
  }
  function activeIsWC() {
    return (
      web3React.account && web3React.connector instanceof WalletConnectConnector
    );
  }

  useEffect(() => {
    if (web3React.account) {
      onSignMessage();
    }
  }, [web3React.account]);

  const onSignMessage = async () => {
    try {
      let signature = null;

      if (!props?.isDisableSignatureRequest) {
        signature = await library?.getSigner()
          .signMessage(`Hello, welcome to Padify. Please sign this message to verify your wallet. This action will not cost you any transaction fee.
  ${"\n"}Address:${"\n"}${web3React.account}`);
      }

      const network = await library?.getNetwork();
      //call login or registration API
      //para wallete_address ,signature
      setIsLocalLoading(false);
      props?.onConnect?.({
        network: network,
        walletAddress: web3React.account,
        signHash: signature,
        walletType: 'walletconnect'
      });
      
    } catch (error) {
      setIsLocalLoading(false);
    }
  };

  const getContent = () => {
    if (isLocalLoading) {
      return (
        <>
          Wallect connect &nbsp; &nbsp; &nbsp;
          <Spinner className="spinner-border text-light spinner-border-sm" />
        </>
      );
    } else if (activeIsWC()) {
      return "Connected";
    } else {
      return "Wallect connect";
    }
  };

  return (
    <div className="App-connector">
      <Button
        className="CoinSelectBox"
        onClick={() => {
          if (isLocalLoading || props?.isLoading) return;
          initConnection();
        }}
      >
        <Image
          alt=""
          src={ethCoinImg}
          style={{ width: 50, height: 50 }}
        />
        {getContent()}
      </Button>
    </div>
  );
}
