import axiosInstance from "./axios";
import { getAuthToken } from "./authToken.service";
const launchPadService = {
  //favourite launchpad lists :
  userFavouriteLaunchPadList: async function (payload) {
    return axiosInstance.post("getUserFavourateLaunchpad", payload);
  },
  addRemoveFavouriteLaunchpad: async function (payload) {
    return axiosInstance.post("addFavourite", payload);
  },
  getSingleLaunchpadDetails: async function (payload) {
    return axiosInstance.post("launchpadDetails", payload);
  },
  createLaunchpad: async function (payload) {
    return axiosInstance.post("createLaunchpad", payload);
  },
  updateLaunchpad: async function (payload) {
    return axiosInstance.post("updateLaunchpad", payload);
  },
  uploadLogoFileS3: async function (payload) {
    return axiosInstance.post("launchpadDetails", payload);
  },
  uploadLogoFileS3: async function (payload) {
    let headers = {
      "Content-Type": "multipart/form-data",
      "x-access-token": getAuthToken() || "",
    };
    return axiosInstance.post("uploadLogoFileS3", payload, { headers });
  },
  uploadPdfS3: async function (payload) {
    let headers = {
      "Content-Type": "multipart/form-data",
      "x-access-token": getAuthToken() || "",
    };
    return axiosInstance.post("uploadWhitepaperpdf", payload, { headers });
  },

  addRecentViewLaunchpad: async function (payload) {
    return axiosInstance.post("/addrecentViewedata", payload);
  },
  launchpadCommunityVoteAnalysis: async function (payload) {
    return axiosInstance.post("launchpadCommunityVoteAnalysis", payload);
  },
  launchpadCommunityAddRemoveLike: async function (payload) {
    return axiosInstance.post("launchpadCommunityAddRemoveLike", payload);
  },
  launchpadCommunityAddRemoveDislike: async function (payload) {
    return axiosInstance.post("launchpadCommunityAddRemoveDislike", payload);
  },
  launchpadBuy: async function (payload) {
    return axiosInstance.post("launchpad-buy", payload);
  },
  getCirculatingSupplyAPI: async function (payload) {
    return axiosInstance.post("getTokenCirculatingSupply", payload);
  },
};
export default launchPadService;
