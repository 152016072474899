// set cookies
import cookies from "js-cookie";
import { parseCookies } from "nookies";

export const getCurrantUser = () => {
  return JSON.parse(localStorage.getItem("currant_user"));
};

export const setCurrantUser = (data) => {
  localStorage.setItem("currant_user", JSON.stringify(data));
};

export const getAuthToken = () => {
  const currantUser = getCurrantUser();
  return currantUser?.token;
};

export const removeCurrantUser = () => {
  localStorage.removeItem("currant_user");
};

// user_Wallet_info
export const setUserWalletInfoInStorage = (obj) => {
  cookies.set("user_wallet_info", JSON.stringify(obj));
};

export const getUserWalletInfo = () => {
  const all_cookies = parseCookies();
  if (all_cookies?.user_wallet_info)
    return JSON.parse(all_cookies?.user_wallet_info);
  else return null;
};

export const removeUserWalletInfo = () => {
  cookies.set("user_wallet_info", JSON.stringify(""));
};

export const setAppSettingsCookie = (obj) => {
  cookies.set("aap_settings", JSON.stringify(obj));
};

export const getAppSettingsCookie = () => {
  const all_cookies = parseCookies();
  if (all_cookies?.aap_settings) return JSON.parse(all_cookies?.aap_settings);
  else return null;
};

export const setFirebaseTokenInCookie = (obj) => {
  cookies.set("firebase_token", JSON.stringify(obj));
};

export const getFirebaseTokenFromCookie = () => {
  const all_cookies = parseCookies();
  if (
    all_cookies?.firebase_token &&
    all_cookies?.firebase_token !== "undefined"
  )
    return JSON.parse(all_cookies?.firebase_token);
  else return null;
};

