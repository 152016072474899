import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const mySwal = withReactContent(Swal);

const Toast = mySwal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const successAlert = (title) => {
  Toast.fire({
    icon: "success",
    title,
    color: "#000",
    background: "#fff",
  });
};

export const errorAlert = (title) => {
  Toast.fire({
    icon: "error",
    title,
    color: "#000",
    background: "#fff",
  });
};

export const warningAlert = (title) => {
  Toast.fire({
    icon: "warning",
    title,
  });
};

export const confirmAlert = (callback) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const swalFire = (title) => {
  Swal.fire("Updated!", title, "success");
};
