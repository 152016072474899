import React from "react";

const Spinner = (props) => {
  const { className, doNotDisableOtherContent, isCenter } = props;

  if (isCenter) {
    return (
      <div className="spinnerLoadSection">
        <div className={className} role="status" />
        {!doNotDisableOtherContent && (
          <div className={"loader-parant-spinner"} />
        )}
      </div>
    );
  } else {
    return (
      <>
        <div className={className} role="status" />
        {!doNotDisableOtherContent && (
          <div className={"loader-parant-spinner"} />
        )}
      </>
    );
  }
};

export default Spinner;

// .loader-parant-spinner {position: absolute;right: 0;left: 0;z-index: 999;top: 0;background-color: rgba(0, 0, 0, 0.9);bottom: 0;display: flex;align-items: center;justify-content: center;}

{
  /**
  add class to parant
  position-relative

  <Spinner className="spinner-border text-light spinner-border-sm" />

   center loader
     <Spinner className="spinner-border text-light spinner-border-sm" isCenter ={true}/>

   // If you want to only desable other componants without loader
             <Spinner className="" />
  

*/
}
