import axiosInstance from "./axios";
import { getAuthToken } from "./authToken.service";
const dashboardService = {
  getAllLaunchpad: async function (payload) {
    return axiosInstance.post("getAllLaunchpad", payload);
  },
  getAllPresale: async function (payload) {
    return axiosInstance.post("presalealllist", payload);
  },

  getAlltokenPage: async function (payload) {
    return axiosInstance.post("tokenExplorerTokenPages", payload);
  },
};
export default dashboardService;
