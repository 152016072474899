export const test = false;
export const infuraId = "5f1a442cc5f146d5ae884f77273265c4";

export const decimals = {
  1: 6,
  56: 18,
};
export const providers = {
  1: "https://eth-mainnet.alchemyapi.io/v2/WRipeVhYsi0RgGCD1AdwhPkWNjoYkcA9",
  5: "https://eth-goerli.g.alchemy.com/v2/WRipeVhYsi0RgGCD1AdwhPkWNjoYkcA9",
  56: "https://bsc-dataseed.binance.org/",
  97: "https://data-seed-prebsc-1-s3.binance.org:8545",
};

export const video_url =
  "https://padefi-asset.s3.us-east-1.amazonaws.com/padefilogo%2F27075_PF_1678086127574.mp4";

export const youtube_video_link =
  "https://www.youtube.com/watch?v=-H5ws-yDzwc&ab_channel=Padefi";
