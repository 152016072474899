import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
//bootstrap
import Modal from "react-bootstrap/Modal";

// blockchain
import { ethers } from "ethers";
import ConnectWalletConnect from "./ConnectWalletConnect";
import ConnectCoinbaseWallet from "./ConnectCoinbaseWallet";
import ConnectTrustWallet from "./ConnectTrustWallet/ConnectTrustWallet";
import ConnectMetaMaskWallet from "./ConnectMetaMaskWallet";
import { Web3ReactProvider } from "@web3-react/core";

import {
  isConnected,
  checkIsConnected,
  signMessage,
  getNetwork,
} from "../wallet/walletConnectAuth";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setUserWalletInfo } from "store/features/users/usersSlice";

const CustomWalletConnector = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [test, setTest] = useState(false);
  const [checkMobileView, setCheckMobileView] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [metaMaskLink] = useState(
    process.env.ENV_MODE == "DEV"
      ? "https://metamask.app.link/dapp/dev.padefi.io"
      : "https://metamask.app.link/dapp/padefi.io"
  );
  // const [metaMaskLink] = useState(
  //   process.env.ENV_MODE == "dev"
  //     ? "https://metamask.app.link/dapp/dev.padefi.io"
  //     : "https://metamask.app.link/dapp/padefi.io"
  // );

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.users);
  const router = useRouter();

  const {
    showConnetWalleteOptionas,
    onShowConnetWalleteModal,
    onHideConnetWalleteOptions,
    onBack,
    isDisableSignatureRequest,
    networksListToCheck,
    isForMultiNetworkCheck,
    contractAddress,
  } = props;
  const onConnect = (data) => {
    //set user Wallet and nwtwork info
    //set or update user wallet info in redux and local storage
    if (props?.isForChangeNetworkInReduxState) {
      // dispatch(setUserWalletInfo(data));
      data.isForChangeNetworkInReduxState = true;
    }
    //login
    props.onConnect(data);
  };

  function getLibrary(provider) {
    return new ethers.providers.Web3Provider(provider);
  }

  const onHandleSetIsLoading = (val) => {
    // setIsLoading(val);
  };

  useEffect(() => {
    async function checkIsMobileView() {
      if (!redirected && typeof window !== "undefined") {
        const width = window.innerWidth;
        if (width < 768) {
          if (!userState?.userWalletInfo?.walletAddress) {
            const walletAddress = await isConnected();
            if (!walletAddress) {
              window.location.href = metaMaskLink;
            }

            // Set the redirected state to true to prevent further redirects
            setRedirected(true);
          }
        }
        setCheckMobileView(width < 768);
      }
    }

    checkIsMobileView();
    window.addEventListener("resize", checkIsMobileView); // update on resize
    return () => {
      window.removeEventListener("resize", checkIsMobileView);
    };
  }, []);
  const getOptions = () => {
    return (
      <>
        <div className="BodayHeadTitle font18 fontBold d-flex justify-content-between">
          Connect Wallet
          {onBack && (
            <button type="button" className="cursor-pointer" onClick={onBack}>
              <i className="fal fa-times"></i>
            </button>
          )}
        </div>

        <div className="allSelectCoinBox position-relative">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <ConnectMetaMaskWallet
                  onConnect={onConnect}
                  isLoading={isLoading}
                  setIsLoading={onHandleSetIsLoading}
                  isDisableSignatureRequest={isDisableSignatureRequest}
                  //multi network for check contract address
                  networksListToCheck={networksListToCheck}
                  isForMultiNetworkCheck={isForMultiNetworkCheck}
                  contractAddress={contractAddress}
                />
                {/* {checkMobileView ? (
                  <a href="https://metamask.app.link/dapp/dev.padefi.io">
                    <ConnectMetaMaskWallet
                      onConnect={onConnect}
                      isLoading={isLoading}
                      setIsLoading={onHandleSetIsLoading}
                      isDisableSignatureRequest={isDisableSignatureRequest}
                      //multi network for check contract address
                      networksListToCheck={networksListToCheck}
                      isForMultiNetworkCheck={isForMultiNetworkCheck}
                      contractAddress={contractAddress}
                    />
                  </a>
                ) : (
                  <ConnectMetaMaskWallet
                    onConnect={onConnect}
                    isLoading={isLoading}
                    setIsLoading={onHandleSetIsLoading}
                    isDisableSignatureRequest={isDisableSignatureRequest}
                    //multi network for check contract address
                    networksListToCheck={networksListToCheck}
                    isForMultiNetworkCheck={isForMultiNetworkCheck}
                    contractAddress={contractAddress}
                  />
                )} */}
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <Web3ReactProvider getLibrary={getLibrary}>
                  <ConnectWalletConnect
                    onConnect={onConnect}
                    isLoading={isLoading}
                    setIsLoading={onHandleSetIsLoading}
                    isDisableSignatureRequest={isDisableSignatureRequest}
                    //multi network for check contract address
                    networksListToCheck={networksListToCheck}
                    isForMultiNetworkCheck={isForMultiNetworkCheck}
                    contractAddress={contractAddress}
                  />
                </Web3ReactProvider>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <ConnectTrustWallet
                  onConnect={onConnect}
                  isLoading={isLoading}
                  setIsLoading={onHandleSetIsLoading}
                  isDisableSignatureRequest={isDisableSignatureRequest}
                  //multi network for check contract address
                  networksListToCheck={networksListToCheck}
                  isForMultiNetworkCheck={isForMultiNetworkCheck}
                  contractAddress={contractAddress}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <Web3ReactProvider getLibrary={getLibrary}>
                  <div className="App">
                    <ConnectCoinbaseWallet
                      onConnect={onConnect}
                      isLoading={isLoading}
                      setIsLoading={onHandleSetIsLoading}
                      isDisableSignatureRequest={isDisableSignatureRequest}
                      //multi network for check contract address
                      networksListToCheck={networksListToCheck}
                      isForMultiNetworkCheck={isForMultiNetworkCheck}
                      contractAddress={contractAddress}
                    />
                  </div>
                </Web3ReactProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {props?.isPopup ? (
        <Modal
          className="signUpPop connectNetworkPopUp"
          animation={false}
          show={showConnetWalleteOptionas}
          onHide={onHideConnetWalleteOptions}
        >
          <Modal.Body>{getOptions()}</Modal.Body>
        </Modal>
      ) : (
        showConnetWalleteOptionas && getOptions()
      )}
    </>
  );
};

export default CustomWalletConnector;
