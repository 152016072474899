import { createAsyncThunk } from "@reduxjs/toolkit";
import * as sweetAlert from "../../../../utils/sweetAlert";
import tokenManagerService from "services/tokenManager.service";

const TokenManagerThunkAPI = {
  getAllTokenManagerAsync: createAsyncThunk(
    "tokenManager/getAllToken",
    async (payload) => {
      try {
        const response = await tokenManagerService.getAllTokenManager(payload);
        if (response?.data.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  createTokenPageAsync: createAsyncThunk(
    "/createTokenPage ",
    async ({payload, callback}) => {
      try {
        const response = await tokenManagerService.createTokenPage(payload);
        if (response?.data?.status) {
          callback({status: 1, data: response?.data})
          return response?.data;
        } else {
          callback({status: 0, data: null})
          sweetAlert.errorAlert(response?.data?.message);
        }
      } catch (error) {
        callback({status: 0, data: null})
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //check token page exist or not
  checkTokenpageExistOrNotAsync: createAsyncThunk(
    "/checkIsTokePageExist ",
    async (payload) => {
      try {
        const response = await tokenManagerService.checkTokenpageExist(payload);
        if (response?.data?.status) {
          return response?.data;
        } else {
          return response?.data;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  editTokenManagerPageAsync: createAsyncThunk(
    "editTokenPage",
    async (payload) => {
      try {
        const response = await tokenManagerService.editTokenManagerPage(
          payload
        );
        if (response?.data?.status) {
          sweetAlert.successAlert(response?.data?.message);
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get user favourite token page :
  favouriteTokenPageAsync: createAsyncThunk(
    "getUserfavouritetokenPage",
    async (payload) => {
      try {
        const response = await tokenManagerService.userFavouriteTokenPageList(
          payload
        );
        if (response?.data.status) {
          return response?.data;
        } else {

          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //delete token page
  deleteTokenPageAsync: createAsyncThunk(
    "decactivatetokenpage",
    async (payload) => {
      try {
        const response = await tokenManagerService.tokenPageDelete(payload);
        if (response?.data.status) {
          return response?.data;
        } else {

          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get fees by network
  getSpecifiedFeesByNetworkAsync: createAsyncThunk(
    "specifiedFeesByNetwork",
    async (payload) => {
      try {
        const response = await tokenManagerService.getSpecifiedFeesByNetwork(
          payload
        );
        if (response?.data.status) {
          return response?.data;
        } else {

          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),
};
export default TokenManagerThunkAPI;
