import { ethers } from "ethers";

async function checkNetworkID() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const network = await provider.getNetwork();
    return network.chainId;
  } catch (error) {}
}

async function signMessage() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const accounts = await ethereum.request({ method: "eth_accounts" });
    const signedMessage =
      await signer.signMessage(`Hello, welcome to Padify. Please sign this message to verify your wallet. This action will not cost you any transaction fee.
    ${"\n"}Address:${"\n"}${accounts[0]}`);
    return signedMessage;
  } catch (error) {
  }
}

async function getNetwork() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    return network;
  } catch (error) {}
}

async function isConnected() {
  try {
    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length) {
      return accounts[0];
    } else {
      return "";
    }
  } catch (error) {}
}
async function checkIsConnected() {
  try {
    var provider = new ethers.providers.Web3Provider(ethereum);
    const accounts = await provider.listAccounts();
    if (!accounts.length) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export {
  checkNetworkID,
  signMessage,
  isConnected,
  getNetwork,
  checkIsConnected,
};
