import React, { useState } from "react";
import { useRouter } from "next/router";
import { custValidator } from "utils/helpers";
import * as sweetAlert from "../../../utils/sweetAlert";
import Input from "componants/common/Input";

//redux
import { useDispatch } from "react-redux";
import UsersThunkAPI from "../../../store/features/users/middleware";
import FormButton from "componants/common/FormButton";

const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
};
const initialError = {
  email: null,
  password: null,
  confirmPassword: null,
};

export default function Signup(props) {
  const { setShowOTPModal, handleClose } = props;
  const [userDetails, setUserDetails] = useState(initialState);
  const [validateError, setValidateError] = useState(initialError);
  const [signupStatus, setSignupStatus] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const dispatch = useDispatch();
  const router = useRouter();
  const [showOtherFields, setShowOtherFields] = useState(true);
  let regularExpressionPassword =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  //hide or show password-
  const togglePassword = (label) => {
    if (label === "password") {
      let temp = "";
      temp = passwordType == "password" ? "text" : "password";
      setPasswordType(temp);
    } else {
      let temp = "";
      temp = passwordType2 == "password" ? "text" : "password";
      setPasswordType2(temp);
    }
  };

  //start validation for signup form-
  const onHandleUserInfo = (event, label) => {
    event.preventDefault();
    const { value } = event.target;
    switch (label) {
      case "email":
        setUserDetails({ ...userDetails, email: value.trim() });
        break;
      case "password":
        setUserDetails({ ...userDetails, password: value.trim() });
        break;
      case "confirmPassword":
        setUserDetails({ ...userDetails, confirmPassword: value.trim() });
        break;
      case "emailErr":
        if (!value.trim()) {
          setValidateError({
            ...validateError,
            email: "Please enter email",
          });
        } else if (!custValidator.isValidEmail(value)) {
          setValidateError({
            ...validateError,
            email: "Please enter valid email address",
          });
        } else {
          setValidateError({ ...validateError, email: null });
        }
        break;
      case "passError":
        if (!regularExpressionPassword.test(value)) {
          setValidateError({
            ...validateError,
            password:
              "Password should be 8-20 char, 1 upper case, 1 lower case, 1 number, 1 special char",
          });
        } else {
          setValidateError({
            ...validateError,
            password: null,
          });
        }
       
        break;
      case "confirmPassErr":
        if (value.length < 7) {
          setValidateError({
            ...validateError,
            confirmPassword: "Please enter password",
          });
        } else if (value !== userDetails.password) {
          setValidateError({
            ...validateError,
            confirmPassword: "Password mismatch",
          });
        } else {
          setValidateError({
            ...validateError,
            confirmPassword: null,
          });
        }
        break;
      default:
        setUserDetails(initialState);
        setValidateError(initialError);
        break;
    }
  };
  //end validation for signup form-

  const passCheck = Boolean(
    userDetails.email &&
      userDetails.password &&
      userDetails.confirmPassword &&
      userDetails.password === userDetails.confirmPassword
  );
  const checkError = Boolean(
    validateError.password ||
      validateError.email ||
      validateError.confirmPassword
  );
  const handleClosePopup = () => {
    setValidateError(initialError);
    handleClose();
  };

  //start onsubmit method for signup-
  const onHandleSignup = async (e) => {
    e.preventDefault();
    try {
      if (
        custValidator.isEmpty(userDetails.email) &&
        custValidator.isEmpty(userDetails.confirmPassword) &&
        passCheck &&
        !checkError
      ) {
        let payload = {
          email: userDetails.email.toLowerCase(),
          password: userDetails.confirmPassword,
          name: "",
          username: "",
          gender: "",
          bio: "",
          wallet_address: "",
          wallet_signup: false,
          date_of_birth: "",
          address: "",
          contact_number: "",
          country: "",
        };
        setSignupStatus(true);

        dispatch(
          UsersThunkAPI.signupAsync({
            payload,
            callback: (data) => {
              setSignupStatus(false);
              if (data.status == 1) {
                setUserDetails(data);
                handleClosePopup();
                setShowOTPModal(userDetails.email);
              }
            },
          })
        );
      } else {
        sweetAlert.errorAlert("Oops! Something went wrong.");
        setValidateError({
          ...validateError,
          email: "Please enter email",
          password: "Please enter strong password",
        });
      }
    } catch (error) {
      sweetAlert.errorAlert("Oops! Something went wrong.");
    }
  };
  //end onsubmit method for signup

  return (
    <div>
      <form onSubmit={(e) => onHandleSignup(e)}>
        <div className='signUpSection'>
          {showOtherFields && (
            <>
              <Input
                type='email'
                //autoFocus={true}
                className='form-control'
                parantclassName='form-group'
                placeholder='Enter your email address'
                label='Email address'
                err={validateError.email}
                value={userDetails.email}
                onChange={(e) => {
                  onHandleUserInfo(e, "email");
                }}
                onBlur={(e) => onHandleUserInfo(e, "emailErr")}
              />

              <div className='form-group'>
                <label>Password</label>
                <input
                  type={passwordType}
                  className='form-control'
                  placeholder='Enter your pasword'
                  value={userDetails.password}
                  onChange={(e) => onHandleUserInfo(e, "password")}
                  onBlur={(e) => onHandleUserInfo(e, "passError")}
                  onCut={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  minLength={8}
                  maxLength={20}
                />
                <span
                  className='showEyeIcon cursor-pointer'
                  onClick={() => togglePassword("password")}
                >
                  {passwordType === "password" ? (
                    <i className='far fa-eye-slash'></i>
                  ) : (
                    <i className='far fa-eye'></i>
                  )}
                </span>

                <div>
                  <span className='text-danger err'>
                    {validateError.password}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <label>Confirm Password</label>
                <input
                  type={passwordType2}
                  className='form-control'
                  placeholder='Please confirm password'
                  value={userDetails.confirmPassword}
                  onChange={(e) => onHandleUserInfo(e, "confirmPassword")}
                  onBlur={(e) => onHandleUserInfo(e, "confirmPassErr")}
                  onCut={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  minLength={8}
                  maxLength={20}
                />
                <span
                  className='showEyeIcon cursor-pointer'
                  onClick={() => togglePassword("confirmPassword")}
                >
                  {passwordType2 === "password" ? (
                    <i className='far fa-eye-slash'></i>
                  ) : (
                    <i className='far fa-eye'></i>
                  )}
                </span>
                <div>
                  <span className='text-danger err'>
                    {validateError.confirmPassword}
                  </span>
                </div>
              </div>

              <FormButton
                label='Create an account'
                type='submit'
                className={
                  ((!userDetails.email ||
                    !userDetails?.password ||
                    !userDetails?.confirmPassword ||
                    checkError) &&
                    "createAccountBtn2 cursor-no-drop") ||
                  " createAccountBtn"
                }
                disabled={
                  !userDetails.email ||
                  !userDetails?.password ||
                  !userDetails?.confirmPassword ||
                  checkError
                }
                onClick={onHandleSignup}
                isLoading={signupStatus}
              />

              <div className='or-block'>
                <span></span> <p>OR</p>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
