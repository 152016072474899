import React, { useEffect, useState } from "react";
import Image from "next/future/image";
import ethCoinImg from "static/images/trustwalletlogo.png";

import { ethers } from "ethers";
//bootstrap
import Button from "react-bootstrap/Button";
import Spinner from "componants/common/Spinner";

import { getTrustWalletInjectedProvider } from "./TrustWallet";
// blockchain
import { trustWalletCheckTokenVerify } from "../../../my-token-manager/tokenVerify";

export default function ConnectTrustWallet(props) {
  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const [initializing, setInitializing] = React.useState(true);
  const [injectedProvider, setInjectedProvider] = React.useState(null);
  const [initializationError, setInitializationError] = React.useState("");
  const [initializationErrorStatus, setInitializationErrorStatus] =
    React.useState(false);

  const [connected, setConnected] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const [chainId, setChainId] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    
    const initializeInjectedProvider = async () => {
      if(!window.ethereum){
        return;
      }
      await window.ethereum.enable();
      const trustWallet = await getTrustWalletInjectedProvider();

      if (!trustWallet) {
        setInitializationErrorStatus(true);
        setInitializationError("Trust Wallet is not installed.");
        setInitializing(false);
        return;
      }
      setInjectedProvider(trustWallet);
      setInitializing(false);
    };

    initializeInjectedProvider();
  }, []);
  const connect = async () => {
    setIsLocalLoading(true);
    try {
      if (initializationErrorStatus) {
        // please redirect this URL
        //https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph
        let url =
          "https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph";
        window.open(url, "_blank");
      }
      setError("");
      const accounts = await injectedProvider.request({
        method: "eth_requestAccounts",
      });
      const chainId = await injectedProvider.request({ method: "eth_chainId" });
      setSelectedAccount(accounts[0]);
      setChainId(chainId);
      setConnected(true);

      // injectedProvider.addListener("chainChanged", setChainId);
      // injectedProvider.addListener("accountsChanged", (accounts) => {
      //   if (accounts.length === 0) {
      //     setConnected(false);
      //     setSelectedAccount("");
      //     setChainId("");
      //   } else {
      //     const connectedAccount = accounts[0];
      //     setSelectedAccount(connectedAccount);
      //   }
      // });
      if (accounts[0]) {
        const injectedProvider = await getTrustWalletInjectedProvider();
        const ethersProvider = new ethers.providers.Web3Provider(
          injectedProvider
        );
        // multi network for check contract address

        if (props?.isForMultiNetworkCheck) {
          let verifyContractList = [];
          for (const element of props.networksListToCheck) {
            let item = element;
            let chain_id = "";

            switch (item.value) {
              case "ETH":
                chain_id = "0x1";
                break;
              case "BSC":
                chain_id = "0x38";
                break;
              case "POLYGON":
                chain_id = "0x89";
                break;
              case "FANTOM":
                chain_id = "0xFA";
                break;
              default:
                chain_id = "";
            }

            await switchChain(chain_id, selectedAccount);
            const value = await trustWalletCheckTokenVerify(
              props.contractAddress,
              element,
              selectedAccount
            );
            verifyContractList.push(value);
          }
          setIsLocalLoading(false);
          props?.onConnect?.({
            network: "network",
            walletAddress: selectedAccount,
            signHash: "signedMessage",
            walletType: "trustwallet",
            verifyContractList: verifyContractList,
          });

          return;
        }

        // network.name
        const signer = ethersProvider.getSigner();

        let signedMessage = null;
        if (!props?.isDisableSignatureRequest) {
          signedMessage =
            await signer.signMessage(`Hello, welcome to Padify. Please sign this message to verify your wallet. This action will not cost you any transaction fee.
      ${"\n"}Address:${"\n"}${accounts[0]}`);
        }
        const network = await ethersProvider.getNetwork();
        setIsLocalLoading(false);
        props?.onConnect?.({
          network: network,
          walletAddress: accounts[0],
          signHash: signedMessage,
          walletType: "trustwallet",
        });
       
      }
    } catch (e) {
      setIsLocalLoading(false);

      if (e.code === 4001) {
      }
    }
  };

  const switchChain = async (networkName, walletAddress) => {
    try {
      await injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networkName.toString() }],
      });
    } catch (e) {
      if (e.code === 4001) {
        setError("User rejected switching chains.");
      }
    }
  };

  const onSign = async () => {};

  const getContent = () => {
    if (isLocalLoading) {
      return (
        <>
          Trust Wallet &nbsp; &nbsp; &nbsp;
          <Spinner className="spinner-border text-light spinner-border-sm" />
        </>
      );
    } else {
      return "Trust Wallet";
    }
  };

  useEffect(() => {
    props?.setIsLoading(isLocalLoading);
  }, [isLocalLoading]);

  return (
    <div className="App-connector">
      <Button
        className="CoinSelectBox"
        onClick={() => {
          if (isLocalLoading || props?.isLoading) return;
          connect(3);
        }}
      >
        <Image alt="" src={ethCoinImg} style={{ width: 50, height: 50 }} />
        {getContent()}
      </Button>
    </div>
  );
}
