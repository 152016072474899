import React from "react";
import { ethers } from "ethers";
import { isConnected } from "../auth/wallet/walletConnectAuth";
import {
  bscTestnet,
  bscMainnet,
  ethereumMainnet,
  fantomMainnet,
  polygonMainnet,
} from "../auth/wallet/walletNetworks";
import { getTrustWalletInjectedProvider } from "../auth/CustomWallet/ConnectTrustWallet/TrustWallet";
import Web3 from "web3";
const checkContractOwner = async (walletAddress, owner) => {
  // if (walletAddress && owner)
  //   return walletAddress.toLowerCase() === owner.toLowerCase();
  // else return false;
  return true;
};

const tokenVerify = async (props, network) => {
  try {
    if (network == "ETH") await ethereumMainnet();
    else if (network == "BNB") await bscMainnet();
    else if (network == "POLYGON") await bscTestnet();
    else if (network == "FANTOM") await fantomMainnet();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contractAddress = props;

    //abi I am using in my code
    const ERC20_ABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "serviceFeeReceiver",
            type: "address",
          },
        ],
        stateMutability: "payable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [],
        name: "_serviceFeeReceiver",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "subtractedValue",
            type: "uint256",
          },
        ],
        name: "decreaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "geUnlockTime",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "addedValue",
            type: "uint256",
          },
        ],
        name: "increaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "time",
            type: "uint256",
          },
        ],
        name: "lock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "togglePaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unlock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        stateMutability: "payable",
        type: "receive",
      },
    ];

    const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);

    let tokenDetails = {};
    try {
      let name = await contract.name();
      let symbol = await contract.symbol();
      let totalSupply = await contract.totalSupply();
      let decimals = await contract.decimals();
      let owner = await contract.owner();
      let contract_address = contract.address;
      tokenDetails = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        totalSupply: ethers.utils.formatEther(totalSupply),
        owner: owner,
        contract_address: contract_address,
        networkName: network,
        status: "verified",
      };
    } catch (error) {
      // console.log("error", error);
      tokenDetails = {
        networkName: network,
        status: "Not verified",
      };
      return tokenDetails;
    }

    return tokenDetails;
  } catch (error) {}
};

async function MultiTokenVerify(props, network, walletAddress) {
  try {
    let verifyTokenStatus = [];
    for (const element of network) {
      let item = element;
      switch (item.value) {
        case "ETH":
          await ethereumMainnet();
          const ethCheck = await checkTokenVerify(props, "ETH", walletAddress);
          if (Object.keys(ethCheck).length !== 0) {
            verifyTokenStatus.push(
              Object.keys(ethCheck).length === 0 ? "" : ethCheck
            );
          } else {
            verifyTokenStatus.push({
              networkName: "ETH",
              status: "Not verified",
            });
          }
          break;
        case "BSC":
          await bscMainnet();
          const bscCheck = await checkTokenVerify(props, "BSC", walletAddress);
          if (Object.keys(bscCheck).length !== 0) {
            verifyTokenStatus.push(
              Object.keys(bscCheck).length === 0 ? {} : bscCheck
            );
          } else {
            verifyTokenStatus.push({
              networkName: "BSC",
              status: "Not verified",
            });
          }
          break;
        case "POLYGON":
          await bscTestnet();
          // polygonMainnet()
          const polyCheck = await checkTokenVerify(
            props,
            "POLYGON",
            walletAddress
          );
          if (Object.keys(polyCheck).length !== 0) {
            verifyTokenStatus.push(
              Object.keys(polyCheck).length === 0 ? {} : polyCheck
            );
          } else {
            verifyTokenStatus.push({
              networkName: "POLYGON",
              status: "Not verified",
            });
          }
          break;
        case "FANTOM":
          await fantomMainnet();
          const fantomCheck = await checkTokenVerify(
            props,
            "FANTOM",
            walletAddress
          );
          if (Object.keys(fantomCheck).length !== 0) {
            verifyTokenStatus.push(
              Object.keys(fantomCheck).length === 0 ? {} : fantomCheck
            );
          } else {
            verifyTokenStatus.push({
              networkName: "FANTOM",
              status: "Not verified",
            });
          }
          break;
        default:
          const provider = new ethers.providers.Web3Provider(ethereum);
          const network = await provider.getNetwork();
          if (network.name == "bnb") {
            const defaultEthCheck = await checkTokenVerify(
              props,
              "BSC",
              walletAddress
            );
            if (Object.keys(defaultEthCheck).length !== 0) {
              verifyTokenStatus.push(
                Object.keys(defaultEthCheck).length === 0 ? "" : defaultEthCheck
              );
            } else {
              verifyTokenStatus.push({
                networkName: "BSC",
                status: "Not verified",
              });
            }
          } else if (network.name == "bnbt") {
            const defaultEthCheck = await checkTokenVerify(
              props,
              "BNBT",
              walletAddress
            );
            if (Object.keys(defaultEthCheck).length !== 0) {
              verifyTokenStatus.push(
                Object.keys(defaultEthCheck).length === 0 ? "" : defaultEthCheck
              );
            } else {
              verifyTokenStatus.push({
                networkName: "BNBT",
                status: "Not verified",
              });
            }
          } else if (network.name == "goerli") {
            const defaultEthCheck = await checkTokenVerify(
              props,
              "GOERLI",
              walletAddress
            );
            if (Object.keys(defaultEthCheck).length !== 0) {
              verifyTokenStatus.push(
                Object.keys(defaultEthCheck).length === 0 ? "" : defaultEthCheck
              );
            } else {
              verifyTokenStatus.push({
                networkName: "GOERLI",
                status: "Not verified",
              });
            }
          } else if (network.name == "homestead") {
            const defaultEthCheck = await checkTokenVerify(
              props,
              "homestead",
              walletAddress
            );
            if (Object.keys(defaultEthCheck).length !== 0) {
              verifyTokenStatus.push(
                Object.keys(defaultEthCheck).length === 0 ? "" : defaultEthCheck
              );
            } else {
              verifyTokenStatus.push({
                networkName: "homestead",
                status: "Not verified",
              });
            }
          }
      }
    }
    return verifyTokenStatus;
  } catch (error) {
  }
}

const checkTokenVerify = async (props, network, walletAddress) => {
  let tokenDetails = {};
  const contractAddress = props ? props : "";
  try {
    let provider;
    if (network == "BSC" || network?.chainId == 56) {
      provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-dataseed1.binance.org/"
      );
    } else if (network == "ETH") {
      provider = new ethers.providers.JsonRpcProvider(
        "https://mainnet.infura.io/v3/2e652aee0b074ecdb3230784854aa3ac"
      );
    }else if (network == "homestead") {
      provider = new ethers.providers.JsonRpcProvider(
        "https://mainnet.infura.io/v3/2e652aee0b074ecdb3230784854aa3ac"
      );
    }else if (network == "GOERLI") {
      provider = new ethers.providers.JsonRpcProvider(
        "https://goerli.infura.io/v3/122632949b0c40a6905ddad43a7ded72"
      );
    } else if (network == "BNBT") {
      provider = new ethers.providers.JsonRpcProvider(
        "https://data-seed-prebsc-1-s1.binance.org:8545"
      );
    }
    const ERC20_ABI = [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_spender",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_from",
            type: "address",
          },
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [
          {
            name: "",
            type: "uint8",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
          {
            name: "_spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        payable: true,
        stateMutability: "payable",
        type: "fallback",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
    ];

    const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);
    let name = await contract.name();
    let symbol = await contract.symbol();
    let totalSupply = await contract.totalSupply();
    let decimals = await contract.decimals();
    let contract_address = contract.address;
    await contract.balanceOf("0x0000000000000000000000000000000000000000");
    tokenDetails = {
      name: name,
      symbol: symbol,
      decimals: decimals,
      totalSupply: ethers.utils.formatEther(totalSupply),
      contract_address: contract_address,
      networkName: network,
      status: "verified",
    };
    const verifyStatus = await checkContractOwner(walletAddress);

    if (verifyStatus) {
      tokenDetails = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        totalSupply: ethers.utils.formatEther(totalSupply),
        contract_address: contract_address,
        networkName: network,
        status: "verified",
      };
      return tokenDetails;
    } else {
      tokenDetails = {
        networkName: network.value,
        status: "not verified",
      };
      return tokenDetails;
    }
  } catch (error) {
    // console.log("error is: 1111111111111111", error);
    tokenDetails = {
      networkName: network,
      status: "not verified",
    };
    return tokenDetails;
  }
  // return tokenDetails;
};
const trustWalletCheckTokenVerify = async (props, network, walletAddress) => {
  let tokenDetails = {};
  try {
    const injectedProvider = await getTrustWalletInjectedProvider();
    const ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
    const contractAddress = props;
    const ERC20_ABI = [
      // Read-Only Functions
      "function decimals() view returns (uint8)",
      "function symbol() view returns (string)",
      "function name() view returns (string)",
      "function totalSupply() view returns (uint256)",
      "function owner() view returns (address)",
    ];

    const contract = new ethers.Contract(
      contractAddress,
      ERC20_ABI,
      ethersProvider
    );

    let name = await contract.name();
    let symbol = await contract.symbol();
    let totalSupply = await contract.totalSupply();
    let decimals = await contract.decimals();
    let owner = await contract.owner();
    let contract_address = contract.address;

    const verifyStatus = await checkContractOwner(walletAddress, owner);
    if (verifyStatus) {
      tokenDetails = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        totalSupply: ethers.utils.formatEther(totalSupply),
        owner: owner,
        contract_address: contract_address,
        networkName: network.value,
        status: "verified",
      };
      return tokenDetails;
    } else {
      tokenDetails = {
        networkName: network.value,
        status: "not verified",
      };
    }
    // tokenDetails = {
    //   name: name,
    //   symbol: symbol,
    //   decimals: decimals,
    //   totalSupply: ethers.utils.formatEther(totalSupply),
    //   owner: owner,
    //   contract_address: contract_address,
    //   networkName: network.value,
    //   status: "verified",
    // };
  } catch (error) {
    tokenDetails = {
      networkName: network.value,
      status: "not verified",
    };
  }
  return tokenDetails;
};
const getTokenWalletBalance = async (contractAddress) => {
  try {
    const ERC20_ABI = [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_spender",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_from",
            type: "address",
          },
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [
          {
            name: "",
            type: "uint8",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [
          {
            name: "",
            type: "string",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          {
            name: "_to",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
          {
            name: "_spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        payable: true,
        stateMutability: "payable",
        type: "fallback",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
    ];
    // const provider1 = new ethers.providers.Web3Provider(ethereum);
    // const signer = provider1.getSigner();
    // const address = await signer.getAddress();
    // const network = await provider1.getNetwork();
    // console.log("address is: ", address);

    // let provider;
    // if (network == "BSC" || network?.chainId == 56) {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://bsc-dataseed1.binance.org/"
    //   );
    // } else if (network == "ETH") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://mainnet.infura.io/v3/2e652aee0b074ecdb3230784854aa3ac"
    //   );
    // } else if (network == "GOERLI") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://mainnet.infura.io/v3/2e652aee0b074ecdb3230784854aa3ac"
    //   );
    // } else if (network == "BNBT") {
    //   provider = new ethers.providers.JsonRpcProvider(
    //     "https://data-seed-prebsc-1-s1.binance.org:8545"
    //   );
    // }
    // Check if MetaMask is installed and enabled
    if (typeof window.ethereum === "undefined") {
      throw new Error("MetaMask is not installed.");
    }

    // Request access to the user's MetaMask wallet
    await window.ethereum.enable();

    // Create a Web3 provider using the current provider from MetaMask
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    // Get the user's address
    const address = await signer.getAddress();
    // Create a new instance of the ERC20 token contract

    const tokenContract = new ethers.Contract(
      contractAddress,
      ERC20_ABI,
      provider
    );

    // Get the token balance of the wallet address
    const balance = await tokenContract.balanceOf(address);
    const symbol = await tokenContract.symbol();
    const decimals = await tokenContract.decimals();
    const etherValue = ethers.utils.formatUnits(balance, decimals);

    // // const signer1 = provider.getSigner();
    // const token_contract_address = new ethers.Contract(
    //   contractAddress,
    //   ERC20_ABI,
    //   signer
    // );
    // console.log("token_contract_address is: ", token_contract_address);
    // const balance = await token_contract_address.balanceOf(
    //   "0x5C82BdB5D1FcF58d429F2172aA675A178088862c"
    // );
    // const symbol = await token_contract_address.symbol();
    // console.log("balance is: ", balance);
    // const etherValue = ethers.utils.formatUnits(balance, "ether");
    return { wallet_bal: etherValue, symbol, decimals };
  } catch (error) {
    // console.log("error is: ", error);
  }
};
export {
  tokenVerify,
  checkContractOwner,
  MultiTokenVerify,
  checkTokenVerify,
  trustWalletCheckTokenVerify,
  getTokenWalletBalance,
};
