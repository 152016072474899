var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"QPuTr3a2jRyvWhMl_q_Tl"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.ENV_MODE === "LIVE") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://32734afd4f3947ea8d8dc3cdc19f56fd@o4505035033673728.ingest.sentry.io/4505035338743808",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
  });
}
