import axiosInstance from "./axios";
// import { getAuthToken } from "./authToken.service";
const headers = {
  "Content-Type": "application/json",
  // "x-access-token": "",
};
const commonServices = {
  getPageMetaData: async function (payload) {
    return axiosInstance.post("getPageMetaData", payload, { headers });
  },
  getparticularcharge: async function (payload) {
    // create_launchpad
    // create_privatesale
    // create_token_page
    // kyc_and_audit
    // advance_token
    // liquidity_fees
    // {
    //    "keyValue":"ALL"
    // }
    return axiosInstance.post("getparticularcharge", payload);
  },
  getExplanatoryVideoLink: async function (payload) {
    return axiosInstance.post("getExplanatoryVideo", payload);
  },
  getAllNotifications: async function (payload) {
    return axiosInstance.post("getAllNotifications", payload);
  },
  getSingleNotification: async function () {
    return axiosInstance.get("getSingleNotification");
  },
  getAdminwalletaddress: async function (payload) {
    // return axiosInstance.post("manageWalletAddress", payload);
    return axiosInstance.post("getSingleAdminWalletAddress", payload);

  },

  changeNotificationStatus: async function (payload) {
    return axiosInstance.post("changeNotificationStatus", payload);
  },

  //check kyc payment
  checkKycPayment: async function (payload) {
    return axiosInstance.post("checkPaymentStatus", payload);
  },

  //check kyc verification status
  checkKycVerificationStatus: async function (payload) {
    return axiosInstance.post("kycVerificationStatus", payload);
  },
};
export default commonServices;
