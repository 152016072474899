import React from "react";
import moment from "moment";
import Image from "next/future/image";

// icons
import notilaunchpad from "static/images/noti-launchpad.svg";
import notiprivateSale from "static/images/noti-privateSale.svg";
import notiTokenCreation from "static/images/noti-TokenCreation.svg";
import notitokenPage from "static/images/noti-tokenPage.svg";
import notitransfer from "static/images/noti-transfer.svg";
import notiwarning from "static/images/noti-warning.svg";
import notiairdrop from "static/images/noti-airdrop.svg";
import notianyNoti from "static/images/noti-anyNoti.svg";
import notianyInfo from "static/images/noti-anyInfo.svg";
const NotificationItem = (props) => {
  const { item, key, onClick,index } = props;
  const getIcon = (noti_type) => {
    let icon = null;
    switch (noti_type) {
      case "NOTI_ANYNOTI":
        icon = notianyNoti;
        break;

      case "NOTI_ANYINFO":
        icon = notianyInfo;
        break;

      case "NOTI_AIREDROP":
        icon = notiairdrop;
        break;

      case "NOTI_LAUNCHPAD":
        icon = notilaunchpad;
        break;

      case "NOTI_PRIVATE_SALE":
        icon = notiprivateSale;
        break;

      case "NOTI_TOKEN_CREATION":
        icon = notiTokenCreation;
        break;

      case "NOTI_TOKENPAGE":
        icon = notitokenPage;
        break;

      case "NOTI_TRANSFER":
        icon = notitransfer;
        break;

      case "NOTI_WARNING":
        icon = notiwarning;
        break;

      default:
        icon = notianyNoti;
    }
    return icon;
  };

  return (
    <div
      className={item?.isRead ? "mainNotiBox cursor-pointer" : "mainNotiBox unReadMsg cursor-pointer"}
      key={key}
      onClick={() => {
        if (onClick) {
          onClick({id:item?._id,index});
        }
      }}
    >
      <div className="iconNoti">
        <Image alt="" src={getIcon(item?.type)} />
      </div>
      <div className="titleNotiBox">
        <h3 className="font14 fontBold mb6"> {item?.title}</h3>
        <h3 className="font14 mb0 colorGrey">{item?.description}</h3>
      </div>
      <div className="timeNotification font12 colorGrey">
        {moment(item?.created_at).startOf("hour").fromNow()}{" "}
        <i class="fas fa-circle"></i>
      </div>
    </div>
  );
};

export default NotificationItem;
