//images
import sweplyLogo from "../static/images/sweplyLogo.svg";
import showLink from "../static/images/show-link.png";
import showTwitter from "../static/images/show-twitter.png";
import showTelegram from "../static/images/show-telegram.png";
import showLinkedin from "../static/images/show-linkedin.png";
import showReddit from "../static/images/show-reddit.png";
import showInsta from "../static/images/show-insta.png";
import showDiscord from "../static/images/show-discord.png";
import chainIcon from "../static/images/chainicon.svg";
import show_facebook from "../static/images/show-facebook.png";
import show_gitHub from "../static/images/show-gitHub.png";
import moment from "moment";
import BigNumber from "bignumber.js";
import da from "date-fns/locale/da";

import ethCoinImg from "static/images/eth.svg";
import bnbCoinImg from "static/images/bnb.svg";
import maticCoinImg from "static/images/matic.svg";
import not_supported_chain_icon from "static/images/not-supported-chain-icon.svg";
import axios from "axios";
import Web3 from "web3";
import { ethers } from "ethers";


// check valid wallet address
export const isValidAddress = (address) =>{
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch (error) {
    return false;
  }
}

// Start validation functions
export const custValidator = {
  //returns true if no is float else false
  isFloat: (value) => value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/),
  isNumber: (value) => value.match(/^[0-9\b]+$/),
  isEmpty: (value) => value !== undefined && value !== null && value !== "",
  isCharecters: (value) => value.match(/^[A-Za-z ]+$/),
  isValidEmail: (value) => {
    return !!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  },
};
// End validation functions

export const isDecimal = (value) => {
  const patt = /^\d+\.{0,1}\d{0,9}$/;
  return patt.test(value);
};

export const isEmpty = (str) => {
  return Boolean(str !== undefined && str !== null && str !== " ");
};

//remove html tags
export const removeHtmlTags = (str) => {
  if (!str) return str;
  return str.replace(/<(.|\n)*?>/g, "");
};

//amount
export const addCommasInAmount = (str) => parseFloat(str).toLocaleString("en");
export const addCommasInAmountForToken = (str) => {
  const number = new BigNumber(str.toString());
  const formattedNumber = number.toFormat();
  return formattedNumber;
};
export const addCommasInAmountInTokenPage = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3
});

export const commaSeparators = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e12
    ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(0) + " T"
    : // Nine Zeroes for Billions 
    Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + " K"
          : Math.abs(Number(labelValue));
};

export const convertToInternationalCurrencySystemValue = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? " B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
      ? " M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
        ? " K"
        : Math.abs(Number(labelValue));
};

export const formatNumber = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3

        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

        : Math.abs(Number(labelValue));
}

export const commarize = (value) => {
  // Alter numbers larger than 1k
  if (value >= 1e3) {
    const units = ["K", "M", "B", "T", "P", "E", "Z", "Y"];

    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    const unit = Math.floor(Math.log10(value) / 3);
    const num = (value / Math.pow(10, unit * 3)).toFixed(2);
    const unitname = units[unit - 1];

    // Output number remainder + unitname
    return num + unitname;
  }

  // Return formatted original number
  return value.toLocaleString();
};
export const zeroCount = (value) => {
  // Convert the number to a string
  let numberString = value.toString();

  // Find the index of the decimal point
  let decimalIndex = numberString.indexOf('.');

  // Check if the decimal point exists
  if (decimalIndex !== -1) {
    // Get the substring after the decimal point
    let decimalPart = numberString.substring(decimalIndex + 1);

    // Count the number of zeros
    let zeroCount = 0;
    for (let i = 0; i < decimalPart.length; i++) {
      if (decimalPart[i] === '0') {
        zeroCount++;
      } else {
        break; // Exit the loop if a non-zero digit is encountered
      }
    }
    return zeroCount;
  } else {
    return 0;
  }
}

export const _parseInt = (value) => {
  if (typeof value === "number" && !Number.isNaN(value)) return value;
  return parseInt(value);
};

export const _parseFloat = (value) => {
  if (typeof value === "number" && !Number.isNaN(value)) return value;
  return parseFloat(value);
};

export const removeProtocol = (url) => {
  if (!url) return "";

  return url.replace(/(^\w+:|^)\/\//, "");
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addDotToEndOfString = (str, numberOfCharsToShow) => {
  if (!str || !numberOfCharsToShow) return str;
  if (str.length < numberOfCharsToShow) return str;
  return str?.slice(0, numberOfCharsToShow) + "...";
};

//mask wallet address
export const addDotsMiddleOfString = (str) => {
  if (!str) return "";
  return `${str.slice(0, 5)} ... ${str.slice(-5)}`;
};

//forms
export const getSocialIcon = (value) => {
  let icon = chainIcon;
  switch (value) {
    case "linkedin":
      icon = showLinkedin;
      break;

    case "github":
      icon = show_gitHub;
      break;

    case "discord":
      icon = showDiscord;
      break;

    case "facebook":
      icon = show_facebook;
      break;

    case "talkwalker":
      icon = chainIcon;
      break;

    case "instagram":
      icon = showInsta;
      break;

    case "twitter":
      icon = showTwitter;
      break;

    case "web_link":
      icon = chainIcon;
      break;
  }

  return icon;
  // }
};

export const validateSoftCap = (
  soft_cap,
  hard_cap,
  softcap_how_many_percent_of_hardcap
) => {
  let softcap = _parseFloat(soft_cap);
  let hardcap = _parseFloat(hard_cap);

  let errMsg = "";

  if (!hardcap) hardcap = 0;
  // if (!softcap) softcap = 0;

  if (!soft_cap) errMsg = "Softcap cannot be blank";
  else if (softcap <= 0) errMsg = "Softcap should be greter than zero";
  else if (softcap < (hardcap / 100) * softcap_how_many_percent_of_hardcap)
    errMsg = "Softcap must be greater than or equal 50% of Hardcap";
  else if (softcap > hardcap)
    errMsg = "Softcap must be less than or equal Hardcap";
  else errMsg = "";

  return errMsg;
};

export const validateMinBuy = (
  minimun_buy,
  maximum_buy,
  soft_cap,
  hard_cap,
  minbuy_how_many_percent_of_max_by
) => {
  // _parseFloat
  let minimunbuy = _parseFloat(minimun_buy) || 0;
  let maximumbuy = _parseFloat(maximum_buy) || 0;

  let errMsg = "";

  // if (!minimunbuy) minimunbuy = 0;
  if (!maximumbuy) maximumbuy = 0;

  // must be positive number

  if (Number(minimun_buy) > Number(hard_cap))
    errMsg = "Minimum buy cannot be greter than hard cap";
  else if (!minimun_buy) errMsg = "Minimum buy cannot be blank";
  else if (minimunbuy <= 0) errMsg = "Minimum buy should be greter than zero";
  // else if (minimunbuy < (maximumbuy / 100) * minbuy_how_many_percent_of_max_by)
  //   errMsg = "Minimum buy must be greater than or equal 50% of minimun buy";
  else if (minimunbuy >= maximumbuy)
    errMsg = "Minimum buy must be less than max buy";
  else errMsg = "";

  return errMsg;
};

export const validateFundRelease = (first_fund_release, fund_release) => {
  let remainPercent = 100;

  if (fund_release) fund_release = _parseFloat(fund_release);

  if (first_fund_release) {
    first_fund_release = _parseFloat(first_fund_release);
    remainPercent = 100 - first_fund_release;
  }

  let err = "";

  if (!fund_release) err = "Fund release can not be blank or 0";
  else if (fund_release <= 0) err = "Fund release should be greter than zero";
  else if (fund_release > remainPercent)
    err =
      "First release for presale and Cycle Release Percent must be less than 100 percent";
  else err = "";
  return err;
};

export const getStatusClass = (statusArg) => {
  if (statusArg === "LIVE" || statusArg === "ONGOING") return "liveBox font12";
  else if (statusArg === "UPCOMMING" || statusArg === "UPCOMING")
    return "liveBox upcoming font12";
  else return "liveBox ended font12";
};

export const formatDate = (date_timestamp) => {
  const timestamp = date_timestamp;
  const date = new Date(timestamp * 1000); // convert seconds to milliseconds
  const options = {
    timeZone: "UTC",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

export const fixTimezoneOffset = (date) => {
  if (!date) return "";
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

export const getTimeStampWithFixTimezon = (temp_date) => {
  let timestamp = moment(fixTimezoneOffset(temp_date)).format("X");
  return timestamp;
};

export const timeStampToUtcDateObj = (temp_timestamp) => {
  const timestamp = temp_timestamp;

  let d = new Date(timestamp * 1000).toUTCString();

  return d;
};

export const getTokenProgressPercentage = (
  contract_hardCap,
  get_total_invested_amount
) => {
  try {
    if (!contract_hardCap || !get_total_invested_amount) return 0;
    // let val_1 = _parseFloat(details?.max_buy);
    let val_1 = _parseFloat(contract_hardCap);
    if (val_1 <= 0) return 0;
    let val_2 = _parseFloat(get_total_invested_amount);

    if (val_2 <= 0) return 0;
    const value = (val_2 / val_1) * 100;
    return value.toFixed(2);
  } catch (error) { }
};

// for set network chain and static chain object in header
export const getNetworkChainName = (network_arg) => {
  let name, chainId;
  let temp_name = "";

  if (network_arg) {
    name = network_arg?.name;
    chainId = network_arg?.chainId;
  }
  switch (chainId) {
    case 1: //"homestead"
      temp_name = "ETH";
      break;
    case 56: //"bnb"
      temp_name = "BSC";

      break;
    case 97: //"bnbt"
      temp_name = "BSC";

      break;
    case 97: //"bnbt"
      temp_name = "BSC";

      break;
    case 5: //"GoerliETH"
      temp_name = "Goerli ETH";
      // temp_name = "BSC TESTNET";

      break;

    default:
      temp_name = "Not Supported";
  }

  return temp_name;
};

export const getNetworkChainImageSrcByName = (network_arg) => {
  let name;
  let temp_name = ""; //yo
  if (network_arg) {
    name = network_arg;
  }
  switch (name) {
    case "homestead": //"1 homestead"
      temp_name = ethCoinImg;
      break;
    case "bnbt": // 56 "bnb"
      temp_name = bnbCoinImg;

      break;
    case "bnb": //97 "bnbt"
      temp_name = bnbCoinImg;

      break;
    case "goerli": //5 "GoerliETH"
      temp_name = ethCoinImg;
      break;

    default:
      temp_name = not_supported_chain_icon; //yo
  }

  return temp_name;
};

export const getNetworkChainImageSrc = (network_arg) => {
  let name, chainId;
  let temp_name = ""; //yo
  if (network_arg) {
    // name = network_arg?.name;
    chainId = _parseInt(network_arg?.chainId);
  }
  switch (chainId) {
    case 1: //"homestead"
      temp_name = ethCoinImg;
      break;
    case 56: //"bnb"
      temp_name = bnbCoinImg;
      break;
    case 97: //"bnbt"
      temp_name = bnbCoinImg;
      break;
    case 5: //"GoerliETH"
      temp_name = ethCoinImg;
      break;
    default:
      temp_name = not_supported_chain_icon; //yo
  }

  return temp_name;
};

export const getNetworkChainNameForPopup = (network_arg) => {
  let name, chainId;
  let temp_name = "";

  if (network_arg) {
    name = network_arg?.name;
    chainId = network_arg?.chainId;
  }
  switch (chainId) {
    case 1: //"homestead"
      temp_name = "Ethereum";
      break;
    case 56: //"bnb"
      temp_name = "BNB Smart Chain";
      break;
    case 97: //"bnbt"
      temp_name = "BNB Smart Chain";

      break;
    case 5: //"GoerliETH"
      temp_name = "Goerli ETH";
      // temp_name = "BSC TESTNET";

      break;

    default:
      temp_name = "Not Supported";
  }

  return temp_name;
};

export const network_chain_static_obj = [
  {
    // eth mainnet
    name: "homestead",
    chainId: 1,
    ensAddress: null,
  },
  {
    // eth mainnet
    name: "goerli",
    chainId: 5,
    ensAddress: null,
  },

  {
    //bnb mainnet
    name: "bnb",
    chainId: 56,
    ensAddress: null,
    _defaultProvider: null,
  },

  {
    //bsc testnate
    name: "bnbt",
    chainId: 97,
    ensAddress: null,
    _defaultProvider: null,
  },
];

export const getNetworkChainStaticObj = (chainId) => {
  return network_chain_static_obj.find((u) => u.chainId == chainId);
};

// currency switch depend on connected network
export const network_chain_ETH_Mainnet = [
  {
    id: "ETH",
    optionLabel: "ETH",
    defaultValue: "ETH",
    checked: false,
  },
  {
    id: "USDT",
    optionLabel: "USDT",
    defaultValue: "USDT",
    checked: false,
  },
  {
    id: "USDC",
    optionLabel: "USDC",
    defaultValue: "USDC",
    checked: false,
  },
];
export const network_chain_BNB_Mainnet = [
  {
    id: "BNB",
    optionLabel: "BNB",
    defaultValue: "BNB",
    checked: false,
  },
  {
    id: "BUSD",
    optionLabel: "BUSD",
    defaultValue: "BUSD",
    checked: false,
  },
  {
    id: "USDT",
    optionLabel: "USDT",
    defaultValue: "USDT",
    checked: false,
  },
  {
    id: "USDC",
    optionLabel: "USDC",
    defaultValue: "USDC",
    checked: false,
  },
];
export const network_chain_BNB_Testnet = [
  {
    id: "BNB",
    optionLabel: "BNB",
    defaultValue: "BNB",
    checked: false,
  },
  {
    id: "BUSD",
    optionLabel: "BUSD",
    defaultValue: "BUSD",
    checked: false,
  },
  {
    id: "USDC",
    optionLabel: "USDC",
    defaultValue: "USDC",
    checked: false,
  },
  {
    id: "USDT",
    optionLabel: "USDT",
    defaultValue: "USDT",
    checked: false,
  },
];

// export const networkChainFilterOptions = [
//   { chainId: "-", label: "All chains", value: "ALL" },
//   { chainId: 1, label: "Ethereum", value: 1 },
//   { chainId: 56, label: "BNB", value: 56 },
//   // { chainId: 97, label: "BNB TESTNET", value: 97 },
//   // { chainId: "-", label: "My Private Sale", value: "my_private_sale" },
// ];

export const networkChainFilterOptionsMain = [
  { chainId: 1, label: "Ethereum", value: 1 },
  { chainId: 56, label: "BSC", value: 56 },
  // { chainId: 97, label: "BNB TESTNET", value: 97 },
  // { chainId: "-", label: "My Private Sale", value: "my_private_sale" },
];

export const networkChainFilterOptionsTest = [
  { chainId: 1, label: "Ethereum Test", value: 5 },
  { chainId: 56, label: "BSC Test", value: 97 },
  // { chainId: 97, label: "BNB TESTNET", value: 97 },
  // { chainId: "-", label: "My Private Sale", value: "my_private_sale" },
];

export const isMainnetSelected = (chain_id) => {
  return networkChainFilterOptionsMain.some(
    (item) => item?.chainId == chain_id
  );
};
export const kyc_attempt_limit = 5;

//add social media link
export const initialSocialMedias = [
  { value: "twitter", label: "Twitter", isDisabled: false },
  { value: "telegram", label: "Telegram", isDisabled: false },
  { value: "instagram", label: "Instagram", isDisabled: false },
  { value: "facebook", label: "Facebook", isDisabled: false },
  { value: "discord", label: "Discord", isDisabled: false },
  { value: "twitch", label: "Twitch", isDisabled: false },
  { value: "linkedin", label: "LinkedIn", isDisabled: false },
  { value: "reddit", label: "Reddit", isDisabled: false },
  { value: "youTube", label: "YouTube", isDisabled: false },
  { value: "tikTok", label: "TikTok", isDisabled: false },
  { value: "weChat", label: "WeChat", isDisabled: false },

  // { value: "github", label: "Github", isDisabled: false },
  // { value: "talkwalker", label: "Talkwalker", isDisabled: false },
];

export const initialBlockExplorerLinks = [
  { value: "EtherScan", label: "EtherScan", isDisabled: false },
  { value: "BscScan", label: "BscScan", isDisabled: false },
  // { value: "Polygonscan", label: "polygonscan", isDisabled: false },
];

export let projectTypeOptions = [
  { label: "Adult", value: "Adult" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "AMM", value: "AMM" },
  { label: "Art", value: "Art" },
  { label: "Asset management", value: "AssetManagement" },
  { label: "Binance Launchpad", value: "Binance Launchpad" },
  { label: "Centralized Exchange (CEX) Token", value: "Centralized Exchange (CEX) Token" },
  { label: "Collectibles & NFTs", value: "CollectiblesAndNFTs" },
  { label: "Commodities", value: "Commodities" },
  { label: "Content Creation", value: "Content Creation" },
  { label: "Cybersecurity", value: "Cybersecurity" },
  { label: "DAPP", value: "DAPP" },
  { label: "DAO", value: "DAO" },
  { label: "Decentralized exchange", value: "DecentralizedExchange" },
  { label: "DeFi", value: "DeFi" },
  { label: "Education", value: "Education" },
  { label: "Energy", value: "Energy" },
  { label: "Enterprise Solutions", value: "Enterprise Solutions" },
  { label: "Events", value: "Events" },
  { label: "Ethereum Ecosystem", value: "Ethereum Ecosystem" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Fashion", value: "Fashion" },
  { label: "Food & Beverage", value: "FoodAndBeverage" },
  { label: "Gambling", value: "Gambling" },
  { label: "Gaming", value: "Gaming" },
  { label: "Governance", value: "Governance" },
  { label: "Government", value: "Government" },
  { label: "Hardware", value: "Hardware" },
  { label: "Health", value: "Health" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Interoperability", value: "Interoperability" },
  { label: "Maritime", value: "Maritime" },
  { label: "Marketplace", value: "Marketplace" },
  { label: "Marketplaces", value: "Marketplaces" },
  { label: "Marketing", value: "Marketing" },
  { label: "Media", value: "Media" },
  { label: "Medium of Exchange", value: "Medium of Exchange" },
  { label: "Meme", value: "Meme" },
  { label: "Metaverse", value: "Metaverse" },
  { label: "Military", value: "Military" },
  { label: "Music", value: "Music" },
  { label: "NFT", value: "NFT" },
  { label: "Payments", value: "Payments" },
  { label: "Philanthropy", value: "Philanthropy" },
  { label: "Platform", value: "Platform" },
  { label: "Play To Earn", value: "Play To Earn" },
  { label: "PoS", value: "PoS" },
  { label: "PoW", value: "PoW" },
  { label: "Privacy", value: "Privacy" },
  { label: "Real Estate", value: "RealEstate" },
  { label: "Retail", value: "Retail" },
  { label: "Services", value: "Services" },
  { label: "Smart Contracts", value: "SmartContracts" },
  { label: "Sports", value: "Sports" },
  { label: "Stablecoin", value: "Stablecoin" },
  { label: "Staking", value: "Staking" },
  { label: "Store of Value", value: "StoreOfValue" },
  { label: "Technology", value: "Technology" },
  { label: "Web3", value: "Web3" },
  { label: "Yield Farming", value: "Yield Farming" }
];

// export const getTokenHolders = async (tokenAddress, chain) => {
//   try {
//     var data = JSON.stringify({
//       query: `query MyQuery {\n  EVM(dataset: realtime, network: ${chain}) {\n    BalanceUpdates(\n      orderBy: {descending: BalanceUpdate_Amount}\n      where: {Currency: {SmartContract: {is: "${tokenAddress}"}}, Block: {Date: {}}}\n      limit: {count: 10}\n    ) {\n      BalanceUpdate {\n        Address\n        Amount\n      }\n    }\n  }\n}\n`,
//       variables: "{}",
//     });

//     var config = {
//       method: "post",
//       url: "https://streaming.bitquery.io/graphql",
//       headers: {
//         "Content-Type": "application/json",
//         "X-API-KEY": "BQYD8sc9GfIX4fRbFr4jxYVw1zwsElft",
//       },
//       data: data,
//     };
//     const response = await axios(config);
//     return response?.data?.data?.EVM?.BalanceUpdates;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// };

//get social media base url Ex  :"https://www.linkedin.com/"
export const getSocialBaseUrl = (social_media_name) => {
  if (social_media_name) {
    social_media_name = social_media_name.toLowerCase();
  }
  let baseUrl;
  switch (social_media_name) {
    case "linkedin":
      baseUrl = "https:";
      break;

    case "github":
      baseUrl = "https:";
      break;

    case "discord":
      baseUrl = "https:";
      break;

    case "facebook":
      baseUrl = "https://";
      break;

    case "talkwalker":
      baseUrl = "https:";
      break;

    case "instagram":
      baseUrl = "https://";
      break;

    case "twitter":
      baseUrl = "https://";
      break;

    case "web_link":
      baseUrl = "https:";
      break;
    // ------------------------------------------------------
    case "telegram":
      baseUrl = "https:";
      break;

    case "twitch":
      baseUrl = "https:";
      break;

    case "reddit":
      baseUrl = "https:";
      break;

    case "youtube":
      baseUrl = "https:";
      break;

    case "tiktok":
      baseUrl = "https:";
      break;

    case "wechat":
      baseUrl = "https:";
      break;
  }

  return baseUrl;
};

export const getTokenHolders = async (tokenAddress, chain) => {
  try {
    var data = JSON.stringify({
      query: `query MyQuery {\n  EVM(dataset: realtime, network: ${chain}) {\n    BalanceUpdates(\n      orderBy: {descending: BalanceUpdate_Amount}\n      where: {Currency: {SmartContract: {is: "${tokenAddress}"}}, Block: {Date: {}}}\n      limit: {count: 10}\n    ) {\n      BalanceUpdate {\n        Address\n        Amount\n      }\n    }\n  }\n}\n`,
      variables: "{}",
    });
    console.log('data is: ', data)
    var config = {
      method: "post",
      url: "https://streaming.bitquery.io/graphql",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "BQYD8sc9GfIX4fRbFr4jxYVw1zwsElft",
      },
      data: data,
    };
    const response = await axios(config);
    return response?.data?.data?.EVM?.BalanceUpdates;
  } catch (err) {
    console.log('err is: ', err)
    return null;
  }
};

export const formatValue = (value) => {
  const formattedValue = Number(value).toFixed(2);
  if (formattedValue.replace(/0+$/, '') === formattedValue) {
    return Number(value).toFixed(4);
  } else {
    return formattedValue;
  }
}

export const getWeb3Instance = (network) => {
  var rpc = "";
  switch (network) {
    case "eth":
      rpc = "https://rpc.ankr.com/eth";
      break;
    case "ETH":
      rpc = "https://rpc.ankr.com/eth";
      break;
    case "bsc":
      rpc = "https://rpc.ankr.com/bsc";
      break;
    case "BSC":
      rpc = "https://rpc.ankr.com/bsc";
      break;
    default:
      rpc = "https://rpc.ankr.com/eth";
      break;
  }
  const web3 = new Web3(rpc);
  return web3;
};

export const getTxCounts = async (network, address) => {
  var net = "ethereum";
  switch (network) {
    case "eth":
      net = "ethereum";
      break;
    case "bsc":
      net = "bsc";
      break;
    default:
      net = "ethereum";
      break;
  }
  var data = JSON.stringify({
    query: `{\n  ethereum(network: ${net}) {\n    smartContractCalls(\n      smartContractAddress: {is: "${address}"}\n    ) {\n      count\n    }\n  }\n}\n`,
    variables: "",
  });

  var config = {
    method: "post",
    url: "https://graphql.bitquery.io",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYD8sc9GfIX4fRbFr4jxYVw1zwsElft",
    },
    data: data,
  };

  const txRes = await axios(config);
  return txRes.data?.data?.ethereum?.smartContractCalls?.[0]?.count;
};

export const getLastTotalLiquidity = async (network, pool) => {
  var today = new Date();
  today.setDate(today.getDate() - 1);
  var lastDay = `${today.getFullYear()}-${today.getMonth() + 1 > 10
    ? today.getMonth() + 1
    : `0${today.getMonth() + 1}-${today.getDate() > 10 ? today.getDate() : `0${today.getDate()}`
    }`
    }`;
  var data = JSON.stringify({
    query: `{\n  ethereum(network: ${network === "eth" ? "ethereum" : "bsc"
      }) {\n    address(address: {is: "${pool}"}) {\n      balances(\n        currency: {in: ["WBNB", "${network === "eth"
        ? "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
        : "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
      }"]}\n        date: {before: "${lastDay}"}\n      ) {\n        currency {\n          symbol\n        }\n        value\n      }\n    }\n  }\n}\n`,
    variables: "{}",
  });
  var config = {
    method: "post",
    url: "https://graphql.bitquery.io",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYD8sc9GfIX4fRbFr4jxYVw1zwsElft",
    },
    data: data,
  };

  const response = await axios(config);
  if (response?.data?.data?.ethereum?.address?.[0]?.balances?.[0]?.value) {
    return response?.data?.data?.ethereum?.address?.[0]?.balances?.[0]?.value;
  } else {
    return 0
  }

};

// Twitter - https://twitter.com/
// Telegram - https://web.telegram.org/
// Instagram - https://www.instagram.com/
// Facebook - https://www.facebook.com/
// Discord - https://discord.com/
// Twitch - https://www.twitch.tv/
// LinkedIn - https://www.linkedin.com/
// Reddit - https://www.reddit.com/
// YouTube - https://www.youtube.com/
// TikTok - https://www.tiktok.com/
// WeChat - https://www.wechat.com/
