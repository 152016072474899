import { createSlice, current } from "@reduxjs/toolkit";

import commonThunkAPI from "./middleware";

const initialState = {
  allcharges: null,
  isLoading: false,
  allNotifications: null,
  sidebarNotification: null,
  isLoadingSidebarNotification: false,
  singleNotificationDetails: null,
  adminWalletAddress: null,
  kycStatus: null,
  kycPaymentStatus: null,
  kycVerificationStatus: null,
  videoLinks: null,
  kycVerificationErrMsg: null,
  status: null,
  loadingNotiStatus: false,
  commonMessage: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    readNotification: (state, action) => {
      let p = action?.payload;

      if (state?.allNotifications && state?.allNotifications?.list) {
        state.allNotifications.list[p?.index].isRead = true;
      }

      if (state?.allNotifications && state?.allNotifications?.unread_count) {
        state.allNotifications.unread_count -= 1;
      }
      if (
        state?.sidebarNotification &&
        state?.sidebarNotification?.unread_count
      ) {
        state.sidebarNotification.unread_count -= 1;
      }
    },
    setAllNotificationRead: (state, action) => {
      if (state?.allNotifications && state?.allNotifications?.unread_count) {
        state.allNotifications.unread_count = 0;
      }
      if (
        state?.sidebarNotification &&
        state?.sidebarNotification?.unread_count
      ) {
        state.sidebarNotification.unread_count = 0;
      }
    },
    clearAllStatusCommon: (state, action) => {
      state.kycStatus = null;
      state.kycVerificationStatus = null;
    },
    setCommonMessage: (state, action) => {
      state.commonMessage = action.payload;
    },
    removeCommonMessage: (state, action) => {
      state.commonMessage = "";
    },
  },
  extraReducers: {
    [commonThunkAPI.getparticularcharge.pending]: (state, action) => {
      state.isLoading = true;
    },
    [commonThunkAPI.getparticularcharge.fulfilled]: (state, action) => {
      state.allcharges = action?.payload;
      state.isLoading = false;
    },
    [commonThunkAPI.getparticularcharge.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // rigit sidebar all
    [commonThunkAPI.getRigitSidebarNotifications.pending]: (state, action) => {
      state.isLoadingSidebarNotification = true;
    },
    [commonThunkAPI.getRigitSidebarNotifications.fulfilled]: (
      state,
      action
    ) => {
      state.sidebarNotification = action?.payload;
      state.isLoadingSidebarNotification = false;
    },
    [commonThunkAPI.getRigitSidebarNotifications.rejected]: (state, action) => {
      state.isLoadingSidebarNotification = false;
    },

    //all
    [commonThunkAPI.getAllNotifications.pending]: (state, action) => {
      state.isLoadingAllNotifications = true;
    },
    [commonThunkAPI.getAllNotifications.fulfilled]: (state, action) => {
      state.allNotifications = action?.payload;
      state.isLoadingAllNotifications = false;
    },
    [commonThunkAPI.getAllNotifications.rejected]: (state, action) => {
      state.isLoadingAllNotifications = false;
    },

    //change notification status
    [commonThunkAPI.changeNotificationStatus.pending]: (state, action) => {
      state.loadingNotiStatus = true;
    },
    [commonThunkAPI.changeNotificationStatus.fulfilled]: (state, action) => {
      state.loadingNotiStatus = false;
    },
    [commonThunkAPI.changeNotificationStatus.rejected]: (state, action) => {
      state.loadingNotiStatus = false;
    },

    //single
    [commonThunkAPI.getSingleNotification.pending]: (state, action) => {
      state.isLoadingSingleNotificationDetails = true;
    },
    [commonThunkAPI.getSingleNotification.fulfilled]: (state, action) => {
      state.singleNotificationDetails = action?.payload;
      state.isLoadingSingleNotificationDetails = false;
    },
    [commonThunkAPI.getSingleNotification.rejected]: (state, action) => {
      state.isLoadingSingleNotificationDetails = false;
    },

    //get admin wallet addresss
    [commonThunkAPI.getAdminWalletAddress.pending]: (state, action) => {
      state.status = true;
    },
    [commonThunkAPI.getAdminWalletAddress.fulfilled]: (state, action) => {
      state.adminWalletAddress = action?.payload;
      state.status = action?.payload?.status;
    },
    [commonThunkAPI.getAdminWalletAddress.rejected]: (state, action) => {
      state.status = false;
    },

    //check kyc payment status
    [commonThunkAPI.checkKycPaymentAsync.pending]: (state, action) => {
      state.kycStatus = null;
      state.kycPaymentStatus = null;
    },
    [commonThunkAPI.checkKycPaymentAsync.fulfilled]: (state, action) => {
      state.kycStatus = action?.payload;
      state.kycPaymentStatus = action?.payload;
    },
    [commonThunkAPI.checkKycPaymentAsync.rejected]: (state, action) => {
      state.kycStatus = null;
      state.kycPaymentStatus = null;
    },

    //check kyc verification status
    [commonThunkAPI.checkKycVerificationStatusAsync.pending]: (
      state,
      action
    ) => {
      state.kycVerificationStatus = null;
    },
    [commonThunkAPI.checkKycVerificationStatusAsync.fulfilled]: (
      state,
      action
    ) => {
      state.kycVerificationStatus = action?.payload?.status;
      state.kycVerificationErrMsg = action?.payload?.message;
    },
    [commonThunkAPI.checkKycVerificationStatusAsync.rejected]: (
      state,
      action
    ) => {
      state.kycVerificationStatus = null;
    },
    //explanatory video links
    [commonThunkAPI.getExplanatoryVideoLink.pending]: (state, action) => {
      state.videoLinks = null;
    },
    [commonThunkAPI.getExplanatoryVideoLink.fulfilled]: (state, action) => {
      state.videoLinks = action?.payload;
    },
    [commonThunkAPI.getExplanatoryVideoLink.rejected]: (state, action) => {
      state.videoLinks = null;
    },
  },
});

export const {
  readNotification,
  setAllNotificationRead,
  clearAllStatusCommon,
  setCommonMessage,
  removeCommonMessage,
} = commonSlice.actions;

export default commonSlice.reducer;
