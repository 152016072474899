import { createAsyncThunk } from "@reduxjs/toolkit";
import commonServices from "services/common.service";
import * as sweetalert from "utils/sweetAlert";

const commonThunkAPI = {
  getparticularcharge: createAsyncThunk(
    "common/getparticularcharge",
    async (payload) => {
      try {
        const response = await commonServices.getparticularcharge(payload);
        if (response?.data?.status) {
          //sweetalert.successAlert(response?.data?.message);
          return response?.data?.data;
        } else {
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),
  getExplanatoryVideoLink: createAsyncThunk(
    "getExplanatoryVideo",
    async (payload) => {
      try {
        const response = await commonServices.getExplanatoryVideoLink(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),
  getAllNotifications: createAsyncThunk(
    "common/getAllNotifications",
    async (payload) => {
      try {
        const response = await commonServices.getAllNotifications({
          limit: payload?.limit,
        });
        if (response?.data?.status) {
          //sweetalert.successAlert(response?.data?.message);

          return response?.data?.data;
        } else {
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),
  getRigitSidebarNotifications: createAsyncThunk(
    "common/getRigitSidebarNotifications",
    async (payload) => {
      try {
        const response = await commonServices.getAllNotifications({
          limit: payload?.limit,
        });

        if (response?.data?.status) {
          //sweetalert.successAlert(response?.data?.message);

          return response?.data?.data;
        } else {
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),
  changeNotificationStatus: createAsyncThunk(
    "common/changeNotificationStatus",
    async (payload) => {
      try {
        const response = await commonServices.changeNotificationStatus(payload);
        if (response?.data?.status) {
          //sweetalert.successAlert(response?.data?.message);
          return null;
        } else {
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),

  getSingleNotification: createAsyncThunk(
    "common/getSingleNotification",
    async (payload) => {
      try {
        const response = await commonServices.getSingleNotification(payload);
        if (response?.data?.status) {
          //sweetalert.successAlert(response?.data?.message);
          return response?.data?.data;
        } else {
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),
  //get admin wallet address
  getAdminWalletAddress: createAsyncThunk(
    "manageWalletAddress",
    async (payload) => {
      try {
        const response = await commonServices.getAdminwalletaddress(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),

  //check kyc payment done or not
  checkKycPaymentAsync: createAsyncThunk(
    "checkPaymentStatus",
    async ({ payload, cb }) => {
      try {
        const response = await commonServices.checkKycPayment(payload);
        if (response?.data) {
          cb(response?.data);
          return response?.data?.status;
        } else {
          cb({ status: 0 });
          return null;
        }
      } catch (error) {
        cb({ status: 0 });

        // sweetalert.successAlert(error?.message);
        return null;
      }
    }
  ),

  //check kyc verification status
  checkKycVerificationStatusAsync: createAsyncThunk(
    "kycVerificationStatus",
    async ({ payload, cb }) => {
      try {
        const response = await commonServices.checkKycVerificationStatus(
          payload
        );
        cb(response?.data);
        if (response?.data) {
          return response?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetalert.successAlert(error?.message);
        cb({ status: 0 });

        return null;
      }
    }
  ),
};

export default commonThunkAPI;
