import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import { useRouter } from "next/router";

export default function Layout(props) {
  const router = useRouter();
  //routs to hide sidebar and header
  const routsToHideComponent = ["/landing", "/resetpassword/[rId]", "/404"];
  let isHide = routsToHideComponent.includes(router.pathname);

  return (
    <>
      {!isHide && <Header />}
      {!isHide && <LeftSidebar />}
      <div className={!isHide ? "rightSection" : ""}>{props?.children}</div>
    </>
  );
}
