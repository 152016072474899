import { createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "../../../../services/users.service";
import {
  setCurrantUser,
  getFirebaseTokenFromCookie,
} from "../../../../services/authToken.service";
import * as sweetAlert from "../../../../utils/sweetAlert";
import { setUserWalletInfo } from "store/features/users/usersSlice";

const UsersThunkAPI = {
  // loginSignupWithWallet
  loginSignupWithWallet: createAsyncThunk(
    "user/loginSignupWithWallet",
    async ({ payload, userWalletInfo, callback }, { dispatch }) => {
      try {
        let payload_temp = { ...payload };
        payload_temp.firebase_token = getFirebaseTokenFromCookie() || "";
        const response = await usersService.loginSignupWithWallet(payload_temp);
        callback(response?.data);
        if (response?.data?.status) {
          if (
            response?.data?.data?.wallet_address ==
            userWalletInfo?.walletAddress
          ) {

            response.data.data.isSignInOrSignUpWithWallete = true;
            // wallte address
            if (userWalletInfo?.isForChangeNetworkInReduxState) {
              dispatch(setUserWalletInfo(userWalletInfo));
            }
            // ---------------------------------------------
            let currant_user = {
              token: response?.data?.data?.token,
              isSignInOrSignUpWithWallete: true,
              firebase_token: getFirebaseTokenFromCookie(),
            };

            setCurrantUser(currant_user);
            callback(response?.data);
            
            return response?.data?.data;

          } else {
            sweetAlert.errorAlert(response?.data?.message);
            // return null;
          }
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return user ? user : null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        callback(error);
        return user ? user : null;
      }
    }
  ),

  //signup :
  signupAsync: createAsyncThunk(
    "user/signup",
    async ({ payload, callback }) => {
      try {
        const response = await usersService.signup(payload);
        callback(response?.data);
        if (response?.data.status) {
        } else {
          sweetAlert.errorAlert(response?.data?.message);
        }
        return null;
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        callback(error);
        return null;
      }
    }
  ),

  //login :
  loginAsync: createAsyncThunk("user/login", async ({ payload, callback }) => {
    try {
      let payload_temp = { ...payload };
      payload_temp.firebase_token = getFirebaseTokenFromCookie() || "";

      const response = await usersService.login(payload_temp);
      callback(response?.data);
      if (response?.data?.status == 1) {
        sweetAlert.successAlert(response?.data?.message);
        response.data.data.isSignInOrSignUpWithWallete = false;

        let currant_user = {
          token: response.data.data.token,
          isSignInOrSignUpWithWallete: false,
        };

        setCurrantUser(currant_user);
        return response?.data.data;
      } else {
        sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      sweetAlert.errorAlert(error?.message);
      callback(error);
      return null;
    }
  }),

  //updateProfile :
  updateProfileAsync: createAsyncThunk(
    "user/updateProfile",
    async (payload) => {
      const response = await usersService.updateProfile(payload);
      if (response?.status == 1) {
        sweetAlert.successAlert("Profile updated");
      }
      return response;
    }
  ),

  //userDetails :
  userDetailsAsync: createAsyncThunk("user/userDetails", async (payload) => {
    const response = await usersService.userDetails(payload);
    return response;
  }),

  //Update profile & banner image :
  updateProfileAndBannerImageAsync: createAsyncThunk(
    "user/updateProfileAndBannerImage",
    async (payload) => {
      try {
        let response = await usersService.updateProfileAndBannerImage(payload);
        if (response?.data.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  // updateEmail
  updateEmail: createAsyncThunk(
    "user/updateEmail",
    async ({ payload, callback }) => {
      try {
        let response = await usersService.updateEmail(payload);
        callback(response?.data);
        if (response?.data.status) {
          sweetAlert.successAlert(response?.data?.message);
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),

  // updateEmail
  validateEmail: createAsyncThunk(
    "user/validateEmail",
    async ({ payload, callback }) => {
      try {
        let response = await usersService.validateEmail(payload);
        callback(response?.data);
        if (response?.data.status) {
          sweetAlert.successAlert(response?.data?.message);
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),

  //recent launchpad/presale list
  recentLaunchpadPresaleAsync: createAsyncThunk(
    "getrecentlaunchdata",
    async (payload) => {
      try {
        const response = await usersService.recentLaunchpadPresaleList(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //recent viewed launchpad
  recentViewLaunchpadAsync: createAsyncThunk(
    "getrecentViewlist",
    async (payload) => {
      try {
        const response = await usersService.recentViewLaunchpadList(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //recent viewed launchpad
  getRecentTransactionAsync: createAsyncThunk(
    "getRecentTransaction",
    async (payload) => {
      try {
        const response = await usersService.getRecentTransaction(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //recent viewed launchpad
  getTotalInvestedValuesAsync: createAsyncThunk(
    "userTotalInvestments",
    async (payload) => {
      try {
        const response = await usersService.getTotalInvestedValues(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get invested pool list
  getTotalPoolInvestedListAsync: createAsyncThunk(
    "getMyInvestedPools",
    async (payload) => {
      try {
        const response = await usersService.getTotalPoolInvestedList(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),
};
export default UsersThunkAPI;
