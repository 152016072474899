import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import tokenThunkAPI from "./middleware";

const initialState = {
  tokenData: null,
  singleUserTokenList: null,
  singleTokenDetails: null,
  tokenPoolAddress: null,
  pools: null,
  poolDetails: null,
  getTokenExplorerPoolList: null,
  tokenHistory: null,
  tokenSinglePostData: null,
  tokenPostList: null,
  verifyTokenDetails: null,
  singleStatus: STATUSES.IDLE,
  status: STATUSES.IDLE,
  likeStatus: null,
  createStatus: "false",
  createVoteStatus: "false",
  allTokenomicsFeesUser: null,
  tokeVoteDetails: null,
  statusTokenomicsFee: STATUSES.IDLE,
  tokenpageTrasactionDetails: null,
  trasactionStatus: null,
  payementStatus: null,
  compileContractData: null,
  tokenPageFavouriteStatus: null,
  adminTokenomicsFees: null,
  historicalDataLoading: false
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    clearAllStatus: (state, action) => {
      state.tokenSinglePostData = null;
      state.createStatus = false;
      state.likeStatus = null;
      state.trasactionStatus = null;
    },
    clearSingleTokenDetails: (state, action) => {
      // state.singleTokenDetails = null;
      state.poolDetails = null;
      state.tokenPoolAddress = null;
    },
  },
  extraReducers: {
    // Instant token
    [tokenThunkAPI.createInstantToken.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.createInstantToken.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [tokenThunkAPI.createInstantToken.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //Compile contract
    [tokenThunkAPI.compileContract.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.compileContract.fulfilled]: (state, action) => {
      state.compileContractData = action.payload;
      state.status = STATUSES.IDLE;
    },
    [tokenThunkAPI.compileContract.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // Get all tokenomics fees user
    [tokenThunkAPI.getAllTokenomicsFeesUser.pending]: (state, action) => {
      state.statusTokenomicsFee = STATUSES.LOADING;
    },
    [tokenThunkAPI.getAllTokenomicsFeesUser.fulfilled]: (state, action) => {
      state.allTokenomicsFeesUser = action.payload;
      state.statusTokenomicsFee = STATUSES.IDLE;
    },
    [tokenThunkAPI.getAllTokenomicsFeesUser.rejected]: (state, action) => {
      state.statusTokenomicsFee = STATUSES.ERROR;
    },

    // Advance token
    [tokenThunkAPI.createAdvanceToken.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.createAdvanceToken.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [tokenThunkAPI.createAdvanceToken.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
    // Advance token
    [tokenThunkAPI.advanceCompileContract.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.advanceCompileContract.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [tokenThunkAPI.advanceCompileContract.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //get single user token list
    [tokenThunkAPI.getSingleUserTokenListAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getSingleUserTokenListAsync.fulfilled]: (state, action) => {
      state.singleUserTokenList = action.payload;
      state.singleStatus = STATUSES.IDLE;
    },
    [tokenThunkAPI.getSingleUserTokenListAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get pool data with pool address
    [tokenThunkAPI.getPoolDataAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getPoolDataAsync.fulfilled]: (state, action) => {
      state.poolDetails = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getPoolDataAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get pool data with pool address getTokenExplorerPoolList
    [tokenThunkAPI.getTokenExplorerPoolListAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getTokenExplorerPoolListAsync.fulfilled]: (
      state,
      action
    ) => {
      state.getTokenExplorerPoolList = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getTokenExplorerPoolListAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    //get token details data by token id
    [tokenThunkAPI.getSingleTokenDetailsAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getSingleTokenDetailsAsync.fulfilled]: (state, action) => {
      state.singleTokenDetails = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getSingleTokenDetailsAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get token related pool data by token address
    [tokenThunkAPI.getPoolAddressAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getPoolAddressAsync.fulfilled]: (state, action) => {
      state.tokenPoolAddress = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getPoolAddressAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get pool data with pool address
    [tokenThunkAPI.getPoolDataAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getPoolDataAsync.fulfilled]: (state, action) => {
      state.poolDetails = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getPoolDataAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get pools with token address
    [tokenThunkAPI.getPoolsAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getPoolsAsync.fulfilled]: (state, action) => {
      state.pools = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getPoolsAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    // get historical data
    [tokenThunkAPI.getHistoricalDataAsync.pending]: (state, action) => {
      state.historicalDataLoading = true;
    },
    [tokenThunkAPI.getHistoricalDataAsync.fulfilled]: (state, action) => {
      state.tokenHistory = action.payload;
      state.historicalDataLoading = false;
    },
    [tokenThunkAPI.getHistoricalDataAsync.rejected]: (state, action) => {
      state.historicalDataLoading = false;
    },

    //create token post
    [tokenThunkAPI.createTokenPostsAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.createTokenPostsAsync.fulfilled]: (state, action) => {
      state.status = "true";
    },
    [tokenThunkAPI.createTokenPostsAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // get pools with token address
    [tokenThunkAPI.getPoolsAsync.pending]: (state, action) => {
      state.singleStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.getPoolsAsync.fulfilled]: (state, action) => {
      state.pools = action.payload;
      state.singleStatus = action?.payload?.status;
    },
    [tokenThunkAPI.getPoolsAsync.rejected]: (state, action) => {
      state.singleStatus = STATUSES.ERROR;
    },

    //create token post
    [tokenThunkAPI.createTokenPostsCommentAsync.pending]: (state, action) => {
      state.createStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.createTokenPostsCommentAsync.fulfilled]: (state, action) => {
      state.createStatus = "true";
    },
    [tokenThunkAPI.createTokenPostsCommentAsync.rejected]: (state, action) => {
      state.createStatus = STATUSES.ERROR;
    },

    //get token post list by tokenid
    [tokenThunkAPI.getTokenPostListAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.getTokenPostListAsync.fulfilled]: (state, action) => {
      state.tokenPostList = action.payload;
      state.status = true;
    },
    [tokenThunkAPI.getTokenPostListAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //get single token post list by post id
    [tokenThunkAPI.getSingleTokenPostAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.getSingleTokenPostAsync.fulfilled]: (state, action) => {
      state.tokenSinglePostData = action.payload;
      state.status = true;
    },
    [tokenThunkAPI.getSingleTokenPostAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // post like/unlike on token post
    [tokenThunkAPI.tokenPostLikeUnlikeAsync.pending]: (state, action) => {
      state.createStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.tokenPostLikeUnlikeAsync.fulfilled]: (state, action) => {
      state.createStatus = "true";
    },
    [tokenThunkAPI.tokenPostLikeUnlikeAsync.rejected]: (state, action) => {
      state.createStatus = STATUSES.ERROR;
    },

    // post dislike token post
    [tokenThunkAPI.tokenPostDislikeAsync.pending]: (state, action) => {
      state.createStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.tokenPostDislikeAsync.fulfilled]: (state, action) => {
      state.createStatus = "true";
    },
    [tokenThunkAPI.tokenPostDislikeAsync.rejected]: (state, action) => {
      state.createStatus = STATUSES.ERROR;
    },

    // post like/unlike on token post
    [tokenThunkAPI.createTokenVoteLikeAsync.pending]: (state, action) => {
      state.createVoteStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.createTokenVoteLikeAsync.fulfilled]: (state, action) => {
      state.createVoteStatus = "true";
    },
    [tokenThunkAPI.createTokenVoteLikeAsync.rejected]: (state, action) => {
      state.createVoteStatus = STATUSES.ERROR;
    },

    // token page favourite
    [tokenThunkAPI.tokenPageAddRemoveFavouriteAsync.pending]: (
      state,
      action
    ) => {
      state.tokenPageFavouriteStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.tokenPageAddRemoveFavouriteAsync.fulfilled]: (
      state,
      action
    ) => {
      state.tokenPageFavouriteStatus = action?.payload?.status;
    },
    [tokenThunkAPI.tokenPageAddRemoveFavouriteAsync.rejected]: (
      state,
      action
    ) => {
      state.tokenPageFavouriteStatus = STATUSES.ERROR;
    },

    // post dislike on token post
    [tokenThunkAPI.createTokenVoteDislikeAsync.pending]: (state, action) => {
      state.createVoteStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.createTokenVoteDislikeAsync.fulfilled]: (state, action) => {
      state.createVoteStatus = "true";
    },
    [tokenThunkAPI.createTokenVoteDislikeAsync.rejected]: (state, action) => {
      state.createVoteStatus = STATUSES.ERROR;
    },

    // get token vote
    [tokenThunkAPI.getTokenVoteAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.getTokenVoteAsync.fulfilled]: (state, action) => {
      state.status = "true";
      state.tokeVoteDetails = action.payload;
    },
    [tokenThunkAPI.getTokenVoteAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // verifu token address
    [tokenThunkAPI.tokenInfoAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [tokenThunkAPI.tokenInfoAsync.fulfilled]: (state, action) => {
      state.status = "true";
      state.verifyTokenDetails = action.payload;
    },
    [tokenThunkAPI.tokenInfoAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //create token page payment
    [tokenThunkAPI.createWalletPaymentAsync.pending]: (state, action) => {
      state.trasactionStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.createWalletPaymentAsync.fulfilled]: (state, action) => {
      state.trasactionStatus = action.payload?.status;
      state.tokenpageTrasactionDetails = action.payload;
    },
    [tokenThunkAPI.createWalletPaymentAsync.rejected]: (state, action) => {
      state.trasactionStatus = STATUSES.ERROR;
    },

    //post payment intent id
    [tokenThunkAPI.postTokenPagePaymentIntentIdAsync.pending]: (
      state,
      action
    ) => {
      state.payementStatus = STATUSES.LOADING;
    },
    [tokenThunkAPI.postTokenPagePaymentIntentIdAsync.fulfilled]: (
      state,
      action
    ) => {
      state.payementStatus = action.payload?.status;
    },
    [tokenThunkAPI.postTokenPagePaymentIntentIdAsync.rejected]: (
      state,
      action
    ) => {
      state.payementStatus = STATUSES.ERROR;
    },

    //get Admin Tokenomics Fees
    [tokenThunkAPI.getAdminTokenomicsFees.pending]: (state, action) => {
      state.adminTokenomicsFeesStatus = true;
    },
    [tokenThunkAPI.getAdminTokenomicsFees.fulfilled]: (state, action) => {
      state.adminTokenomicsFees = action.payload;
      state.adminTokenomicsFeesStatus = false;
    },
    [tokenThunkAPI.getAdminTokenomicsFees.rejected]: (state, action) => {
      state.adminTokenomicsFeesStatus = false;
    },
  },
});
export const { clearAllStatus, clearSingleTokenDetails } = tokenSlice.actions;
export default tokenSlice.reducer;
