import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/future/image";
import { useRouter } from "next/router";

// images
import logoMain from "../../static/images/logo.svg";
import logoMainBlue from "../../static/images/logoBlue.svg";
import proImg from "../../static/images/round-img.png";

import ethCoinImg from "../../static/images/eth.svg";

import notilaunchpad from "../../static/images/noti-launchpad.svg";

//componants
import Dropdown from "react-bootstrap/Dropdown";
import Auth from "../auth";
import Loader from "componants/common/Loader";
import NotificationListRigitSidebar from "componants/common/NotificationListRigitSidebar";

// blockchain
import { addDotsMiddleOfString } from "../../utils/helpers";
import { ethers } from "ethers";
import {
  isConnected,
  checkIsConnected,
  signMessage,
  getNetwork,
} from "../auth/wallet/walletConnectAuth";
import { getCurrentNetwork } from "componants/auth/wallet/walletNetworks";

// store
import {
  openAuthModal,
  closeAuthModal,
  removeWalletInfo,
} from "store/features/users/usersSlice";

import {
  setCommonMessage,
  removeCommonMessage,
} from "store/features/common/commonSlice";

// redux
import { useSelector, useDispatch } from "react-redux";
import UsersThunkAPI from "store/features/users/middleware";
import commonThunkAPI from "store/features/common/middleware/index";

import {
  logout,
  setFirebaseToken,
} from "../../store/features/users/usersSlice";
import { STATUSES } from "../../utils/statuses";

// componants
import WalleteConnectButton from "componants/auth/CustomWallet/WalleteConnectButton";
import NetworkChainSelectorPopup from "componants/auth/CustomWallet/NetworkChainSelectorPopup";
import WalletConnectorPopup from "componants/auth/CustomWallet/WalletConnectorPopup";

// utils
import { errorAlert, successAlert } from "../../utils/sweetAlert";
import {
  isEmpty,
  getNetworkChainName,
  getNetworkChainStaticObj,
  getNetworkChainImageSrc,
} from "utils/helpers";

//  firebase
import "firebase/messaging";
import "firebase/database";
import firebase from "firebase/app";
import { firebaseCloudMessaging } from "utils/firebase";

import {
  setUserWalletInfo,
  removeWalletAddress,
} from "store/features/users/usersSlice";

//auth token
import {
  removeCurrantUser,
  setUserWalletInfoInStorage,
  getUserWalletInfo,
  removeUserWalletInfo,
  setFirebaseTokenInCookie,
  getAuthToken,
} from "services/authToken.service";
import Web3 from "web3";

export default function Header() {
  const router = useRouter();
  const [mobileFlag, setMobileFlag] = useState(false);

  // stats
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //redux
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.users);
  const user = userState?.user || null;
  const { sidebarNotification } = useSelector((state) => state.commonState);

  const [flag, setFlag] = useState(false);

  const [walleteChangeFlag, setWalleteChangeFlag] = useState(false);
  const [changeWalleteLoading, setChangeWalleteLoading] = useState(false);
  const [tempData, setTempData] = useState("");

  //blockchain
  const menuOpen = () => {
    document.body.classList.remove("menuAction");
  };

  const menuClose = () => {
    document.body.classList.add("menuAction");
  };

  const notiOpen = () => {
    if (
      sidebarNotification?.list &&
      sidebarNotification?.list.length > 0 &&
      router.pathname !== "/notifications"
    )
      document.body.classList.add("notiAction");
  };

  const notiClose = () => {
    document.body.classList.remove("notiAction");
  };

  const clearCurrantUserData = () => {
    dispatch(logout());
    setIsLoggedIn(false);
  };

  const onLogout = () => {
    clearCurrantUserData();
    router.push("/");
  };

  const removeCurrantUser = () => {
    return new Promise((resolve, reject) => {
      clearCurrantUserData();
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const onloginSignupWithWalletAfterChangeWallete = async (
    userWalletInfo,
    d
  ) => {
    onHideConnetWalleteOptions();
    let payload = {
      wallet_address: userWalletInfo?.walletAddress,
      wallet_signup: true,
      signHash: userWalletInfo?.signHash,
    };

    dispatch(
      UsersThunkAPI.loginSignupWithWallet({
        payload,
        user,
        callback: (res) => {
          // setWalletchangeFlag(false)
          dispatch(setUserWalletInfo(d));

          removeWalletchangeFlag();
          onHideConnetWalleteOptions();
          setChangeWalleteLoading(false);
          //if user from change walltet from metamask
          // if (changeWalleteLoading){
          // window.location.reload();
          // }
          if (res.status == 1) {
            window.location.reload();
          } else {
            errorAlert(res?.message);
            setChangeWalleteLoading(false);
          }
        },
      })
    );
  };

  const removeWalletchangeFlag = (val) => {
    localStorage.removeItem("wall_change_flag");
  };

  const setWalletchangeFlag = (val) => {
    localStorage.setItem("wall_change_flag", val);
  };

  const getWalletchangeFlag = () => {
    return localStorage.getItem("wall_change_flag");
  };

  const onChangeWalletOld = async (accounts) => {
    if (getWalletchangeFlag()) {
      return;
    }
    setWalletchangeFlag(true);

    if (accounts.length < 1) {
      onLogout();
      return;
    }
    if (walleteChangeFlag) {
      setWalleteChangeFlag(false);
      return;
    }
    setWalleteChangeFlag(true);
    setChangeWalleteLoading(true);

    removeCurrantUser().then(async () => {
      if (accounts[0]) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        let d = {
          network: network,
          walletAddress: accounts[0],
          // signHash: signHash,
          signHash: "",
          walletType: "metamask",
          // verifyContractList: verifyContractList,
          verifyContractList: "",
        };

        onloginSignupWithWalletAfterChangeWallete(
          {
            walletAddress: d?.walletAddress,
            signHash: d?.signHash,
          },
          d
        );
      } else {
        setChangeWalleteLoading(false);
      }
    });
  };

  const onLogoutOnWalletChange = () => {
    // userState?.userWalletInfo?.walletAddress
    if (userState?.userWalletInfo?.walletAddress) {
      clearCurrantUserData();
      let msg =
        "Wallet account changed. Please log in again with your new account.";
      dispatch(setCommonMessage(msg));
      // removeCommonMessage();
      router.push("/?forLoginIn=true");
      // window.location.reload();
    }
  };

  const onChangeWallte = async () => {
    // Listen for changes in the connected wallet
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", async (accounts) => {
        onLogoutOnWalletChange();
        // onChangeWalletOld(accounts)
      });
    }
  };

  //Wallet
  const [showNetworkChainOptionas, setShowNetworkChainOptionas] =
    useState(false);

  const onShowNetworkChainOptionas = () => {
    setShowNetworkChainOptionas(true);
  };
  const onHideNetworkChainOptionas = () => {
    setShowNetworkChainOptionas(false);
  };
  //Wallet
  const [showConnetWalleteOptionas, setShowConnetWalleteOptions] =
    useState(false);

  function checkIsMobileView() {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      return width < 768; // adjust the breakpoint as needed
    }
  }

  useEffect(() => {
    // console.log("onLogoutOnWalletChange123",getUserWalletInfo())

    window.addEventListener("resize", checkIsMobileView); // update on resize
    return () => {
      window.removeEventListener("resize", checkIsMobileView);
    };
  }, []);

  const onShowConnetWalleteOptions = () => {
    // check site is in desktop view OR mobile view
    setShowConnetWalleteOptions(true);
  };
  const onHideConnetWalleteOptions = () => {
    setShowConnetWalleteOptions(false);
  };

  // get current wallet network

  const [network, setNetwork] = useState("");
  useEffect(() => {
    getnetwork();
  }, [network]);

  const getnetwork = async () => {
    if (typeof window !== "undefined" && window.ethereum) {
      if (userState?.userWalletInfo?.walletAddress) {
        window.ethereum.on("chainChanged", (chainId) => {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          provider.getNetwork().then((network__) => {
            // // setNetwork(network);
            let tempUserWalletInfo = {
              ...userState?.userWalletInfo,
              network: network__,
            };

            dispatch(setUserWalletInfo(tempUserWalletInfo));
          });
        });
      }
    }

    if (!userState?.userWalletInfo?.walletAddress) {
      let tempUserWalletInfo = {
        ...(userState?.userWalletInfo ? userState?.userWalletInfo : {}),
        network: getNetworkChainStaticObj(56),
      };

      dispatch(setUserWalletInfo(tempUserWalletInfo));
    }
  };
  useEffect(() => {
    let token = getAuthToken();
    if (token) setIsLoggedIn(true);
    else setIsLoggedIn(false);
  }, [user]);

  useEffect(() => {
    let token = getAuthToken();
    if (token) {
      let payload = { userid: user?._id };
      dispatch(UsersThunkAPI.userDetailsAsync(payload));
    }
    setIsLocalLoading(false);
    // onChangeWallte();

    return () => {
      removeWalletchangeFlag();
    };
  }, []);

  useEffect(() => {
    onChangeWallte();
  }, []);

  const onIsConnected = async () => {
    const isWalletConnected = await checkIsConnected();
    const walletAddress = await isConnected();

    if (!isWalletConnected) {
      // dispatch(removeWalletInfo());
      dispatch(removeWalletAddress());
    }

    if (walletAddress) {
      const network = await ethers.getDefaultProvider().getNetwork();
    }
  };

  //  login with wallete address
  const onloginSignupWithWallet = async (userWalletInfo) => {
    onHideConnetWalleteOptions();
    let payload = {
      wallet_address: userWalletInfo?.walletAddress,
      wallet_signup: true,
      signHash: userWalletInfo?.signHash,
    };

    dispatch(
      UsersThunkAPI.loginSignupWithWallet({
        payload,
        userWalletInfo,
        user,
        callback: (res) => {
          onHideConnetWalleteOptions();
          if (res.status == 1) {
            // dispatch(removeCommonMessage());
            // successAlert(res?.message);
            // router.push("/user/profile");
          } else {
            // errorAlert(res?.message);
            return;
          }
        },
      })
    );
  };

  // notification
  const initFirebaseSettings = async () => {
    try {
      let firebase_token = await firebaseCloudMessaging.init();
      dispatch(setFirebaseToken(firebase_token));
    } catch (error) {}
  };

  const getFirebaseNotifications = async () => {
    try {
      if (isLoggedIn) {
        firebase
          .database()
          .ref("padefi-update")
          .child(user?._id)
          .on("value", function (snapshot) {
            dispatch(
              commonThunkAPI?.getRigitSidebarNotifications({
                limit: 9,
              })
            );
          });
      }
    } catch (error) {}
    // onIsConnected();
  };

  useEffect(() => {
    initFirebaseSettings();

    onIsConnected();
    setFlag(true);
  }, []);

  useEffect(() => {
    getFirebaseNotifications();
  }, [user, isLoggedIn]);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      const handleNetworkChange = async (chainId) => {
        const newProvider = new ethers.providers.Web3Provider(window.ethereum);
        const newNetwork = await newProvider.getNetwork();
        const newSigner = newProvider.getSigner();
        const newAddress = await newSigner.getAddress();
        let netNetwork = { network: newNetwork, walletAddress: newAddress };
        dispatch(setUserWalletInfo(netNetwork));

        switch (chainId) {
          case "0x1":
            getNetworkChainImageSrc({ chainId: 1 });
            break;
          case "0x61":
            getNetworkChainImageSrc({ chainId: 97 });
            break;
          case "0x38":
            getNetworkChainImageSrc({ chainId: 56 });
            break;
          case "0x5":
            getNetworkChainImageSrc({ chainId: 5 });
            break;

          default:
            break;
        }
      };

      ethereum.on("chainChanged", handleNetworkChange);

      return () => {
        ethereum.removeListener("chainChanged", handleNetworkChange);
      };
    }
  }, [tempData]);

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        const width = window.innerWidth;
        if (width < 768) {
          if (router.query.isFirst) {
            mobileLogin();
          }

          localStorage.setItem("mobileFlag", true);
          setMobileFlag(true);
        }
      }
    } catch (error) {}
  }, []);

  async function mobileLogin() {
    if (localStorage.getItem("mobileFlag")) {
      // if(mobileFlag){
      return;
    }
    if (userState?.userWalletInfo?.walletAddress) {
      return;
    }
    let web3;
    try {
      if (
        typeof window !== "undefined" &&
        typeof window.ethereum !== "undefined"
      ) {
        // Use MetaMask's provider
        web3 = new Web3(window.ethereum);
        window.ethereum.enable(); // Request user's permission to connect

        await window.ethereum.request({ method: "eth_requestAccounts" });
        let signHash = null;
        signHash = await signMessage();
        setTempData({
          signHash,
        });
        const walletAddress = await isConnected();
        const network = await getNetwork();
        let verifyContractList = "";
        // if (props?.isForMultiNetworkCheck) {
        //   // verifyContractList = await MultiTokenVerify(
        //   //   props.contractAddress,
        //   //   props.networksListToCheck,
        //   //   walletAddress
        //   // );

        // }
        if (signHash) {
          const userWalletInfo = {
            network: network,
            walletAddress: walletAddress,
            signHash: signHash,
            walletType: "metamask",
            isForChangeNetworkInReduxState: true,
          };
          let payload = {
            wallet_address: walletAddress,
            wallet_signup: true,
            signHash: signHash,
          };

          dispatch(
            UsersThunkAPI.loginSignupWithWallet({
              payload,
              userWalletInfo,
              callback: (res) => {
                if (res.status == 1) {
                  // successAlert(res);
                  // router.push("/user/profile");
                }
              },
            })
          );
        }
      }
    } catch (error) {
      console.log("error is: ", error);
    }
  }
  return (
    <>
      <div>
        <div className="mainHeader">
          {isLocalLoading && <Loader />}

          {changeWalleteLoading && (
            <>
              {" "}
              <Loader />
              <Loader />
              <Loader />
              <Loader />
            </>
          )}

          <div className="logoSection d-flex align-items-center">
            <div
              className="menuIconOpen"
              style={{ fontSize: "23PX" }}
              onClick={menuOpen}
            >
              <i className="fal fa-times"></i>
            </div>
            <div className="menuIconClose" onClick={menuClose}>
              <i className="far fa-bars"></i>
            </div>
            <Link href="/">
              <a>
                <Image className="logo" alt="" src={logoMain} />
                <Image className="logoBlue" alt="" src={logoMainBlue} />
              </a>
            </Link>
          </div>

          <div className="rightMenuBox d-flex align-items-center">
            {/* add class wallet connect or not */}
            <div
              className={
                userState?.userWalletInfo?.walletAddress && flag
                  ? "walletConnecAndIconBx walletConnected"
                  : "walletConnecAndIconBx"
              }
            >
              {userState?.userWalletInfo?.network && flag && (
                <WalleteConnectButton
                  onClick={onShowNetworkChainOptionas}
                  className="binanceBtn fontBold d-flex align-items-center justify-content-center"
                  classNameAfterConnect={
                    "headerWalletConnetBtn mainNetBtnHead fontBold respoIconShow"
                  }
                  isConnected={userState?.userWalletInfo?.network?.chainId}
                >
                  {/* className="walletIconShowMobile" */}

                  {userState?.userWalletInfo?.network ? (
                    <Image
                      alt=""
                      height="35"
                      width="35"
                      src={getNetworkChainImageSrc(
                        userState?.userWalletInfo?.network
                      )}
                    />
                  ) : (
                    ""
                  )}
                </WalleteConnectButton>
              )}
              {/* isLoggedIn &&  */}
              {userState?.userWalletInfo?.walletAddress && flag ? (
                <WalleteConnectButton
                  className={
                    "d-flex align-items-center justify-content-center headerWalletConnetBtn"
                  }
                  classNameAfterConnect={""}
                  onClick={onShowConnetWalleteOptions}
                  type="button"
                >
                  {`${
                    userState?.userWalletInfo?.network?.chainId == 1 ||
                    userState?.userWalletInfo?.network?.chainId == 56
                      ? ""
                      : "Testnet: "
                  }${addDotsMiddleOfString(
                    userState?.userWalletInfo?.walletAddress
                  )}`}
                </WalleteConnectButton>
              ) : (
                <WalleteConnectButton
                  className={
                    "fontBold d-flex align-items-center justify-content-center headerWalletConnetBtn"
                  }
                  classNameAfterConnect={""}
                  onClick={onShowConnetWalleteOptions}
                  type="button"
                >
                  Connect
                </WalleteConnectButton>
              )}
            </div>

            {/* {isLoggedIn && (
              <div className='notificationBox'>
                <div onClick={notiOpen}>
                  <span className='bellIcon'>
                    <i className='far fa-bell'></i>
                  </span>
                  {Boolean(sidebarNotification?.unread_count) && (
                    <span className='notiCount'>
                      {sidebarNotification && sidebarNotification?.unread_count}
                    </span>
                  )}
                </div>

                <NotificationListRigitSidebar
                  notificationList={sidebarNotification?.list}
                  allNotificationCount={sidebarNotification?.all_count}
                  notiClose={notiClose}
                />
              </div>
            )}  */}

            {isLoggedIn && (
              <div className="profilemenu">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {(user?.user_profile_image?.url && (
                      <div className="proPicmainImg">
                        <Image
                          src={user?.user_profile_image?.url || proImg}
                          width={50}
                          height={50}
                          alt="profile-image"
                        />
                      </div>
                    )) || (
                      <span className="proPic">
                        {(isEmpty(user?.name) &&
                          user?.name.charAt(0).toUpperCase()) ||
                          (isEmpty(user?.email) &&
                            user?.email.charAt(0).toUpperCase()) ||
                          "M"}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link href={"/user/profile"}>My profile</Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      {" "}
                      <a href="#">My wallet</a>{" "}
                    </Dropdown.Item> */}
                    <Dropdown.Item>
                      <Link href={"/my-token-manager"}>My token manager</Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="logOutmenu" onClick={onLogout}>
                      <a>
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H8C8.55 18 9 17.55 9 17C9 16.45 8.55 16 8 16H2V2Z"
                              fill="#8B93A3"
                            />
                            <path
                              d="M17.65 8.64954L14.86 5.85954C14.54 5.53954 14 5.75954 14 6.20954V7.99954H7C6.45 7.99954 6 8.44954 6 8.99954C6 9.54954 6.45 9.99954 7 9.99954H14V11.7895C14 12.2395 14.54 12.4595 14.85 12.1395L17.64 9.34954C17.84 9.15954 17.84 8.83954 17.65 8.64954Z"
                              fill="#8B93A3"
                            />
                          </svg>
                        </span>
                        Log Out
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {/* signup button */}
            {!isLoggedIn && <Auth />}

            {showNetworkChainOptionas && (
              <NetworkChainSelectorPopup
                onConnect={(data) => {
                  // onloginSignupWithWallet(data);
                  onHideNetworkChainOptionas();
                }}
                isPopup={true}
                showNetworkChainOptionas={showNetworkChainOptionas}
                onHideNetworkChainOptionas={onHideNetworkChainOptionas}
                onBack={onHideNetworkChainOptionas}
                isForChangeNetworkInReduxState={true}
              />
            )}

            {showConnetWalleteOptionas && (
              <WalletConnectorPopup
                onConnect={(data) => {
                  onloginSignupWithWallet(data);
                }}
                isPopup={true}
                showConnetWalleteOptionas={showConnetWalleteOptionas}
                onHideConnetWalleteOptions={onHideConnetWalleteOptions}
                onBack={onHideConnetWalleteOptions}
                isForChangeNetworkInReduxState={true}
              />
            )}
          </div>
        </div>
        <div className="heightDev"></div>
      </div>
    </>
  );
}
