import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import UsersThunkAPI from "./middleware";
import * as sweetAlert from "../../../utils/sweetAlert";
import {
  isEmpty,
  getNetworkChainName,
  getNetworkChainStaticObj,
} from "utils/helpers";

//auth token
import {
  removeCurrantUser,
  setUserWalletInfoInStorage,
  getUserWalletInfo,
  removeUserWalletInfo,
  setFirebaseTokenInCookie,
} from "../../../services/authToken.service";

const initialState = {
  user: null,
  userWalletInfo: getUserWalletInfo() ? getUserWalletInfo() : null,
  // userWalletInfo: null,
  status: STATUSES.IDLE,
  forgotPwPopup: false,
  isOpenAuthModal: false,
  recentLaunchpadPresaleList: null,
  recentViewdLaunchpads: null,
  loadingRecentTransaction: false,
  totalInvestedValuesStatus: false,
  totalInvestedPoolListStatus: false,
  totalInvestedPoolList: null,
  totalInvestedValuesData: null,
  recentTransactionList: null,
  firbaseToken: null,
};
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    openAuthModal: (state) => {
      state.isOpenAuthModal = true;
    },
    closeAuthModal: (state) => {
      state.isOpenAuthModal = false;
    },
    openForgotPwPopup: (state) => {
      state.forgotPwPopup = true;
    },
    closeForgotPwPopup: (state) => {
      state.forgotPwPopup = false;
    },
    logout: (state) => {
      state.user = null;
      state.userWalletInfo = {
        network: getNetworkChainStaticObj(56),
      };
      removeCurrantUser();
      removeUserWalletInfo();
      localStorage.removeItem("wall_change_flag");

    },
    updateUserDetailsInStore: (state, action) => {
      state.user = action?.payload;
    },
    setUserWalletInfo: (state, action) => {
      setUserWalletInfoInStorage(action.payload);
      state.userWalletInfo = action.payload;
    },
    removeWalletInfo: (state, action) => {
      state.userWalletInfo = null;
      removeUserWalletInfo();
    },
    removeWalletAddress: (state, action) => {
      if (state?.userWalletInfo?.walletAddress) {
        state.userWalletInfo.walletAddress = "";
      }
     
      setUserWalletInfoInStorage({ ...state.userWalletInfo });
    },
    setFirebaseToken: (state, action) => {
      //device token
      state.firbaseToken = action?.payload;
      setFirebaseTokenInCookie(action?.payload);
    },
    removeFavouriteRecentViewLaunchpad: (state, action) => {
      let temp = state.recentViewdLaunchpads.map((item) => {
        if (item?._id == action?.payload) item.isfavourite = !item.isfavourite;
        return item;
      });
      state.recentViewdLaunchpads = temp;
    },
  },
  extraReducers: {
    
    [UsersThunkAPI.loginSignupWithWallet.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.loginSignupWithWallet.fulfilled]: (state, action) => {
      state.user = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.loginSignupWithWallet.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

   
    [UsersThunkAPI.loginAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.loginAsync.fulfilled]: (state, action) => {
      state.user = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.loginAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
   
    [UsersThunkAPI.signupAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.signupAsync.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.signupAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
   
    [UsersThunkAPI.updateProfileAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.updateProfileAsync.fulfilled]: (state, action) => {
      state.user = action?.payload?.data || {};
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.updateProfileAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
    
    [UsersThunkAPI.userDetailsAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.userDetailsAsync.fulfilled]: (state, action) => {
      state.user = action?.payload?.data || {};
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.userDetailsAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
    
    [UsersThunkAPI.updateProfileAndBannerImageAsync.pending]: (
      state,
      action
    ) => {
    },
    [UsersThunkAPI.updateProfileAndBannerImageAsync.fulfilled]: (
      state,
      action
    ) => {
      state.user.user_banner_image = action?.payload?.user_banner_image;
      state.user.user_profile_image = action?.payload?.user_profile_image;
    },
    [UsersThunkAPI.updateProfileAndBannerImageAsync.rejected]: (
      state,
      action
    ) => {
    },

    // updateEmail
    [UsersThunkAPI.updateEmail.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.updateEmail.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.updateEmail.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // validateEmail
    [UsersThunkAPI.validateEmail.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.validateEmail.fulfilled]: (state, action) => {
      state.user = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [UsersThunkAPI.validateEmail.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //recent launchpad
    [UsersThunkAPI.recentLaunchpadPresaleAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.recentLaunchpadPresaleAsync.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
      state.recentLaunchpadPresaleList = action?.payload;
    },
    [UsersThunkAPI.recentLaunchpadPresaleAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //recent viewd launchpads
    [UsersThunkAPI.recentViewLaunchpadAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [UsersThunkAPI.recentViewLaunchpadAsync.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
      state.recentViewdLaunchpads = action?.payload;
    },
    [UsersThunkAPI.recentViewLaunchpadAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    //recent viewd launchpads
    [UsersThunkAPI.getRecentTransactionAsync.pending]: (state, action) => {
      state.loadingRecentTransaction = true;
    },
    [UsersThunkAPI.getRecentTransactionAsync.fulfilled]: (state, action) => {
      state.loadingRecentTransaction = true;
      state.recentTransactionList = action?.payload;
    },
    [UsersThunkAPI.getRecentTransactionAsync.rejected]: (state, action) => {
      state.loadingRecentTransaction = true;
    },

    //get total invested values
    [UsersThunkAPI.getTotalInvestedValuesAsync.pending]: (state, action) => {
      state.totalInvestedValuesStatus = false;
    },
    [UsersThunkAPI.getTotalInvestedValuesAsync.fulfilled]: (state, action) => {
      state.totalInvestedValuesStatus = true;
      state.totalInvestedValuesData = action?.payload;
    },
    [UsersThunkAPI.getTotalInvestedValuesAsync.rejected]: (state, action) => {
      state.totalInvestedValuesStatus = true;
    },

    // get invested pool list
    [UsersThunkAPI.getTotalPoolInvestedListAsync.pending]: (state, action) => {
      state.totalInvestedPoolListStatus = false;
    },
    [UsersThunkAPI.getTotalPoolInvestedListAsync.fulfilled]: (
      state,
      action
    ) => {
      state.totalInvestedPoolListStatus = true;
      state.totalInvestedPoolList = action?.payload;
    },
    [UsersThunkAPI.getTotalPoolInvestedListAsync.rejected]: (state, action) => {
      state.totalInvestedPoolListStatus = true;
    },
  },
});

export const {
  openForgotPwPopup,
  closeForgotPwPopup,
  logout,
  changeVerifyUserStatus,
  openAuthModal,
  closeAuthModal,
  setUserWalletInfo,
  setFirebaseToken,
  removeWalletInfo,
  removeFavouriteRecentViewLaunchpad,
  removeWalletAddress,
} = usersSlice.actions;

export default usersSlice.reducer;

