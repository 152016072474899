import axiosInstance from "./axios";
import { getAuthToken } from "../services/authToken.service";
let response = "";
const usersService = {
  loginSignupWithWallet: async function (payload) {
    return axiosInstance.post("loginSignupWithWallet", payload);
  },

  signup: async function (payload) {
    payload.purpose = "SIGNUP";

    return axiosInstance.post("signup", payload);
  },
  login: async function (payload) {
    return axiosInstance.post("login", payload);
  },
  validateEmail: async function (payload) {
    return axiosInstance.post("validatemail", payload);
  },
  resendOTP: async function (payload) {
    try {
      response = await axiosInstance.post("resendOtp", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  forgetPassword: async function (payload) {
    try {
      response = await axiosInstance.post("forgetPassword", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  resetPassword: async function (payload) {
    try {
      response = await axiosInstance.post("resetPassword", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  //Edit profile page services-
  updateProfile: async function (payload) {
    try {
      response = await axiosInstance.post("updateProfileDetails", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },

  userDetails: async function (payload) {
    try {
      response = await axiosInstance.post("userDetails", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  updateUsername: async function (payload) {
    try {
      response = await axiosInstance.post("updateUsername", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  changePassword: async function (payload) {
    try {
      response = await axiosInstance.post("changePassword", payload);
      return response.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  //update profile and banner image-
  updateProfileAndBannerImage: async function (payload) {
    let headers = {
      "x-access-token": getAuthToken(),
      "Content-Type": "multipart/form-data",
    };
    return axiosInstance.post("updateProfileAndBannerImage", payload, {
      headers: headers,
    });
  },

  updateEmail: async function (payload) {
    return axiosInstance.post("updateEmail", payload);
  },

  //recent launchpad
  recentLaunchpadPresaleList: async function (payload) {
    return axiosInstance.post("getrecentlaunchdata", payload);
  },

  //recent presale
  recentViewLaunchpadList: async function (payload) {
    return axiosInstance.post("getrecentViewlist", payload);
  },

  //getRecentTransaction
  getRecentTransaction: async function (payload) {
    return axiosInstance.post("getRecentTransaction", payload);
  },

  //get total pool invested values
  getTotalInvestedValues: async function (payload) {
    return axiosInstance.post("userTotalInvestments", payload);
  },

  //get invested pool invested list
  getTotalPoolInvestedList: async function (payload) {
    return axiosInstance.post("getMyInvestedPools", payload);
  },
};
export default usersService;
