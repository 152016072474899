import React, { useState, useEffect, useRef } from "react";
import Loader from "../../common/Loader";
import validator from "validator";
import * as sweetAlert from "../../../utils/sweetAlert";
import usersService from "../../../services/users.service";

export default function Forget(props) {
  const { setForgetModal } = props;
  const [userDetails, setUserDetails] = useState({ email: "" });
  const [validateError, setValidateError] = useState({ email: "" });

  const onHandleUserInfo = async (event) => {
    let { value } = event.target;
    let tempValidateError = { ...validateError };
    value = await value.trim();
    if (value.length < 3) tempValidateError.email = "Please enter email";
    else if (!validator.isEmail(value))
      tempValidateError.email = "Please enter valid email";
    else tempValidateError.email = "";
    setValidateError({
      ...validateError,
      ...tempValidateError,
    });
    setUserDetails({
      ...userDetails,
      email: value,
    });
  };
  const forgetPassCheck = Boolean(userDetails?.email);
  const forgetPassError = Boolean(validateError.email);

  const forgetPassword = async (event) => {
    event.preventDefault();
    try {
      if (forgetPassCheck && !forgetPassError) {
        let payload = {
          mail: userDetails.email.toLowerCase(),
        };
        const response = await usersService.forgetPassword(payload);
        if (response.status) {
          sweetAlert.successAlert(response?.message);
          setForgetModal(false);
        } else sweetAlert.errorAlert(response?.message);
      } else sweetAlert.errorAlert("Please check clear all error");
    } catch (error) {}
  };
  return (
    <div>
      <form onSubmit={(e) => forgetPassword(e)}>
        <div className='forgetPassword'>
          <div className='form-group'>
            <h5>
              Enter your details below to request your account password reset.
            </h5>
            <label>Email</label>
            <input
              type='text'
              className='form-control'
              placeholder='Enter Email '
              value={userDetails?.email}
              onChange={onHandleUserInfo}
            />
            <div>
              <h6 style={{ color: "#d34747" }}>{validateError.email}</h6>
            </div>
          </div>

          <button
            className={
              ((!userDetails?.email || forgetPassError) &&
                "createAccountBtn2 cursor-no-drop mb-4") ||
              " createAccountBtn mb-4"
            }
            type='submit'
            disabled={!userDetails?.email || forgetPassError}
            onClick={forgetPassword}
          >
            Send
          </button>

          <button
            className='forgerPassBtn'
            type='button'
            style={{
              // textDecorationLine: "underline",
              border: "none",
              color: "blue",
            }}
            onClick={props.goSignIn}
          >
            Remember my account password ? Sign in
          </button>
        </div>
      </form>
    </div>
  );
}
