import axiosInstance from "./axios";
let response = "";

const tokenService = {
  //Instance token
  compileContract: async function (payload) {
    try {
      response = await axiosInstance.post("compileContract", payload);
      return response?.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  getAllTokenomicsFeesUser: async function () {
    return axiosInstance.get("getAllTokenomicsFeesUser");
  },
  createInstantToken: async function (payload) {
    try {
      response = await axiosInstance.post("createInstantToken", payload);
      return response?.data;
    } catch (error) {
      const { name, message } = error;
      response = `${name} => ${message}`;
      return response;
    }
  },
  // Advance token
  createAdvanceToken: async function (payload) {
    return axiosInstance.post("createAdvanceToken", payload);
  },
  advanceCompileContract: async function (payload) {
    return axiosInstance.post("advanceCompileContract", payload);
  },

  //get  token list in profile page-
  getSingleUserTokenList: async function (payload) {
    return axiosInstance.post("getAllUserToken", payload);
  },

  //get token details by token id
  getSingleTokenDetails: async function (payload) {
    return axiosInstance.post("getSingleTokenPage", payload);
  },
  // get pool address with token address and chain id
  getPoolAddress: async function (payload) {
    return axiosInstance.post("getPoolAddress", payload);
  },
  // get pool data with pool address

  // get pool address with token address and chain id
  getPoolAddress: async function (payload) {
    return axiosInstance.post("getPoolAddress", payload);
  },

  // get pool data with pool address

  getPoolData: async function (payload) {
    return axiosInstance.post("getPoolData", payload);
  },

  getPools: async function (payload) {
    return axiosInstance.post("getPools", payload);
  },

  getTokenExplorerPoolList: async function (payload) {
    return axiosInstance.post("getTokenExplorerPoolList", payload);
  },

  getHistoricalData: async function (payload) {
    return axiosInstance.post("getHistoricalData", payload);
  },

  //create token posts
  createTokenPost: async function (payload) {
    return axiosInstance.post("/createPost", payload);
  },
  getPools: async function (payload) {
    return axiosInstance.post("getPools", payload);
  },

  //create token posts comment
  createTokenPostsComment: async function (payload) {
    return axiosInstance.post("/commentTopost", payload);
  },

  //get token post list
  getTokenPostData: async function (payload) {
    return axiosInstance.post("/postList", payload);
  },

  //get single post by post id
  getSingleTokenPost: async function (payload) {
    return axiosInstance.post("/singlepostbypostid", payload);
  },

  //like/unlike on token post
  postLikeUnlike: async function (payload) {
    return axiosInstance.post("/addremovelike", payload);
  },

  //dislike token post
  postDislike: async function (payload) {
    return axiosInstance.post("/dislikeaddremove", payload);
  },

  //like/unlike on token vote
  tokenLikeUnlikeVote: async function (payload) {
    return axiosInstance.post("/tokenAddremovelike", payload);
  },

  //get token vote
  getTokenLikeDislikeVote: async function (payload) {
    return axiosInstance.post("/getTokenVoteanalysis", payload);
  },

  //token vote dislike
  tokenDislikeVote: async function (payload) {
    return axiosInstance.post("/tokenAddremovedislike", payload);
  },

  //verify token address
  tokenInfo: async function (payload) {
    return axiosInstance.post("/tokenInfo", payload);
  },

  //token vote like/unlike
  tokenVoteLikeUnlike: async function (payload) {
    return axiosInstance.post("/tokenAddremovelike", payload);
  },

  //token page add/remove favourite
  tokenPageFavourite: async function (payload) {
    return axiosInstance.post("/addFavourite", payload);
  },

  //create token page payment
  tokenPagePayment: async function (payload) {
    return axiosInstance.post("/createTransction", payload);
  },

  //post payment intent id
  tokenPagePaymentInentId: async function (payload) {
    return axiosInstance.post("/stripePayStatusCheck", payload);
  },

  //get Admin Tokenomics Fees
  getAdminTokenomicsFees: async function (payload) {
    return axiosInstance.post("/getAdminTokenomicsFees", payload);
  },
};

export default tokenService;
