import { createAsyncThunk } from "@reduxjs/toolkit";
import tokenService from "../../../../services/token.service.js";
import secure from "../../../../utils/secure";
import * as sweetAlert from "../../../../utils/sweetAlert";

const tokenThunkAPI = {
  //Instance token
  createInstantToken: createAsyncThunk(
    "createInstantToken",
    async (payload) => {
      const response = await tokenService.createInstantToken(payload);
      return response;
    }
  ),

  compileContract: createAsyncThunk("compileContract", async (payload) => {
    const response = await tokenService.compileContract(payload);
    return response;
  }),

  getAllTokenomicsFeesUser: createAsyncThunk(
    "getAllTokenomicsFeesUser",
    async ({ callback }) => {
      try {
        const response = await tokenService.getAllTokenomicsFeesUser();
        // callback(response?.data);

        if (response?.data?.status == 1) {
          callback({ status: 1, data: response?.data.data });

          return response?.data.data;
        } else {
          // sweetAlert.errorAlert("Unable to load tax information");
          callback({ status: 0, data: null });
          return null;
        }
      } catch (error) {
        // callback(error);
        // sweetAlert.errorAlert("Unable to load tax information");
        callback({ status: 0, data: null });

        return null;
      }
    }
  ),

  // Advance token
  createAdvanceToken: createAsyncThunk(
    "createAdvanceToken",
    async ({ payload, callback }) => {
      try {
        const response = await tokenService.createAdvanceToken(payload);
        if (response?.data?.status == 1) {
          sweetAlert.successAlert(response?.data?.message);
          callback(response?.data);
          return response?.data.data;
        } else {
          // sweetAlert.errorAlert(response?.data?.message);
          callback(response?.data);
          return null;
        }
      } catch (error) {
        // callback(error);
        // sweetAlert.errorAlert(error?.message);
        callback({ status: 0, data: null, message: error.message });
        return null;
      }
    }
  ),

  advanceCompileContract: createAsyncThunk(
    "advanceCompileContract",
    async ({ payload, callback }) => {
      try {
        const response = await tokenService.advanceCompileContract(payload);
        if (response?.data?.status == 1) {
          callback({ status: 1, data: response?.data });
          return response?.data.data;
        } else {
          sweetAlert.errorAlert("Unable to compile contract");
          callback({ status: 0, data: null });
          return null;
        }
      } catch (error) {
        // callback(error);
        // sweetAlert.errorAlert(error?.message);
        callback({ status: 0, data: null });

        return null;
      }
    }
  ),

  //get single user token list-
  getSingleUserTokenListAsync: createAsyncThunk(
    "token/getSigleUserTokenList",
    async (payload) => {
      try {
        const response = await tokenService.getSingleUserTokenList(payload);
        if (response?.data?.status == 1) {
          if (response.data.data) {
            response.data.data = response?.data.data.map((item, index) => {
              item.label = item.contractAddress;
              item.value = item.contractAddress;
              return item;
            });
          }

          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get token details by token id
  getSingleTokenDetailsAsync: createAsyncThunk(
    "/getSingleTokenPage",
    async (payload) => {
      try {
        const response = await tokenService.getSingleTokenDetails(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          // // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  // get token data like marketcap, total liquidity, volume, circulate supply
  getPoolAddressAsync: createAsyncThunk("/getPoolAddress", async ({payload, callback}) => {
    try {
      const response = await tokenService.getPoolAddress(payload);
      if (response?.data?.status == 1) {
        callback({
          status: 1,
          data: response?.data?.data
        })
        return response?.data?.data;
      } else {
        callback({
          status: 0,
          data: null
        })
        // // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      callback({
        status: 0,
        data: null
      })
      // // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  getPoolDataAsync: createAsyncThunk("/getPoolData", async (payload) => {
    try {
      const response = await tokenService.getPoolData(payload);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else {
        // // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  getTokenExplorerPoolListAsync: createAsyncThunk("/getTokenExplorerPoolList", async ({tokenDetails, callback}) => {
    try {
      const response = await tokenService.getTokenExplorerPoolList(tokenDetails);
      callback(response?.data);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else {
        // // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // console.log('error is: ',error)
      callback({ status: 0 });
      // // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  getPoolsAsync: createAsyncThunk("/getPools", async (payload) => {
    try {
      const response = await tokenService.getPools(payload);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else {
        // // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  // get token historical data
  getHistoricalDataAsync: createAsyncThunk(
    "/getHistoricalData",
    async (payload) => {
      try {
        const response = await tokenService.getHistoricalData(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          // // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //token post for community discussion
  createTokenPostsAsync: createAsyncThunk("/createPost", async (payload) => {
    try {
      const response = await tokenService.createTokenPost(payload);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else {
        // // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  getPoolsAsync: createAsyncThunk("/getPools", async (payload) => {
    try {
      const response = await tokenService.getPools(payload);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else {
        // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  //token post comment for community discussion
  createTokenPostsCommentAsync: createAsyncThunk(
    "/commentTopost",
    async (payload) => {
      try {
        const response = await tokenService.createTokenPostsComment(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get token post list by id
  getTokenPostListAsync: createAsyncThunk("/postList", async (payload) => {
    try {
      const response = await tokenService.getTokenPostData(payload);
      if (response?.data?.status == 1) {
        return response?.data?.data;
      } else if (response?.data?.status == 2) {
        return;
      } else if (response?.data?.status == 3) {
        return;
      } else {
        // sweetAlert.errorAlert(response?.data?.message);
        return null;
      }
    } catch (error) {
      // sweetAlert.errorAlert(error?.message);
      return null;
    }
  }),

  //get single post by post id
  getSingleTokenPostAsync: createAsyncThunk(
    "/singlepostbypostid",
    async (payload) => {
      try {
        const response = await tokenService.getSingleTokenPost(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //token post like/unlike
  tokenPostLikeUnlikeAsync: createAsyncThunk(
    "/addremovelike",
    async (payload) => {
      try {
        const response = await tokenService.postLikeUnlike(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //token post like/unlike
  tokenPostDislikeAsync: createAsyncThunk(
    "/dislikeaddremove",
    async (payload) => {
      try {
        const response = await tokenService.postDislike(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //token vote like/unlike
  createTokenVoteLikeAsync: createAsyncThunk(
    "/tokenAddremovelike",
    async (payload) => {
      try {
        const response = await tokenService.tokenLikeUnlikeVote(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //token vote dislike
  createTokenVoteDislikeAsync: createAsyncThunk(
    "/tokenAddremovedislike",
    async (payload) => {
      try {
        const response = await tokenService.tokenDislikeVote(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get token vote
  getTokenVoteAsync: createAsyncThunk(
    "/getTokenVoteanalysis",
    async (payload) => {
      try {
        const response = await tokenService.getTokenLikeDislikeVote(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //verify token address
  tokenInfoAsync: createAsyncThunk(
    "/tokenInfo",
    async ({ payload, callback }) => {
      try {
        const response = await tokenService.tokenInfo(payload);
        if (response?.data?.status == 1) {
          sweetAlert.successAlert("Token verified");
          callback(response);

          return response?.data?.data;
        } else {
          callback({ status: 0 });

          // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        callback({ status: 0 });

        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //token post like/unlike
  tokenPageAddRemoveFavouriteAsync: createAsyncThunk(
    "/addFavourite",
    async ({ payload, callback }) => {
      try {
        const response = await tokenService.tokenPageFavourite(payload);
        if (response?.data?.status == 1) {
          callback(response?.data);
          return response?.data?.data;
        } else {
          // sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //create token payment function
  createWalletPaymentAsync: createAsyncThunk(
    "/createTransction",
    async (payload) => {
      try {
        const response = await tokenService.tokenPagePayment(payload);
        if (response?.data?.status == 1) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //create token page payment function
  postTokenPagePaymentIntentIdAsync: createAsyncThunk(
    "/stripePayStatusCheck",
    async ({ payload, cb }) => {
      try {
        const response = await tokenService.tokenPagePaymentInentId(payload);
        if (response?.data?.status == 1) {
          cb(response?.data);
          return response?.data;
        } else {
          cb({ status: 0 });
          return response?.data;
        }
      } catch (error) {
        cb({ status: 0 });

        // sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  // token platform charges
  getAdminTokenomicsFees: createAsyncThunk(
    "getAdminTokenomicsFees",
    async ({ payload, callback }) => {
      try {
        const response = await tokenService.getAdminTokenomicsFees(payload);
        if (response?.data?.status == 1) {
          callback(response?.data);
          return response?.data.data;
        } else {
          callback(response?.data);
          return null;
        }
      } catch (error) {
        callback({ status: 0, data: null, message: error.message });
        return null;
      }
    }
  ),
};
export default tokenThunkAPI;
