import React from "react";

export default function FormButton(props) {
  const {
    label,
    name,
    className,
    onHandleClick,
    disabled,
    isLoading,
    children,
    type,
    comingSoon,
  } = props;

  return (
    <button
      type={type || "button"}
      className={className}
      onClick={onHandleClick}
      name={name}
      style={
        isLoading || disabled
          ? { cursor: "no-drop", opacity: 0.5 }
          : { cursor: "pointer" }
      }
      disabled={isLoading || disabled}
    >
      {comingSoon && (
        <span className="posAbsolute comingSoonBtnTxt">Coming soon</span>
      )}

      {(isLoading && (
        <>
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          Please wait
        </>
      )) || (
        <>
          {label} {children && children}
        </>
      )}
    </button>
  );
}

/*
How to use

 <FormButton
    className="tokenCreateBtnfrm fontBold"
    name="createToken"
    label="Create Token"
    disabled={false}
    isLoading={false}
    onHandleClick={onHandleSubmitLaunchpad}
  />

*/
