import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import DashboardThunkAPI from "./middleware";

const initialState = {
  allLaunchpadList: null,
  allPresaleList: null,
  allTokenpageList: null,
  status: STATUSES.IDLE,
  loadingAllLaunchpadList: false,
  loadingAllPrivateSaleList: false,
  loadingAllTokenPageList: false,
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    likeDislikeLaunchpad: (state, action) => {
      state.allLaunchpadList.map((item, index) => {
        if (item?._id == action?.payload) {
          item.isLiked = !item.isLiked;
        }
      });
    },
    likeDislikePresale: (state, action) => {
      state.allPresaleList.map((item, index) => {
        if (item?._id == action?.payload) {
          item.isLiked = !item.isLiked;
        }
      });
    },
    likeDislikeTokenpage: (state, action) => {
      state.allTokenpageList.map((item, index) => {
        if (item?._id == action?.payload) {
          item.isLiked = !item.isLiked;
        }
      });
    },

    changeTokenCartNetwork: (state, action) => {
      if (state?.allTokenpageList) {
        state.allTokenpageList.map((item, index) => {
          if (item?._id == action?.payload?.tokenPageId) {
            item.selected_token_data = action?.payload?.selectedTokenObj;
          }
        });
      }
    },
  },
  extraReducers: {
    [DashboardThunkAPI.getAllLaunchpadAsync.pending]: (state, action) => {
      state.loadingAllLaunchpadList = true;
      state.status = STATUSES.LOADING;
    },
    [DashboardThunkAPI.getAllLaunchpadAsync.fulfilled]: (state, action) => {
      state.allLaunchpadList = action?.payload;
      state.status = STATUSES.IDLE;
      state.loadingAllLaunchpadList = false;
    },
    [DashboardThunkAPI.getAllLaunchpadAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
      state.loadingAllLaunchpadList = false;
    },

    [DashboardThunkAPI.getAllPresaleAsync.pending]: (state, action) => {
      state.loadingAllPrivateSaleList = true;
      state.status = STATUSES.LOADING;
    },
    [DashboardThunkAPI.getAllPresaleAsync.fulfilled]: (state, action) => {
      let temp_data = action?.payload?.data;

      if (action?.payload?.forScrollPaginantion) {
        state.allPresaleList = [
          ...(state?.allPresaleList?.length ? state?.allPresaleList : []),
          ...(temp_data?.length ? temp_data : []),
        ];
      } else {
        state.allPresaleList = temp_data;
      }

      state.status = STATUSES.IDLE;
      state.loadingAllPrivateSaleList = false;
    },
    [DashboardThunkAPI.getAllPresaleAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
      state.loadingAllPrivateSaleList = false;
    },

    //get all token page list

    [DashboardThunkAPI.getAllTokenPageAsync.pending]: (state, action) => {
      state.loadingAllTokenPageList = true;
      state.status = STATUSES.LOADING;
    },
    [DashboardThunkAPI.getAllTokenPageAsync.fulfilled]: (state, action) => {
      state.allTokenpageList = action?.payload;
      state.status = STATUSES.IDLE;
      state.loadingAllTokenPageList = false;
    },
    [DashboardThunkAPI.getAllTokenPageAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
      state.loadingAllTokenPageList = false;
    },
  },
});

export const { likeDislikeLaunchpad, changeTokenCartNetwork } =
  dashboardSlice.actions;
export const { likeDislikePresale } = dashboardSlice.actions;
export const { likeDislikeTokenpage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
