import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import validator from "validator";

//utils
import { encryptData_, decrypteData_ } from "../../../utils/secure";
//componats
import Input from "componants/common/Input";
//redux
import UsersThunkAPI from "../../../store/features/users/middleware";

import { custValidator } from "utils/helpers";
import FormButton from "componants/common/FormButton";

import {
  setCommonMessage,
  removeCommonMessage,
} from "store/features/common/commonSlice";

const initialStateLogin = {
  username: "",
  pass: "",
};
const initialErrorLogin = {
  username: null,
  pass: null,
};
export default function Login(props) {
  const { handleClose, setForgetModal } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.commonState);

  //state
  const [loginDetails, setLoginDetails] = useState(initialStateLogin);
  const [loginError, setLoginError] = useState(initialErrorLogin);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [showOtherFields, setShowOtherFields] = useState(true);

  // hide or show password-
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // onchange
  const onHandleLoginInfo = (event, label) => {
    const { value } = event.target;
    switch (label) {
      case "username":
        setLoginDetails({ ...loginDetails, username: value.trim() });
        break;
      case "pass":
        setLoginDetails({ ...loginDetails, pass: value.trim() });
        break;
      case "usernameError":
        if (!value.trim()) {
          setLoginError({
            ...loginError,
            username: "Please enter email",
          });
        } else if (!custValidator.isValidEmail(value)) {
          setLoginError({
            ...loginError,
            username: "Please enter valid email address",
          });
        } else {
          setLoginError({ ...loginError, username: null });
        }
        break;

      case "passError":
        if (value.length < 7) {
          setLoginError({
            ...loginError,
            pass: "Please enter your password ",
          });
        } else {
          setLoginError({
            ...loginError,
            pass: null,
          });
        }
        break;
      default:
        setLoginDetails(initialStateLogin);
        setLoginError(initialErrorLogin);
        break;
    }
  };

  const handleClosePopup = () => {
    setLoginDetails(initialStateLogin);
    setLoginError(initialErrorLogin);
    handleClose();
  };

  // onsubmit method for login-
  const onLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        custValidator.isEmpty(loginDetails.username) &&
        custValidator.isEmpty(loginDetails.pass) &&
        loginPassCheck &&
        !loginPassError
      ) {
        let encrypted_pw = encryptData_(loginDetails.pass);
        let payload = {
          email: loginDetails.username.toLowerCase(),
          password: encrypted_pw,
          firebase_token: "",
          wallet_address: "",
          wallet_signup: false,
        };
        setLoaderStatus(true);

        dispatch(
          UsersThunkAPI.loginAsync({
            payload,
            callback: (res) => {
              setLoaderStatus(false);
              if (res.status == 1) {
                if (!res?.data?.name) router.push("/user/profile");
                handleClosePopup();
              }
            },
          })
        );
      } else {
        setLoginError({
          ...loginError,
          username: "please enter email",
          pass: "Please enter your password",
        });
        setLoaderStatus(false);
      }
    } catch (error) {
      setLoaderStatus(false);
    }
  };

  const loginPassCheck = Boolean(loginDetails.username && loginDetails.pass);
  const loginPassError = Boolean(loginError.username || loginError.pass);

 

  return (
    <div>
      <form onSubmit={(e) => onLoginSubmit(e)}>
        <div className="signUpSection">
          {commonState?.commonMessage && (
            <>
              <h6 className=" text-danger err text-center">
                {commonState?.commonMessage}
              </h6>
              <br />
              <br />
            </>
          )}

          {showOtherFields && (
            <>
              <Input
                type="email"
                //autoFocus={true}
                className="form-control"
                parantclassName="form-group"
                placeholder="Enter your email address"
                label="Email address"
                err={loginError.username}
                value={loginDetails.username}
                onChange={(e) => onHandleLoginInfo(e, "username")}
                onBlur={(e) => onHandleLoginInfo(e, "usernameError")}
              />

              <div className="form-group">
                <label>Password</label>
                <input
                  type={passwordType}
                  className="form-control"
                  placeholder="Enter your password"
                  value={loginDetails.pass}
                  onChange={(e) => onHandleLoginInfo(e, "pass")}
                  onBlur={(e) => onHandleLoginInfo(e, "passError")}
                  maxLength={20}
                />
                <div>
                  <span className="text-danger err">{loginError.pass}</span>
                </div>

                <span
                  className="showEyeIcon cursor-pointer"
                  onClick={() => togglePassword()}
                >
                  {passwordType === "password" ? (
                    <i className="far fa-eye-slash"></i>
                  ) : (
                    <i className="far fa-eye"></i>
                  )}
                </span>
              </div>

              <button
                className="forgerPassBtn"
                type="button"
                style={{
                  textDecorationLine: "underline",
                  border: "none",
                  color: "blue",
                }}
                onClick={() => {
                  handleClose();
                  setForgetModal(true);
                }}
              >
                Forgot password ?
              </button>

              <FormButton
                label="Login"
                type="submit"
                className={
                  ((!loginDetails?.username ||
                    !loginDetails?.pass ||
                    loginPassError) &&
                    "createAccountBtn2 cursor-no-drop") ||
                  " createAccountBtn"
                }
                disabled={!loginDetails?.username || loginPassError}
                onClick={onLoginSubmit}
                isLoading={loaderStatus}
              />
              <div className="or-block">
                <span></span> <p>OR</p>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
