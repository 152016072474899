import "firebase/messaging";
import "firebase/database";
import firebase from "firebase/app";

const firebaseCloudMessaging = {
  init: async () => {
    try {
      if (!firebase?.apps?.length) {
        // Initialize the Firebase app with the credentials
        firebase?.initializeApp({
          apiKey: "AIzaSyBd-JakbpuMtGkfNrav6vBbPoPCwfuhj7k",
          authDomain: "padefi-demo.firebaseapp.com",
          projectId: "padefi-demo",
          storageBucket: "padefi-demo.appspot.com",
          databaseURL: "https://padefi-demo-default-rtdb.firebaseio.com",
          messagingSenderId: "322642539935",
          appId: "1:322642539935:web:f41ab14b53481f5fd879aa",
          measurementId: "G-H44JFWFVJL",
        });
        try {
          const messaging = firebase.messaging();
          // Request the push notification permission from browser
          const status = await Notification.requestPermission();
          if (status && status === "granted") {
            // Get new token from Firebase
            const fcm_token = await messaging.getToken({
              vapidKey:
                "BMz_vvrE47ZFZCA5pmJEGwgQV-OMqSD4H2b0n6MnqlDU31V3BtzU9fQs-0AsDMsZvS1zCpvvA5TLbqtFaot1UcE",
            });

            // Set token in our local storage
            if (fcm_token) {
              return fcm_token;
            } else {
              return null;
            }
          }
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
  // sendMessage: async (to_id, from_id) => {
  //   // console.log("to_id =>" + to_id, "from_id =>" + from_id);
  //   firebase.database().ref("padefi-update").child(from_id).remove();
  //   firebase.database().ref("padefi-update").child(from_id).set({
  //     user_id: to_id,
  //   });
  //   return false;
  // },
  // sendUpdates: async (to_id, from_id) => {
  //   // console.log("to_id =>" + to_id, "from_id =>" + from_id);
  //   firebase.database().ref("padefi-update").child(from_id).remove();
  //   firebase.database().ref("padefi-update").child(from_id).set({
  //     user_id: to_id,
  //   });
  //   return false;
  // },
};
export { firebaseCloudMessaging };
