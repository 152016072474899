import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import TokenManagerThunkAPI from "./middleware";

const initialState = {
  getTokenManager: null,
  createTokenPageStatus: null,
  tokenpageExistOrNot: null,
  tokendetails: null,
  status: STATUSES.IDLE,
  updatedStatus: STATUSES.IDLE,
  favTokenPageList: null,
  feesData: null,
  deleteTokenPageStatus: null,
};

const TokenManagerSlice = createSlice({
  name: "tokenManager",
  initialState,
  reducers: {
    clearAllStatus: (state, action) => {
      state.updatedStatus = STATUSES.IDLE;
      state.tokenpageExistOrNot = null;
      state.tokendetails = null;
    },
    removeFavouriteTokenpage: (state, action) => {
      let temp = state.favTokenPageList.map((item) => {
        if (item?.tokenPageid == action?.payload)
          item.isfavourite = !item.isfavourite;
        return item;
      });
      state.favTokenPageList = temp;
    },
  },
  extraReducers: {
    [TokenManagerThunkAPI.getAllTokenManagerAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.getAllTokenManagerAsync.fulfilled]: (
      state,
      action
    ) => {
      state.getTokenManager = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [TokenManagerThunkAPI.getAllTokenManagerAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },
    /**
     * @desc edit user token :
     */
    [TokenManagerThunkAPI.editTokenManagerPageAsync.pending]: (
      state,
      action
    ) => {
      state.updatedStatus = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.editTokenManagerPageAsync.fulfilled]: (
      state,
      action
    ) => {
      state.getTokenManager = action?.payload?.data || {};
      state.updatedStatus = action?.payload?.status;
    },
    [TokenManagerThunkAPI.editTokenManagerPageAsync.rejected]: (
      state,
      action
    ) => {
      state.updatedStatus = STATUSES.ERROR;
    },

    //create token page
    [TokenManagerThunkAPI.createTokenPageAsync.pending]: (state, action) => {
      state.createTokenPageStatus = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.createTokenPageAsync.fulfilled]: (state, action) => {
      state.createTokenPageStatus = action?.payload?.status;
      state.tokendetails = action?.payload?.data;
    },
    [TokenManagerThunkAPI.createTokenPageAsync.rejected]: (state, action) => {
      state.createTokenPageStatus = STATUSES.ERROR;
    },

    //check token page exist or not
    [TokenManagerThunkAPI.checkTokenpageExistOrNotAsync.pending]: (
      state,
      action
    ) => {
      state.tokenpageExistOrNot = null;
    },
    [TokenManagerThunkAPI.checkTokenpageExistOrNotAsync.fulfilled]: (
      state,
      action
    ) => {
      state.tokenpageExistOrNot = action?.payload;
    },
    [TokenManagerThunkAPI.checkTokenpageExistOrNotAsync.rejected]: (
      state,
      action
    ) => {
      state.tokenpageExistOrNot = null;
    },

    //favourite token page list
    [TokenManagerThunkAPI.favouriteTokenPageAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.favouriteTokenPageAsync.fulfilled]: (
      state,
      action
    ) => {
      state.status = action?.payload?.status;
      state.favTokenPageList = action?.payload?.data;
    },
    [TokenManagerThunkAPI.favouriteTokenPageAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },

    //delete token page
    [TokenManagerThunkAPI.deleteTokenPageAsync.pending]: (state, action) => {
      state.deleteTokenPageStatus = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.deleteTokenPageAsync.fulfilled]: (state, action) => {
      state.deleteTokenPageStatus = action?.payload?.status;
    },
    [TokenManagerThunkAPI.deleteTokenPageAsync.rejected]: (state, action) => {
      state.deleteTokenPageStatus = STATUSES.ERROR;
    },

    //get fees by network
    [TokenManagerThunkAPI.getSpecifiedFeesByNetworkAsync.pending]: (
      state,
      action
    ) => {
      state.statu = STATUSES.LOADING;
    },
    [TokenManagerThunkAPI.getSpecifiedFeesByNetworkAsync.fulfilled]: (
      state,
      action
    ) => {
      state.status = action?.payload?.status;
      state.feesData = action?.payload?.data;
    },
    [TokenManagerThunkAPI.getSpecifiedFeesByNetworkAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },
  },
});

export const { clearAllStatus, removeFavouriteTokenpage } =
  TokenManagerSlice.actions;
export default TokenManagerSlice.reducer;
