import { ethers } from "ethers";
import { infuraId } from "../../../utils/config";
import * as sweetAlert from "../../../utils/sweetAlert";

async function ethereumMainnet() {
  try {
    // request to metamask
    let hex_chainId = ethers.utils.hexValue(1);
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    if (network.chainId !== 1) return true;
  } catch (error) {
    console.log('error is: ', error)
    if (error.code === 4001) {
      return true;
    }
  }
}
async function bscMainnet() {
  try {
    // request to metamask
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x38", // 56
          rpcUrls: ["https://bsc-dataseed1.binance.org/"],
          chainName: "Smart Chain",
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          blockExplorerUrls: ["https://bscscan.com"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}
async function bscTestnet() {
  // request to metamask
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x61", // 97
          chainName: "BSC Testnet",
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s2.binance.org:8545"],
          blockExplorerUrls: ["https://explorer.binance.org/smart-testnet"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}
async function GoerliETHTestnet() {
  // request to metamask
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x5", // 5
          chainName: "Goerli",
          nativeCurrency: {
            name: "GoerliETH",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://goerli.infura.io/v3/d2bc2c2cd3764075b6e6762025c78797"],
          blockExplorerUrls: ["https://goerli.etherscan.io"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}
async function polygonMainnet() {
  try {
    // request to metamask
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89", // 137
          rpcUrls: ["https://polygon-rpc.com"],
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}
async function fantomMainnet() {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0xFA", // 250
          rpcUrls: ["https://rpc.ftm.tools/"],
          chainName: "Fantom Opera",
          nativeCurrency: {
            // name: "Fantom Opera",
            symbol: "FTM",
            decimals: 18,
          },
          blockExplorerUrls: ["https://ftmscan.com/"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}

async function goerliTestnet() {
  try {
    var hex_chainId = ethers.utils.hexValue(5);
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: hex_chainId, // 5
          rpcUrls: ["https://goerli.infura.io/v3/"],
          chainName: "Goerli test network",
          nativeCurrency: {
            name: "GoerliETH",
            symbol: "GoerliETH",
            decimals: 18,
          },
          blockExplorerUrls: ["https://goerli.etherscan.io/"],
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      sweetAlert.errorAlert("Transaction rejected");
    }
  }
}
async function getCurrentNetwork() {
  let networkId = "";
  window.ethereum.on("chainChanged", (chainId) => {
    // The user switched to a different network
    // console.log("chainId is: ", chainId);
    networkId = parseInt(chainId, 16);
    // Do something with the new network ID
    // console.log("networkId is: ", networkId);
  });
  // console.log("networkId is121212: ", networkId);
  return networkId;
}
export {
  ethereumMainnet,
  bscMainnet,
  bscTestnet,
  polygonMainnet,
  fantomMainnet,
  goerliTestnet,
  getCurrentNetwork,
  GoerliETHTestnet,
  // SepoliaETHTestnet,
};
