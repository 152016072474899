import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import validator from "validator";
import usersService from "../../services/users.service";
import { custValidator } from "../../utils/helpers";
import * as sweetAlert from "../../utils/sweetAlert";
import Login from "./login";
import Signup from "./signup";
import Forget from "./forget-password";
import ValidateOtp from "./validate-otp";
import {
  setCommonMessage,
  removeCommonMessage,
} from "store/features/common/commonSlice";
//componants
import WalleteConnectButton from "./CustomWallet/WalleteConnectButton";
import WalletConnectorPopup from "./CustomWallet/WalletConnectorPopup";

//redux
import UsersThunkAPI from "../../store/features/users/middleware";

import { getAuthToken } from "../../services/authToken.service";

// store
import { openAuthModal, closeAuthModal } from "store/features/users/usersSlice";

export default function Auth() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.users);

  const Ref = useRef(null);
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);
  const [key, setKey] = useState("login");
  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState(false);
  const [showLoginSignupTabs, setShowLoginSignupTabs] = useState(true);

  const handleShow = () => {
    dispatch(openAuthModal());
  };
  const handleClose = () => {
    dispatch(removeCommonMessage());
    dispatch(closeAuthModal());
    setShowModal(false);
    setForgetModal(false);
    setKey("login");
    setTimeout(() => {
      setShowLoginSignupTabs(true);
    }, 500);
  };
  //callback function for OTP
  const setShowOTPModal = (email_) => {
    setEmail(email_);
    setShow(false);
    setShowModal(true);
  };

  useEffect(() => {
    if (!getAuthToken() && router?.query?.forLoginIn) {
      handleShow();
    }
    if (router?.query?.forgotPassword) {
      openForgotPasswordModal();
    }
  }, [router?.query]);

  const goSignIn = () => {
    handleShow();
  };

  const openForgotPasswordModal = () => {
    setForgetModal(true);
  };

  //Wallet
  const [showConnetWalleteOptionas, setShowConnetWalleteOptions] =
    useState(false);
  const onShowConnetWalleteOptions = () => {
    setShowConnetWalleteOptions(true);
    setShowLoginSignupTabs(false);
  };
  const onHideConnetWalleteOptions = () => {
    setShowConnetWalleteOptions(false);
    setShowLoginSignupTabs(true);
  };

  //  login with Wallet address
  const onloginSignupWithWallet = async (userWalletInfo) => {
    let payload = {
      wallet_address: userWalletInfo?.walletAddress,
      wallet_signup: true,
      signHash: userWalletInfo?.signHash,
    };

    dispatch(
      UsersThunkAPI.loginSignupWithWallet({
        payload,
        userWalletInfo,
        callback: (res) => {
          if (res.status == 1) {
            dispatch(removeCommonMessage());

            sweetAlert.successAlert(res?.message);
            router.push("/user/profile");
          }
        },
      })
    );
  };
  // useEffect(() => {
  //   return () => {
  //     console.log("return 123");
  //     dispatch(removeCommonMessage());
  //   };
  // }, []);
  return (
    <>
      <Button
        className="binanceBtn walletConnectBtn fontBold headLoginBtn"
        onClick={handleShow}
      >
        Login
      </Button>

      {/* signup and login modal */}
      <Modal
        className={
          showLoginSignupTabs ? "signUpPop" : "signUpPop connectNetworkPopUp"
        }
        show={userState?.isOpenAuthModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {showLoginSignupTabs && (
            <button type="button" className="crossPopBtn" onClick={handleClose}>
              <i className="fal fa-times"></i>
            </button>
          )}

          {(showLoginSignupTabs && (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 signLogintab"
            >
              <Tab eventKey="login" title="Login">
                <Login
                  handleClose={handleClose}
                  setForgetModal={setForgetModal}
                />
              </Tab>
              <Tab eventKey="signup" title="Sign Up">
                <Signup
                  handleClose={handleClose}
                  setShowOTPModal={setShowOTPModal}
                />
              </Tab>
            </Tabs>
          )) || (
            <WalletConnectorPopup
              onConnect={(data) => {
                onloginSignupWithWallet(data);
              }}
              isPopup={false}
              showConnetWalleteOptionas={showConnetWalleteOptionas}
              onHideConnetWalleteOptions={onHideConnetWalleteOptions}
              onBack={() => setShowLoginSignupTabs(true)}
              isForChangeNetworkInReduxState={true}
            />
          )}

          {showLoginSignupTabs && (
            <WalleteConnectButton
              onClick={onShowConnetWalleteOptions}
              className={"createAccountBtn connectWalletBtn"}
              classNameAfterConnect="binanceBtn fontBold d-flex align-items-center justify-content-center"
              isConnected={false}
            >
              Wallet Connector
            </WalleteConnectButton>
          )}
        </Modal.Body>
      </Modal>
      {/* end signup and login modal */}

      {/* OTP verification modal */}
      <Modal
        className="signUpPop otpPop "
        show={showModal}
        onHide={handleClose}
        //onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <button type="button" className="crossPopBtn" onClick={handleClose}>
            <i className="fal fa-times"></i>
          </button>
          <ValidateOtp setShowModal={setShowModal} email={email} />
        </Modal.Body>
      </Modal>
      {/*end Otp  */}

      {/* forget password model */}
      <Modal
        className="signUpPop forgetPassword"
        show={forgetModal}
        onHide={handleClose}
      >
        <Modal.Body>
          <button type="button" className="crossPopBtn" onClick={handleClose}>
            <i className="fal fa-times"></i>
          </button>
          <Forget setForgetModal={setForgetModal} goSignIn={goSignIn} />
        </Modal.Body>
      </Modal>
      {/* end forget passwoed */}
    </>
  );
}
