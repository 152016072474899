import React, { useEffect, useState } from "react";
import Image from "next/future/image";
import { useRouter } from "next/router";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setUserWalletInfo } from "store/features/users/usersSlice";
import UsersThunkAPI from "store/features/users/middleware";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// componants
import Spinner from "componants/common/Spinner";

import ethCoinImg from "../../../static/images/eth.svg";
import bnbCoinImg from "../../../static/images/bnb.svg";
import maticCoinImg from "../../../static/images/matic.svg";

// blockchain
import { ethers } from "ethers";

import {
  checkNetworkID,
  signMessage,
  isConnected,
} from "../wallet/walletConnectAuth";

import {
  ethereumMainnet,
  bscMainnet,
  bscTestnet,
  polygonMainnet,
  fantomMainnet,
  GoerliETHTestnet,
} from "../wallet/walletNetworks";

// import * as sweetAlert from "../../utils/sweetAlert";
import {
  getNetworkChainNameForPopup,
  getNetworkChainStaticObj,
} from "utils/helpers";

const CustomWalletConnector = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.users);
  const router = useRouter();

  const {
    showNetworkChainOptionas,
    onShowNetworkChainOptionas,
    onHideNetworkChainOptionas,
    onBack,
  } = props;

  const onConnect = (data) => {
    if (props?.isForChangeNetworkInReduxState) {
      dispatch(setUserWalletInfo(data));
    }
    props.onConnect(data);
  };

  const getCurrentNetwork = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const network = await provider.getNetwork();
    return network;
  };

  const onWalletConnect = async (id) => {
    if (!userState?.userWalletInfo?.walletAddress) {
      let static_chain = getNetworkChainStaticObj(id);
      onConnect({
        network: static_chain,
        walletAddress: "",
        // signHash,
      });
      return;
    }

    setIsLoading(true);
    const network_id = await checkNetworkID();
    switch (id) {
      // ethereum
      case 1:
        if (network_id != id) {
          try {
            await ethereumMainnet();
            const walletAddress = await isConnected();

            const network = await getCurrentNetwork();

            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
              // signHash,
            });
            // wallet address and sign message pass to API
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          const walletAddress = await isConnected();
          const network = await getCurrentNetwork();

          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
            // signHash,
          });
        }
        break;

      // BSC Mainnet
      case 56:
        if (network_id != id) {
          try {
            await bscMainnet();
            // const signHash = await signMessage();
            const walletAddress = await isConnected();
            const network = await getCurrentNetwork();

            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
              // signHash,
            });
            // wallet address and sign message pass to API
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          // const signHash = await signMessage();
          const walletAddress = await isConnected();
          const network = await getCurrentNetwork();
          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
            // signHash,
          });
        }
        break;

      // BSC Testnet
      case 97:
        if (network_id != id) {
          try {
            await bscTestnet();
            // const signHash = await signMessage();
            const walletAddress = await isConnected();

            const network = await getCurrentNetwork();
            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
              // signHash,
            });
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          // const signHash = await signMessage();
          const walletAddress = await isConnected();
          const network = await getCurrentNetwork();
          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
            // signHash,
          });
        }
        break;
      // GoerliETH Testnet
      case 5:
        if (network_id != id) {
          try {
            await GoerliETHTestnet();
            const walletAddress = await isConnected();
            const network = await getCurrentNetwork();
            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
            });
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          const walletAddress = await isConnected();
          const network = await getCurrentNetwork();
          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
          });
        }
        break;

      // Polygon Mainnet
      case 137:
        if (network_id != id) {
          try {
            await polygonMainnet();
            // const signHash = await signMessage();
            const walletAddress = await isConnected();

            const network = await getCurrentNetwork();
            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
              // signHash,
            });
            // wallet address and sign message pass to API
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          // const signHash = await signMessage();
          const walletAddress = await isConnected();

          const network = await getCurrentNetwork();
          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
            // signHash,
          });
        }
        break;
      // Fantom Mainnet
      case 250:
        if (network_id != id) {
          try {
            await fantomMainnet();
            // const signHash = await signMessage();
            const walletAddress = await isConnected();
            const network = await getCurrentNetwork();
            setIsLoading(false);

            onConnect({
              network,
              walletAddress,
              // signHash,
            });
            // wallet address and sign message pass to API
          } catch (error) {
            setIsLoading(false);
          }
        } else {
          // const signHash = await signMessage();
          const walletAddress = await isConnected();
          const network = await getCurrentNetwork();
          setIsLoading(false);

          onConnect({
            network,
            walletAddress,
            // signHash,
          });
        }
        break;
      default:
        // const signHash = await signMessage();
        const walletAddress = await isConnected();
        const network = await getCurrentNetwork();
        setIsLoading(false);

        onConnect({
          network,
          walletAddress,
          // signHash,
        });
    }
  };

  let getOptions = () => {
    return (
      <>
        <div className="BodayHeadTitle font18 fontBold chooseNetworkTitletxt">
          Choose Network
        </div>
        <div className="allSelectCoinBox position-relative">
          {isLoading && <Spinner />}

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <Button
                  className="CoinSelectBox"
                  onClick={() => onWalletConnect(56)}
                >
                  <Image alt="" src={bnbCoinImg} />
                  {getNetworkChainNameForPopup({ chainId: 56 })}
                </Button>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="allSelectCoinBoxBTN">
                <Button
                  className="CoinSelectBox"
                  onClick={() => onWalletConnect(1)}
                >
                  <Image alt="" src={ethCoinImg} />
                  {getNetworkChainNameForPopup({ chainId: 1 })}
                </Button>
              </div>
            </div>
          </div>
          <div className="testNetBx">
            <h2 className="colorGrey fontBold">Testnet</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="allSelectCoinBoxBTN">
                  <Button
                    className="CoinSelectBox"
                    onClick={() => onWalletConnect(97)}
                  >
                    <Image alt="" src={bnbCoinImg} />
                    {getNetworkChainNameForPopup({ chainId: 97 })}
                  </Button>
                </div>
              </div>
              {/* GoerliETH testnet */}
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="allSelectCoinBoxBTN">
                  <Button
                    className="CoinSelectBox"
                    onClick={() => onWalletConnect(5)}
                  >
                    <Image alt="" src={ethCoinImg} />
                    {getNetworkChainNameForPopup({ chainId: 5 })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {props?.isPopup ? (
        <Modal
          className="signUpPop connectNetworkPopUp"
          animation={false}
          show={showNetworkChainOptionas}
          onHide={onHideNetworkChainOptionas}
        >
          <Modal.Body>{getOptions()}</Modal.Body>
        </Modal>
      ) : (
        showNetworkChainOptionas && getOptions()
      )}
    </>
  );
};

export default CustomWalletConnector;
