import React from "react";
import { useEffect, useState } from "react";
import Image from "next/future/image";
import ethCoinImg from "static/images/metamasklogo.svg";

// blockchain
import {
  signMessage,
  isConnected,
  getNetwork,
} from "../wallet/walletConnectAuth";
import { MultiTokenVerify } from "../../my-token-manager/tokenVerify";
import Button from "react-bootstrap/Button";

import Spinner from "componants/common/Spinner";
import Web3 from 'web3';

const ConnectMetaMaskWallet = (props) => {
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  async function initConnection() {
    let web3;
    setIsLocalLoading(true);
    try {
      if (
        typeof window !== "undefined" &&
        typeof window.ethereum !== "undefined"
      ) {
        // Use MetaMask's provider
        web3 = new Web3(window.ethereum);
        window.ethereum.enable(); // Request user's permission to connect

        await window.ethereum.request({ method: "eth_requestAccounts" });
        let signHash = null;
        if (!props?.isDisableSignatureRequest) {
          signHash = await signMessage();
        }

        const walletAddress = await isConnected();
        const network = await getNetwork();
        let verifyContractList = "";
        if (props?.isForMultiNetworkCheck) {
          verifyContractList = await MultiTokenVerify(
            props.contractAddress,
            props.networksListToCheck,
            walletAddress
          );
          props?.onConnect({
            network: network,
            walletAddress: walletAddress,
            signHash: signHash,
            walletType: "metamask",
            verifyContractList: verifyContractList,
          });
          return;
        }
        if (signHash) {
          props?.onConnect({
            network: network,
            walletAddress: walletAddress,
            signHash: signHash,
            walletType: "metamask",
          });
        }
        setIsLocalLoading(false);
      }
    } catch (error) {
      console.log("error is: ", error);
      setIsLocalLoading(false);
    }
  }

  useEffect(() => {
    props?.setIsLoading(isLocalLoading);
  }, [isLocalLoading]);

  const getContent = () => {
    if (isLocalLoading) {
      return (
        <>
          Meta Mask &nbsp; &nbsp; &nbsp;
          <Spinner className="spinner-border text-light spinner-border-sm" />
        </>
      );
    } else {
      return "Meta Mask";
    }
  };

  return (
    <div className="App-connector">
      <Button
        className="CoinSelectBox"
        onClick={() => {
          if (isLocalLoading || props?.isLoading) return;
          initConnection();
        }}
      >
        <Image alt="" src={ethCoinImg} style={{ width: 50, height: 50 }} />
        {getContent()}
      </Button>
    </div>
  );
};

export default ConnectMetaMaskWallet;
