import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/statuses";
import privateSaleThunkAPI from "./middleware";

const initialState = {
  presale: null,
  singlePresaleDetails: null,
  singlePrivateSaleDetails: null,
  addRemoveFavouriteLoading: false,
  communityVoteAnalysys: null,
  likeLoader: false,
  disLikeLoader: false,
  emergencywithdraw: null,

  status: STATUSES.IDLE,
};

const privateSaleSlice = createSlice({
  name: "presale",
  initialState,
  reducers: {
  
    removeFavouritePresale: (state, action) => {
      let temp = state.presale.map((item) => {
        if (item?._id == action?.payload) item.isfavourite = !item.isfavourite;
        return item;
      });
      state.presale = temp;
    },

    addRemoveToFavouritePrivateSaleDetail: (state) => {
      if (state.singlePrivateSaleDetails)
        state.singlePrivateSaleDetails.isfavourite =
          !state.singlePrivateSaleDetails.isfavourite;
    },

    removeSinglePrivateSaleDetails: (state) => {
      state.singlePrivateSaleDetails = null;
    },
  },
  extraReducers: {
    /**
     * @desc get user presale :
     */
    [privateSaleThunkAPI.favouritePresaleAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [privateSaleThunkAPI.favouritePresaleAsync.fulfilled]: (state, action) => {
      state.presale = action?.payload;
      state.status = STATUSES.IDLE;
    },
    [privateSaleThunkAPI.favouritePresaleAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
   
    [privateSaleThunkAPI.addFavouritePresaleAsync.pending]: (state, action) => {
      state.addRemoveFavouriteLoading = true;
    },
    [privateSaleThunkAPI.addFavouritePresaleAsync.fulfilled]: (
      state,
      action
    ) => {
      state.addRemoveFavouriteLoading = false;
    },
    [privateSaleThunkAPI.addFavouritePresaleAsync.rejected]: (
      state,
      action
    ) => {
      state.addRemoveFavouriteLoading = false;
    },

    // createPrivateSale
    [privateSaleThunkAPI.createPrivateSale.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [privateSaleThunkAPI.createPrivateSale.fulfilled]: (state, action) => {
      state.status = true;
    },
    [privateSaleThunkAPI.createPrivateSale.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    // emergencywithdraw
    [privateSaleThunkAPI.emergencywithdraw.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [privateSaleThunkAPI.emergencywithdraw.fulfilled]: (state, action) => {
      state.status = true;
    },
    [privateSaleThunkAPI.emergencywithdraw.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    [privateSaleThunkAPI.privatesaledetailsAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [privateSaleThunkAPI.privatesaledetailsAsync.fulfilled]: (
      state,
      action
    ) => {
      state.status = STATUSES.IDLE;
      state.singlePrivateSaleDetails = action.payload;
    },
    [privateSaleThunkAPI.privatesaledetailsAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },

    [privateSaleThunkAPI.getPrivateSaleCommunityVoteAnalysisAsync.pending]: (
      state,
      action
    ) => {
      state.status = STATUSES.LOADING;
    },
    [privateSaleThunkAPI.getPrivateSaleCommunityVoteAnalysisAsync.fulfilled]: (
      state,
      action
    ) => {
      state.status = STATUSES.IDLE;
      state.communityVoteAnalysys = action.payload;
    },
    [privateSaleThunkAPI.getPrivateSaleCommunityVoteAnalysisAsync.rejected]: (
      state,
      action
    ) => {
      state.status = STATUSES.ERROR;
    },

    [privateSaleThunkAPI.privateSaleCommunityAddRemoveLikeAsync.pending]: (
      state,
      action
    ) => {
      state.likeLoader = true;
    },
    [privateSaleThunkAPI.privateSaleCommunityAddRemoveLikeAsync.fulfilled]: (
      state,
      action
    ) => {
      state.likeLoader = false;
      state.status = STATUSES.IDLE;
      state.communityVoteAnalysys = action.payload;
    },
    [privateSaleThunkAPI.privateSaleCommunityAddRemoveLikeAsync.rejected]: (
      state,
      action
    ) => {
      state.likeLoader = false;
    },

    [privateSaleThunkAPI.privateSaleCommunityAddRemoveDislikeAsync.pending]: (
      state,
      action
    ) => {
      state.disLikeLoader = true;
    },
    [privateSaleThunkAPI.privateSaleCommunityAddRemoveDislikeAsync.fulfilled]: (
      state,
      action
    ) => {
      state.disLikeLoader = false;
      state.communityVoteAnalysys = action.payload;
    },
    [privateSaleThunkAPI.privateSaleCommunityAddRemoveDislikeAsync.rejected]: (
      state,
      action
    ) => {
      state.disLikeLoader = false;
    },
  },
});
export const { removeFavouritePresale, addRemoveToFavouritePrivateSaleDetail ,removeSinglePrivateSaleDetails} =
  privateSaleSlice.actions;

export default privateSaleSlice.reducer;
