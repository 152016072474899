import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "services/dashboard.service";
import tokenService from "services/token.service";
import * as sweetalert from "../../../../utils/sweetAlert";
import moment from "moment";

import {
  _parseFloat,
  addDotsMiddleOfString,
  formatDate,
  timeStampToUtcDateObj,
  getTokenProgressPercentage,
  getTimeStampWithFixTimezon,
  _parseInt,
} from "utils/helpers";

const DashboardThunkAPI = {
  //get user all launchpad on dashboard:
  getAllLaunchpadAsync: createAsyncThunk(
    "dashboard/getUserAllLaunchpad",
    async (payload) => {
      try {
        const response = await dashboardService.getAllLaunchpad(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),
  //get user all presale on dashboard:
  getAllPresaleAsync: createAsyncThunk(
    "dashboard/getUserAllPresale",
    async ({ payload, cb }) => {
      try {
        const response = await dashboardService.getAllPresale(payload);
        if (response?.data?.status) {

          let res_data = JSON.parse(JSON.stringify(response?.data?.data));
          let temp_data = [];

          // for (let i = 0; i < res_data.length; i++) {
          res_data.map((x, index) => {
           
            x.contract_name = x.presale_title;
            x.contract_whitelist = x.wishlist == "enable";
            x.contract_softCap = x.softcap_bnb;
            x.contract_hardCap = x.hardcap_bnb;
            x.min_buy = x.minimum_bnb;
            x.max_buy = x.maximum_bnb;
            x.start_time = _parseInt(x.start_date);
            x.end_time = _parseInt(x.end_date);
            x.contract_first_release = x.first_fund_release_percent;
            x.contract_cycle_release = x.fund_release_percent;
            x.contract_cycle_duration = x.fund_vesting_period;
            x.progresspercentage = getTokenProgressPercentage(
              x?.contract_hardCap,
              x?.get_total_invested_amount
            );

            //dete proparty to avaoid repitation
            delete x.presale_title;
            delete x.wishlist;
            delete x.softcap_bnb;
            delete x.hardcap_bnb;
            delete x.minimum_bnb;
            delete x.maximum_bnb;
            delete x.start_date;
            delete x.end_date;
            delete x.first_fund_release_percent;
            delete x.fund_release_percent;
            delete x.fund_vesting_period;

            temp_data.push(x);
          });

          cb({
            data: temp_data,
            status: 1,
          });

          let temp_payload = {
            data: temp_data,
            forScrollPaginantion: payload.forScrollPaginantion ? true : false,
          };

          return temp_payload;
        } else {
          cb({
            data: null,
            status: 0,
          });
          // sweetalert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {

        cb({
          data: null,
          status: 0,
        });

        return null;
      }
    }
  ),

  //get all token page list
  getAllTokenPageAsync: createAsyncThunk(
    "tokenExplorerTokenPages",
    async (payload) => {
      try {
        const response = await dashboardService.getAlltokenPage(payload);
        if (response?.data?.status) {
          if (response?.data?.data) {
            response?.data?.data.map((item, index) => {
              item.selected_token_data = item.token_data[0];
            });
          }
          let tokenDetails = [];
          for (let i = 0; i < response?.data?.data?.length; i++) {
            tokenDetails.push({
              contractAddress:
                response?.data?.data[i]?.token_data?.[0]?.contract_address,
              chainId: response?.data?.data[i]?.token_data?.[0]?.networkName,
            });
          }
          const resp = await tokenService.getTokenExplorerPoolList(tokenDetails)
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        console.log('error', error)
        return null;
      }
    }
  ),
};
export default DashboardThunkAPI;
