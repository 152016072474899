// create axios instance :
/**
  name:swapnil shewale
  date:20/01/23
*/

import axios from "axios";
//authToken
import {
  removeCurrantUser,
  getAuthToken,
  removeUserWalletInfo,
} from "./authToken.service";
// secure
import * as secure from "../utils/secure";

const isEncryptDecryptEnabled =
  process.env.IS_ENCRYPT_DECRYPT_ENABLED == "ENABLED";

const avoid_encryption = [
  "uploadLogoFileS3",
  "updateProfileAndBannerImage",
  "uploadWhitepaperpdf",
];

const avoid_decryption = [];

// create instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// request middleware
axiosInstance.interceptors.request.use(
  async (config1) => {
    let config = config1;
    // console.log("ax request in axios interceptors brfore", config.data);
    //set token
    // console.log("window1111",window)
    if (typeof window !== "undefined") {
      config.headers["x-access-token"] = getAuthToken() || "";
    }

    // console.log("secure payload before encrypt", config.data);
    //encrypt data ----------------
    let temp_data = { bytes: null };
    // if (process.env.ENV_MODE !== "DEV" && isEncryptDecrypt) {

    if (isEncryptDecryptEnabled && !avoid_encryption.includes(config?.url)) {
      temp_data["bytes"] = await secure.encryptData(config.data);
      config.data = temp_data;
    }
    // ------------------
    // console.log("secure payload after encrypt", config.data);

    // console.log("ax request in axios interceptors after", config.data);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// responce middleware
axiosInstance.interceptors.response.use(
  async (result) => {
    let res = result;
    if (result?.data?.status == 2) {
      removeCurrantUser();
      removeUserWalletInfo();
      // window.location.replace("/?forLoginIn=true");
    }

    // console.log("ax responce in axios interceptors before", res);

    // console.log("secure responce before decrypt", res?.data?.data);

    //decrypte data ---------------
    let temp_data = res?.data?.data;
    // console.log("ax responce data in axios interceptors before", temp_data);
    // if (process.env.ENV_MODE !== "DEV" && isEncryptDecrypt) {
    if (isEncryptDecryptEnabled) {
      temp_data = await secure.decrypteData(temp_data);
      res.data.data = await temp_data;
    }
    // ---------------
    // console.log("ax responce in axios interceptors after", res);

    return res;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export default axiosInstance;
