import "../styles/globals.css";
import "bootstrap/dist/css/bootstrap.css";
import Head from "next/head";
import Script from "next/script";
import MetaTagComponant from "../componants/MetaTagComponant";
//test
// Redux
import { Provider } from "react-redux";
import { useRouter } from "next/router";
import store from "../store";
//test
//  Layout
import Layout from "../componants/Layout/Layout";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <MetaTagComponant />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NDHJG359"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <Provider store={store}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  );
}

export default MyApp;
