export default function Loader() {
  return (
    <div className={"loader-parant"}>
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status'>
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
    </div>
  );
}
// .loader-parant {position: fixed;right: 0;left: 0;z-index: 999;top: 0;background-color: rgba(0, 0, 0, 0.9);bottom: 0;display: flex;align-items: center;justify-content: center;}
