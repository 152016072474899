export default function Input(props) {
  return (
    <div className={props?.parantclassName}>
      <div className="form-group">
        {props?.label && (
          <label htmlFor={props?.id} className="form-label">
            {props?.label}
            {props?.info && (
              <span className="informationSpan posRelative">
                <i className="far fa-info-circle"></i>
                <span className="posAbsolute noteSection noteSectionTop p16 bgLightGray contentGrayColor">
                  {props?.info}
                </span>
              </span>
            )}
          </label>
        )}

        <input
          type={props?.type}
          value={props?.value}
          className={props?.className}
          id={props?.id}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          name={props?.name}
          onKeyPress={(e) => {
            props?.acceptOnly == "number" &&
              !/[0-9]/.test(e.key) &&
              e.preventDefault();

            props?.acceptOnly == "character" &&
              !/[a-zA-z]/.test(e.key) &&
              e.preventDefault();

          }} // numbers only
          disabled={props?.disabled}
          maxLength={props?.maxLength}
          onBlur={props?.onBlur}
          pattern={props?.pattern}
        />
       
        {(props.fieldDesc && (
          <p className="formGroupsmltxt colorGrey font12 m-l-5 ">
            {props.fieldDesc}
          </p>
        )) ||
          null}
        {props?.err && <span className="text-danger err">{props?.err}</span>}
        {props.verified && (
          <span className="verifyTag">
            <i className="far fa-check"></i> Verified
          </span>
        )}
      </div>
    </div>
  );
}

// //How to use

// <Input
//   type="text"
//   name="token_name"
//   className="form-control"
//   parantclassName="col-md-6"
//   id="token_name"
//   placeholder="Ex: Sweply token"
//   label="Name your token"
//   err={data?.errors?.token_name}
//   onChange={handleChange}
//   value={data?.token_name}
//   info=""
// />;
