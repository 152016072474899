import React from "react";
import Head from "next/head";
import Script from "next/script";

export default function MetaTags() {
  return (
    <Head>
      {/* <!-- Google Tag Manager --> */}
      {/* 
         Copy the code below and paste it onto every page of your website.
        Paste this code as high in the <head> of the page as possible:
     */}
      {/* Primary Meta Tags */}

      <title>PADEFI: The Simple Crypto Launchpad Empowering Everyone!</title>
      <meta
        name="description"
        content="Easily create tokens and token sales with the best No-Code tool. Engage your communities through unique token pages."
      />
      <meta
        name="keywords"
        content="PADEFI, create your token, no code crypto, blockchain, decentralized finance, DeFi, cryptocurrency, digital assets, tokenization, smart contracts, token economy, Initial Coin Offering (ICO), token launch, tokenized investments, crypto crowdfunding, tokenomics."
      />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      {/* Open Graph / Facebook */}

      <meta property="og:type" content="website" />
      <meta property="og:url" content=" https://padefi.io" />
      <meta
        property="og:title"
        content="PADEFI: The Simple Crypto Launchpad Empowering Everyone!"
      />
      <meta
        property="og:description"
        content="Easily create tokens and token sales with the best No-Code tool. Engage your communities through unique token pages."
      />
      <meta
        property="og:image"
        content="https://padefi-asset.s3.us-east-1.amazonaws.com/padefi-assets/Frame%2048095562%201.png"
      />

      {/* Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content=" https://padefi.io " />
      <meta
        property="twitter:title"
        content="PADEFI: The Simple Crypto Launchpad Empowering Everyone!"
      />
      <meta
        property="twitter:description"
        content="Easily create tokens and token sales with the best No-Code tool. Engage your communities through unique token pages."
      />
      <meta
        property="twitter:image"
        content="https://padefi-asset.s3.us-east-1.amazonaws.com/padefi-assets/Frame%2048095562%201.png"
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NDHJG359');
                    `,
        }}
      />
    </Head>
  );
}
