import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import logger from "redux-logger";
import launchPadReducer from "./features/launchPads/launchPadSlice";
import usersReducer from "./features/users/usersSlice";
import tokenReducer from "./features/token/tokenSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import appSettingsReducer from "./features/appSettings/appSettingsSlice";
import privateSaleReducer from "./features/privateSale/privateSaleSlice";
import tokenManagerReducer from "./features/tokenManager/tokenManagerSlice";

import commonSlice from "./features/common/commonSlice";

const reducer = combineReducers({
  appSettingsState: appSettingsReducer,
  users: usersReducer,
  launchPadState: launchPadReducer,
  presaleState: privateSaleReducer,
  tokenState: tokenReducer,
  dashboardState: dashboardReducer,
  tokenManagerState: tokenManagerReducer,
  commonState: commonSlice,
});
const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

  middleware: (getDefaultMiddleware) => {
    if (process.env.ENV_MODE !== "LIVE") {
      return getDefaultMiddleware().concat(logger);
    } else {
      return getDefaultMiddleware();
    }
  },

  devTools: process.env.ENV_MODE !== "LIVE",
});
export default store;
// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
