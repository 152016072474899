import React from "react";

const NoDataFoundSmall = (props) => {
  const { className, message } = props;
  return (
    <div className={className ? className : "noRecordSection"}>{message}</div>
  );
};

<div className="noRecordSection"></div>;

export default NoDataFoundSmall;
{
  /**
       How to use 

       import NoDataFoundSmall from "componants/common/NoDataFoundSmall";

       <NoDataFoundSmall
              message="No token have been created"
              className="colorGrey "
            /> 

     */
}
