import { createSlice, current } from "@reduxjs/toolkit";

import {
  setAppSettingsCookie,
  getAppSettingsCookie,
} from "services/authToken.service";

const initialState = {
  blackTheme: true,
  ...getAppSettingsCookie(),
};

const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.blackTheme = !state.blackTheme;
      //save in cookies app settings
      setAppSettingsCookie(current(state));
    },
  },
});

export const { changeTheme } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
