import React from "react";

const WalleteConnectButton = (props) => {
  const { onClick, className, children, classNameAfterConnect, isConnected } =
    props;
  return (
    <button
      className={isConnected ? classNameAfterConnect : className}
      onClick={onClick}
      type="button"
    >
    {children}
    </button>
  );
};

export default WalleteConnectButton;
