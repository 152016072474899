import axiosInstance from "./axios";

const tokenManagerService = {
  getAllTokenManager: async function (payload) {
    return axiosInstance.post("getTokenManager", payload);
  },
  editTokenManagerPage: async function (payload) {
    return axiosInstance.post("/editTokenPage", payload);
  },
  createTokenPage: async function (payload) {
    return axiosInstance.post("/createTokenPage ", payload);
  },
  checkTokenpageExist: async function (payload) {
    return axiosInstance.post("/checkIsTokePageExist ", payload);
  },
  userFavouriteTokenPageList: async function (payload) {
    return axiosInstance.post("/getUserfavouritetokenPage ", payload);
  },
  tokenPageDelete: async function (payload) {
    return axiosInstance.post("/decactivatetokenpage ", payload);
  },
  getSpecifiedFeesByNetwork: async function (payload) {
    return axiosInstance.post("specifiedFeesByNetwork", payload);
  },
};
export default tokenManagerService;
