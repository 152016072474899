import { createAsyncThunk } from "@reduxjs/toolkit";
import * as sweetAlert from "../../../../utils/sweetAlert";
import privateSale from "../../../../services/privateSale.service";

const privateSaleThunkAPI = {
  //get user favourite launchpad :
  favouritePresaleAsync: createAsyncThunk(
    "presale/favouritePresaleList",
    async (payload) => {
      try {
        const response = await privateSale.userFavouritePresaleList(payload);
        if (response?.data.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),
  //add/remove favourite launchpad to list :
  addFavouritePresaleAsync: createAsyncThunk(
    "presale/addFavouritePresale",
    async ({ payload, callback }) => {
      try {
        const response = await privateSale.addRemoveFavouritePresale(payload);
        if (response?.data.status) {
          callback(response?.data);
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  createPrivateSale: createAsyncThunk(
    "presale/create-private-sale",
    async ({ payload, callback }) => {
      try {
        const response = await privateSale.createPrivateSale(payload);
        if (response?.data.status) {
          callback(response?.data);
          return response?.data?.data;
        } else {
          callback({ status: 0 });
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        callback({ status: 0 });

        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get user single launchpad details :
  privatesaledetailsAsync: createAsyncThunk(
    "presale/privatesaledetails",
    async (payload) => {
      try {
        const response = await privateSale.privatesaledetails(payload);
        if (response?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get getPrivateSaleCommunityVoteAnalysisAsync :
  getPrivateSaleCommunityVoteAnalysisAsync: createAsyncThunk(
    "private-sale/getPrivateSaleCommunityVoteAnalysis",
    async (payload) => {
      try {
        const response = await privateSale.getPrivateSaleCommunityVoteAnalysis(
          payload
        );
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get privateSaleCommunityAddRemoveLikeAsync :
  privateSaleCommunityAddRemoveLikeAsync: createAsyncThunk(
    "private-sale/privateSaleCommunityAddRemoveLike",
    async (payload) => {
      try {
        const response = await privateSale.privateSaleCommunityAddRemoveLike(
          payload
        );
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get privateSaleCommunityAddRemoveDislikeAsync :
  privateSaleCommunityAddRemoveDislikeAsync: createAsyncThunk(
    "private-sale/privateSaleCommunityAddRemoveDislike",
    async (payload) => {
      try {
        const response = await privateSale.privateSaleCommunityAddRemoveDislike(
          payload
        );
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get emergencywithdraw :
  emergencywithdraw: createAsyncThunk(
    "private-sale/emergencywithdraw",
    async ({ payload, callback }) => {
      try {
        const response = await privateSale.emergencywithdraw(payload);
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),
};
export default privateSaleThunkAPI;
