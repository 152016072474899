import { useEffect, useState } from "react";
import Image from "next/future/image";
import { useWeb3React } from "@web3-react/core";
import Spinner from "componants/common/Spinner";

import { WalletLinkConnector } from "@web3-react/walletlink-connector";

import Button from "react-bootstrap/Button";
import ethCoinImg from "../../../static/images/coinbasewalletlogo.png";

const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,

  appName: "Padefi",

  supportedChainIds: [1, 97, 250, 5, 137, 56],
});

export default function ConnectWalletConnect(props) {
  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const { library, chainId, ...web3React } = useWeb3React();

  function handleError(error) {
    if (error) {
      setIsLocalLoading(false);
      CoinbaseWallet.handleDisconnect();
    }
  }

  function initConnection() {
    setIsLocalLoading(true);

    web3React.activate(CoinbaseWallet);
  }

  function closeConnection() {
    CoinbaseWallet.close();
  }

  function activeIsWC() {
    return web3React.account && web3React.connector;
  }

  useEffect(() => {
    if (web3React.account) {
      onSignMessage();
    }
  }, [web3React.account]);

  const onSignMessage = async () => {
    try {
      let signature = null;
      if (!props?.isDisableSignatureRequest) {
        signature = await library?.getSigner()
          .signMessage(`Hello, welcome to Padify. Please sign this message to verify your wallet. This action will not cost you any transaction fee.
    ${"\n"}Address:${"\n"}${web3React.account}`);
      }

      const network = await library?.getNetwork();

      setIsLocalLoading(false);

      props?.onConnect?.({
        network: network,
        walletAddress: web3React.account,
        signHash: signature,
        walletType: "coinbase",
      });
    } catch (error) {
      setIsLocalLoading(false);
    }
  };

  useEffect(() => {
    props?.setIsLoading(isLocalLoading);
  }, [isLocalLoading]);

  const getContent = () => {
    if (isLocalLoading) {
      return (
        <>
          Coinbase Wallet &nbsp; &nbsp; &nbsp;
          <Spinner className="spinner-border text-light spinner-border-sm" />
        </>
      );
    } else if (activeIsWC()) {
      return "Connected";
    } else {
      return "Coinbase Wallet";
    }
  };

  return (
    <div className="App-connector">
      <Button
        className="CoinSelectBox"
        onClick={() => {
          if (isLocalLoading || props?.isLoading) return;
          initConnection();
        }}
      >
        <Image alt="" src={ethCoinImg} style={{ width: 50, height: 50 }} />
        {getContent()}
      </Button>
    </div>
  );
}
