import { createAsyncThunk } from "@reduxjs/toolkit";
import * as sweetAlert from "../../../../utils/sweetAlert";
import launchPadService from "../../../../services/launchPad.service";

const LaunchPadThunkAPI = {
  //get user favourite launchpad :
  favouriteLaunchPadAsync: createAsyncThunk(
    "launchPad/favouriteLaunchpadList",
    async (payload) => {
      try {
        const response = await launchPadService.userFavouriteLaunchPadList(
          payload
        );
        if (response?.data.status) {
          
          return response?.data?.data;
        } else {
         
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),
  //add /remove favourite launchpad to list :
  addFavouriteLaunchpadAsync: createAsyncThunk(
    "launchpad/addFavouriteLaunchpad",
    async ({ payload, callback }) => {
      try {
        const response = await launchPadService.addRemoveFavouriteLaunchpad(
          payload
        );
        if (response?.data.status) {
          callback(response?.data);
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),
  //get user single launchpad details :
  getSingleLaunchpadDetailsAsync: createAsyncThunk(
    "launchpad/getSingleLaunchpadDetails",
    async (payload) => {
      try {
        const response = await launchPadService.getSingleLaunchpadDetails(
          payload
        );
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  //get user single launchpad details :
  createLaunchpadAsync: createAsyncThunk(
    "launchpad/createLaunchpad",
    async ({ payload, callback }) => {
      try {
        const response = await launchPadService.createLaunchpad(payload);
        callback(response?.data);

        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        callback({ status: 0 });

        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),
  //get user single launchpad details :
  updateLaunchpadAsync: createAsyncThunk(
    "launchpad/updateLaunchpad",
    async ({ payload, callback }) => {
      try {
        const response = await launchPadService.updateLaunchpad(payload);
        callback(response?.data);

        if (response?.data?.status) {
          return response?.data?.data;
        } else {
          sweetAlert.errorAlert(response?.data?.message);
          return null;
        }
      } catch (error) {
        callback({ status: 0 });

        sweetAlert.errorAlert(error?.message);
        return null;
      }
    }
  ),

  // add recent view laucnhpad by user
  addRecentViewLaunchpadByUserAsync: createAsyncThunk(
    "/addrecentViewedata",
    async (payload) => {
      try {
        const response = await launchPadService.addRecentViewLaunchpad(payload);
        if (response?.data?.status) {
          return response?.data?.data;
        } else {
        }
      } catch (error) {
      }
    }
  ),
  //get launchpadCommunityVoteAnalysisAsync :
  launchpadCommunityVoteAnalysisAsync: createAsyncThunk(
    "launchpad/launchpadCommunityVoteAnalysis",
    async (payload) => {
      try {
        const response = await launchPadService.launchpadCommunityVoteAnalysis(
          payload
        );
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get launchpadCommunityAddRemoveLike :
  launchpadCommunityAddRemoveLikeAsync: createAsyncThunk(
    "launchpad/launchpadCommunityAddRemoveLike",
    async (payload) => {
      try {
        const response = await launchPadService.launchpadCommunityAddRemoveLike(
          payload
        );
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
  ),

  //get launchpadCommunityAddRemoveDislike :
  launchpadCommunityAddRemoveDislikeAsync: createAsyncThunk(
    "launchpad/launchpadCommunityAddRemoveDislike",
    async (payload) => {
      try {
        const response =
          await launchPadService.launchpadCommunityAddRemoveDislike(payload);
        if (response?.status) {
          return response?.data?.data;
        } else {
          return null;
        }
      } catch (error) {
      }
    }
  ),
};
export default LaunchPadThunkAPI;
