import CryptoJS from "crypto-js";

export const encryptData = (data) => {
  try {
    // if envirment i DEV then return data as it
    // if (process.env.ENV_MODE === "DEV") return data;

    let stringify_data = JSON.stringify(data);
    let ciphertext = CryptoJS.AES.encrypt(
      stringify_data,
      process.env.EN_SECRETE_KEY
    ).toString();
    //  console.log("en=>> ciphertext",ciphertext)
    return ciphertext;
  } catch (e) {
    // console.log("error in decrypteData ", e);
  }
};

export const decrypteData = (data_ciphertext) => {
  try {
    // if envirment i DEV then return data as it
    // if (process.env.ENV_MODE === "DEV") return data_ciphertext;

    let bytes = CryptoJS.AES.decrypt(
      data_ciphertext,
      process.env.EN_SECRETE_KEY
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //  console.log("en=>> decryptedData",decryptedData)
    return decryptedData;
  } catch (e) {
    // console.log("error in decrypteData ", e);
  }
};

export const encryptData_ = (data) => {
  try {
    // if envirment i DEV then return data as it

    let stringify_data = JSON.stringify(data);
    let ciphertext = CryptoJS.AES.encrypt(
      stringify_data,
      process.env.EN_SECRETE_KEY
    ).toString();
    //  console.log("en=>> ciphertext",ciphertext)
    return ciphertext;
  } catch (e) {
    // console.log("error in decrypteData ", e);
  }
};

export const decrypteData_ = (data_ciphertext) => {
  try {
    // if envirment i DEV then return data as it

    let bytes = CryptoJS.AES.decrypt(
      data_ciphertext,
      process.env.EN_SECRETE_KEY
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //  console.log("en=>> decryptedData",decryptedData)
    return decryptedData;
  } catch (e) {
    // console.log("error in decrypteData ", e);
  }
};
{
  /**
    //how to use
    import * as secure from "../../utils/secure";
    
    const testEncription = () => {
    let data= {
      name:"swapnil",
      roll:15,
    }
    let encrepted = secure.encryptData(data)
    console.log("data22 encrepted123",encrepted)
    let decrepted = secure.decrypteData(encrepted)
    console.log("data22 descripted123",decrepted)
  };
 */
}
