import { useEffect, useState } from "react";
import Image from "next/future/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { getAppSettingsCookie } from "../../services/authToken.service";
//import icons
import logoMain from "../../static/images/logo.svg";
import logoBlue from "../../static/images/logoBlue.svg";

import $ from "jquery"

//import menulist constant
import { MENUS_LEFT_SIDEBAR } from "../../utils/menusLeftSidebar";

//redux
import { useSelector, useDispatch } from "react-redux";
import { changeTheme } from "../../store/features/appSettings/appSettingsSlice";

//cookies
import { parseCookies } from "nookies";

export default function LeftSidebar() {
  //redux
  const appSettingsState = useSelector((state) => state?.appSettingsState);
  const dispatch = useDispatch();
  const router = useRouter();
  const addBodyClass = () => {
    dispatch(changeTheme());
    document.body.classList.add("light-mode");
  };
  const removeBodyClass = () => {
    dispatch(changeTheme());
    document.body.classList.remove("light-mode");
  };
  const menuClose = () => {
    document.body.classList.remove("menuAction");
  };

  const isActive = (routelink, activeMenuRoutes) => {
    return (
      (activeMenuRoutes && activeMenuRoutes.includes(router.pathname)) ||
      routelink == router.pathname
    );
  };

  useEffect(() => {
    appSettingsState?.blackTheme
      ? document.body.classList.remove("light-mode")
      : document.body.classList.add("light-mode");
  }, []);

  useEffect(() => {
    var doc_width = $(document).width();
    if( doc_width < 992){
      $(".leftMenu").on("click", function(){
        $("body").removeClass("menuAction");
      });
    }
  })

  return (
    <>
      <div className='overlayLeftbar'></div>
      <div className='leftHeadMenuBox'>
        <div className='leftMenu'>
          <div className='leftMenuLogo'>
            <Link className='mainBlackLogoSlide' href={"/"}>
              <Image className='logoBlue' alt='' src={logoBlue} />
            </Link>
            <Link className='mainWhiteLogoSlide' href={"/"}>
              <Image className='logo' alt='' src={logoMain} />
            </Link>
          </div>
          <div className='resMenuClose'>
            <i className='fal fa-times' onClick={menuClose}></i>
          </div>
          {/* start left sidebar menu  */}
          <ul>
            {MENUS_LEFT_SIDEBAR?.map((item, index) => (
              <MenuItem
                key={index}
                menu_name={item?.menu_name}
                icon={item?.icon}
                link={item?.link}
                svgIcon={item?.svgIcon}
                isActive={isActive(item?.link, item?.activeMenuRoutes)}
                target={item?.target}
              />
            ))}
          </ul>
          {/* end left sidebar menu  */}

          <div className='menuBotam'>
            <div className='socialBtmSection'>
              {/* start social media buttons */}
              <div className='socailIcon'>
                <a href={"https://web.telegram.org"} target='_blank'>
                  <i className='fab fa-telegram-plane'></i>
                </a>
                <a href={"https://twitter.com/Padefi"} target='_blank'>
                  <i className='fab fa-twitter'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MenuItem = (props) => {
  return (
    <li key={props?.key}>
      <Link href={props?.link}>
        <a
          className={props?.isActive ? "active" : ""}
          target={props?.target == "newpage" ? "_blank" : ""}
        >
          <div dangerouslySetInnerHTML={{ __html: props.svgIcon }} />
          <span>{props?.menu_name}</span>
        </a>
      </Link>
    </li>
  );
};
