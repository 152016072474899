import axiosInstance from "./axios";

const privateSaleServices = {
  //favourite launchpad lists :
  userFavouritePresaleList: async function (payload) {
    return axiosInstance.post("getUserFavouratePresale", payload);
  },
  addRemoveFavouritePresale: async function (payload) {
    return axiosInstance.post("addFavourite", payload);
  },

  createPrivateSale: async function (payload) {
    return axiosInstance.post("create-private-sale", payload);
  },

  privatesaledetails: async function (payload) {
    return axiosInstance.post("privatesaledetails", payload);
  },
  getPrivateSaleCommunityVoteAnalysis: async function (payload) {
    return axiosInstance.post("getPrivateSaleCommunityVoteAnalysis", payload);
  },
  privateSaleCommunityAddRemoveLike: async function (payload) {
    return axiosInstance.post("privateSaleCommunityAddRemoveLike", payload);
  },
  privateSaleCommunityAddRemoveDislike: async function (payload) {
    return axiosInstance.post("privateSaleCommunityAddRemoveDislike", payload);
  },
  privateSaleBuy: async function (payload) {
    return axiosInstance.post("private-sale-buy", payload);
  },
  addUserToWhitelist: async function (payload) {
    return axiosInstance.post("addUserToWhitelist", payload);
  },
  removeUserFromWhitelist: async function (payload) {
    return axiosInstance.post("removeUserFromWhitelist", payload);
  },

  getWhiteListUsersList: async function (payload) {
    return axiosInstance.post("getWhiteListUsersList", payload);
  },
  getContributorsList: async function (payload) {
    return axiosInstance.post("getContributorsList", payload);
  },
  updatePrivateSale: async function (payload) {
    return axiosInstance.post("update-private-sale", payload);
  },
  emergencywithdraw: async function (payload) {
    return axiosInstance.post("emergencywithdraw", payload);
  },



  
};
export default privateSaleServices;
